import "bootstrap/dist/css/bootstrap.min.css"
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import './services/firebase';

import './App.css';
import {RecipePage} from "./pages/RecipePage";
import {RecipeProvider} from "./contexts/RecipeContext";
import {SearchRecipeProvider} from "./contexts/SearchRecipeContext";
import {UserAuthProvider} from "./contexts/UserAuthContext";
import {NotificationProvider} from "./contexts/NotificationContext";
import {Notification} from "./components/Notification";

function ProvidedApp() {
    return (
        <>
            <RecipePage/>
            <Notification/>
        </>
    );
}

function App() {
    return (
        <NotificationProvider>
            <SearchRecipeProvider>
                <RecipeProvider>
                    <UserAuthProvider>
                        <ProvidedApp/>
                    </UserAuthProvider>
                </RecipeProvider>
            </SearchRecipeProvider>
        </NotificationProvider>
    )
}

export default App;
