import {Form} from "react-bootstrap";
import {FormSection} from "../FormSection";
import {Slider} from "../Slider";
import {useSearchRecipeContext} from "../../contexts/SearchRecipeContext";
import {useUserAuthContext} from "../../contexts/UserAuthContext";
import {AllergenSwitches} from "../AllergenSwitches";

export function FilterForm(props) {

    const {title} = props;

    const {isVeggie, setVeggie, isLowCal, setLowCal, maxCal, setMaxCal, duration, setDuration, difficulty, setDifficulty} = useSearchRecipeContext();
    const {showLoginFormUI, showSelectAllergensUI} = useUserAuthContext();

    return (
        <>
            <h2 className={"sidebar-header"}>{title}</h2>
            <Form className={"sidebar-form"} id={"display-overflow"}>

                <FormSection title={"Dieet"}>
                    <Form.Switch type={"switch"} id={"veggie"} label={"VEGGIE"} checked={isVeggie} onChange={() => setVeggie(!isVeggie)}/>
                    <Form.Switch type={"switch"} id={"caloriebewust"} label={"CALORIEBEWUST"} checked={isLowCal} onChange={() => setLowCal(!isLowCal)}/>
                </FormSection>

                <FormSection title={"Voorkeuren"} marginTop={".5rem"}>
                    <Slider label={"MAX KCAL"} value={maxCal} onChange={setMaxCal} min={500} max={2000} step={500} tooltipLabel={(kcal) => kcal === 2000 ? "ONBEPERKT" : kcal + " KCAL"} showToolTip={!showLoginFormUI && !showSelectAllergensUI}/>
                    <Slider label={"TIJD"} value={duration} onChange={setDuration} min={10} max={60} step={10} tooltipLabel={(time) => time === 60 ? "ONBEPERKT" : time + " MIN"} showToolTip={!showLoginFormUI && !showSelectAllergensUI}/>
                    <Slider label={"MOEILIJKHEID"} value={difficulty} onChange={setDifficulty} min={0} max={2} step={1} tooltipLabel={(difficulty) => difficulty === 0 ? "MAKKELIJK" : difficulty === 1 ?"GEMIDDELD" : difficulty === 2 ? "MOEILIJK" : false} showToolTip={!showLoginFormUI && !showSelectAllergensUI}/>
                </FormSection>

                <AllergenSwitches/>
            </Form>
        </>
    );

}