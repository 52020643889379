import {Row} from "react-bootstrap";
import Avatar from "boring-avatars";
import {useUserAuthContext} from "../contexts/UserAuthContext";

export function Profile() {

    const {authUser, setShowLoginFormUI, setShowSelectAllergensUI} = useUserAuthContext();

    return (
        <div className={"sidebar-profile"} onClick={() => authUser.uid ? setShowSelectAllergensUI(true) : setShowLoginFormUI(true)}>
            <Row>
                <div className={"sidebar-profile-avatar"}><Avatar size={40} name={authUser.uid} variant="marble"/></div>
                <div className={authUser.uid ? "sidebar-profile-username" : "sidebar-profile-username log-in"}>
                    {authUser.uid ? <p className={"logged-in"}>{"welkom terug, "}</p> : <p>{"meld u aan"}</p>}
                    {authUser.uid ? <p className={"logged-in username"}>{authUser.firstname}</p> : ""}
                </div>
            </Row>
        </div>
    );

}