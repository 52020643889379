import {Container} from "react-bootstrap";
import {RecipeContent} from "./RecipeContent";
import {useRecipeContext} from "../contexts/RecipeContext";
import {NoRecipe} from "./NoRecipe";

export function Recipe(){

    const {recipe} = useRecipeContext();

    return (
        <Container className={"recipe-container"}>
            {recipe ? <RecipeContent/> : <NoRecipe/>}
        </Container>
    );

}