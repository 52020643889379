export function FormSection(props){

    const {title, marginTop} = props;

    return (
        <>
            <h4 className={"sidebar-subheader"} style={{marginTop: marginTop ? marginTop : "0"}}>{title}</h4>
            {props.children}
        </>
    );

}