import {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {createUserWithEmailAndPassword, signInWithEmailAndPassword} from "firebase/auth";
import {auth, firestoreDB} from "../services/firebase";
import {setDoc, getDoc, doc} from "firebase/firestore";
import {useSearchRecipeContext} from "./SearchRecipeContext";
import {useNotificationContext} from "./NotificationContext";
import {useLocalStorage} from "@uidotdev/usehooks";

const UserAuthContext = createContext();

export function UserAuthProvider(props) {

    const {allergens, setAllergens} = useSearchRecipeContext();
    const {setNotification} = useNotificationContext();

    const [user, setUser] = useState({uid: "", firstname: "", lastname: "", email: "", password: "", allergens: {}, stayLoggedIn: false});
    const [authUser, setAuthUser] = useState({uid: "", firstname: "", lastname: "", email: "", password: "", allergens: {}, stayLoggedIn: false});
    const [showLoginFormUI, setShowLoginFormUI] = useState(false);
    const [showSelectAllergensUI, setShowSelectAllergensUI] = useState(false);
    const [stayLoggedIn, setStayLoggedIn] = useState(false);

    const [localUser, setLocalUser] = useLocalStorage("localUser", {...authUser});

    useEffect(() => {
        if(localUser.uid){
            setAuthUser(localUser);
            setAllergens(localUser.allergens)
            setStayLoggedIn(true);
        }
        // eslint-disable-next-line
    }, [])

    const login = useCallback(async(e) => {
        e.preventDefault();

        signInWithEmailAndPassword(auth, user.email, user.password)
            .then(async (userCredential) => {

                const docRef = doc(firestoreDB, "users", userCredential.user.uid);
                await getDoc(docRef).then((snap) => {
                    setAuthUser({...snap.data(), uid: userCredential.user.uid});
                    setAllergens(snap.data()["allergens"]);
                });

                setShowLoginFormUI(false);
            })
            .catch(() => {
                document.getElementById("error-message").innerHTML = "Foute gegevens"
                document.getElementById("error-message").style = "display: block;";
            });
    }, [setAllergens, user.email, user.password]);

    const logout = useCallback(() => {
        setAllergens({
            "lactose": false,
            "gluten": false,
            "vis": false,
            "pinda": false,
            "noten": false,
            "mosterd": false,
            "soja": false,
            "eieren": false,
            "sulfiet": false,
            "selderij": false,
            "sesamzaad": false,
            "cashewnoten": false,
            "schaaldieren": false,
            "lupine": false,
            "amandelnoten": false
        });
        setAuthUser({uid: "", firstname: "", lastname: "", email: "", password: "", allergens: {}});
        setShowSelectAllergensUI(false);
    }, [setAllergens]);

    const register = useCallback( (e) => {
        e.preventDefault();

        createUserWithEmailAndPassword(auth, user.email, user.password)
            .then(async (userCredential) => {

                authUser.uid = userCredential.user.uid;

                await setDoc(doc(firestoreDB, "users", userCredential.user.uid), {
                    firstname: user.firstname,
                    lastname: user.lastname,
                    email: user.email,
                    allergens: allergens
                });

                const docRef = doc(firestoreDB, "users", userCredential.user.uid);
                await getDoc(docRef).then((snap) => {
                    setAuthUser({...snap.data(), uid: userCredential.user.uid});
                });

                setShowLoginFormUI(false);
            })
            .catch(() => {
                document.getElementById("error-message").innerHTML = "Email al in gebruik"
                document.getElementById("error-message").style = "display: block;";
            });
    }, [allergens, authUser, user.email, user.firstname, user.lastname, user.password]);

    const saveUserAllergens = useCallback(async(e) => {
        e.preventDefault();

        await setDoc(doc(firestoreDB, "users", authUser.uid), {
            firstname: authUser.firstname,
            lastname: authUser.lastname,
            email: authUser.email,
            allergens: allergens
        }).then(() => {
            if(stayLoggedIn) setLocalUser({...authUser, allergens: allergens});
            setNotification("Uw allergieën zijn opgeslaan")
        });

    }, [allergens, authUser, setLocalUser, setNotification, stayLoggedIn]);

    const toggleStayLoggedIn = useCallback( () => {

        const status = !stayLoggedIn;

        if(status) setLocalUser({...authUser, allergens: allergens, stayLoggedIn: stayLoggedIn});
        if(!status) setLocalUser({});

        setStayLoggedIn(!stayLoggedIn);
    }, [allergens, authUser, setLocalUser, stayLoggedIn]);

    const api = useMemo(() => ({
        user,
        setUser,
        authUser,
        setAuthUser,
        login,
        register,
        showLoginFormUI,
        setShowLoginFormUI,
        showSelectAllergensUI,
        setShowSelectAllergensUI,
        logout,
        saveUserAllergens,
        stayLoggedIn,
        setStayLoggedIn,
        toggleStayLoggedIn,
    }), [user, setUser, authUser, setAuthUser, login, register, showLoginFormUI, setShowLoginFormUI, showSelectAllergensUI, setShowSelectAllergensUI, logout, saveUserAllergens, stayLoggedIn, setStayLoggedIn, toggleStayLoggedIn]);

    return <UserAuthContext.Provider value={api}>
        {props.children}
    </UserAuthContext.Provider>;
}

export const useUserAuthContext = () => useContext(UserAuthContext);