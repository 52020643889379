import {Col, Form, Row} from "react-bootstrap";
import {useUserAuthContext} from "../../contexts/UserAuthContext";

export function RegisterForm(props){

    const {showLoginForm} = props;

    const {register, user, setUser} = useUserAuthContext();

    return (
        <>
            <Form onSubmit={register} className={"sidebar-form"} style={{marginTop: "1rem", marginBottom: "0rem", display: showLoginForm ? "none" : "block"}}>
                <Row>
                    <Col md={6}><Form.Control className={"login-form-input"} type="text" value={user.firstname} onChange={e => setUser({...user, firstname: e.target.value})} placeholder="Voornaam"/></Col>
                    <Col md={6}><Form.Control className={"login-form-input"} type="text" value={user.lastname} onChange={e => setUser({...user, lastname: e.target.value})} placeholder="Achternaam"/></Col>
                </Row>
                <Form.Control className={"login-form-input"} type="email" value={user.email} onChange={e => setUser({...user, email: e.target.value})} placeholder="Email"/>
                <Form.Control className={"login-form-input"} type="password" value={user.password} onChange={e => setUser({...user, password: e.target.value})} placeholder="Wachtwoord" style={{marginTop: "1rem"}}/>
                <button className={"btn-sidebar btn-login"}>Registreren</button>
            </Form>
        </>
    );

}