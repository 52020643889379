import {useRecipeContext} from "../contexts/RecipeContext";
import {UserAuthentication} from "./UserAuthentication";
import {Profile} from "./Profile";
import {ProfileSettings} from "./ProfileSettings";
import {FilterForm} from "./forms/FilterForm";

export function Sidebar(){

    const {newRandomRecipe, setRecipesUsingFilters} = useRecipeContext();

    return (
        <>
            <div className={"sidebar"}>
                <div className={"sidebar-wrapper"}>

                    <div className={"sidebar-controls"}>
                        <button className={"btn-sidebar"} onClick={newRandomRecipe}>Willekeurig</button>
                        <button className={"btn-sidebar"} onClick={setRecipesUsingFilters}>Zoeken</button>
                    </div>

                    <FilterForm title={"Filter"}/>
                    <Profile/>

                </div>
            </div>

            <UserAuthentication/>
            <ProfileSettings/>

        </>
    );

}