import {useRecipeContext} from "../contexts/RecipeContext";
import {Col, Row} from "react-bootstrap";

function Labels(props) {
    const {recipe} = props;
    return (
        <ul className={"recipe-labels-list"}>
            {recipe["labels"].map((label) => <li key={label}>{label}</li>)}
        </ul>
    );
}

function Details(props) {
    const {recipe} = props;
    return (
        <ul className={"recipe-details"}>
            <li><b>{recipe["duration"].split(" ")[0]}</b> {recipe["duration"].split(" ")[1]}</li>
            <li><b>{recipe["kcal"].split(" ")[0]}</b> {recipe["kcal"].split(" ")[1]}</li>
        </ul>
    );
}

function Ingredients(props) {
    const {recipe} = props;
    return (
        <>
            <h3>Ingrediënten</h3>
            <ul>
                {recipe["ingredients"].map(ingredient => <li key={ingredient}>{ingredient}</li>)}
            </ul>
        </>
    );
}

function Allergens(props) {
    const {recipe} = props;
    return (
        <>
            <h3>Allergenen</h3>
            <ul>
                {Array.isArray(recipe["allergens"]) ? recipe["allergens"].map((allergen, index) => <li key={index}>{allergen}</li>) : Object.keys(recipe["allergens"]).map((key, index) => recipe["allergens"][key] ? <li key={index}>{key.substring(0, 1).toUpperCase() + key.substring(1)}</li> : "")}
            </ul>
        </>
    );
}

export function RecipeContent() {

    const {recipe} = useRecipeContext();

    return (
        <>
            <div className={"recipe-header"} style={{backgroundImage: "url(" + recipe["image_url"] + ")"}}>
                <h1 className={"recipe-title"}>{recipe["title"]}</h1>
            </div>
            <h3 className={"recipe-extra"}>{recipe["extra"]}</h3>
            <h5 className={"recipe-difficulty"}>{recipe["difficulty"]}</h5>

            <Labels recipe={recipe}/>
            <Details recipe={recipe}/>

            <Row className={"recipe-ingredients-row"}>
                <Col className={"recipe-ingredients-list"}>

                    <Ingredients recipe={recipe}/>

                </Col>
                <Col className={"recipe-allergens-list"}>

                    <Allergens recipe={recipe}/>

                </Col>
            </Row>

            <a href={recipe["pdf_url"]} target={"_blank"} rel={"noreferrer"} className={"recipe-button"}>Maak klaar</a>
        </>
    );

}