import {Col, Offcanvas, Row} from "react-bootstrap";
import {useState} from "react";

import {useUserAuthContext} from "../contexts/UserAuthContext";
import {LoginForm} from "./forms/LoginForm";
import {RegisterForm} from "./forms/RegisterForm";

function FormSelector(props) {
    const {setShowLoginForm} = props;
    return (
        <Row>
            <Col md={6}><button className={"btn-sidebar btn-login"} onClick={() => setShowLoginForm(true)}>Aanmelden</button></Col>
            <Col md={6}><button className={"btn-sidebar btn-login"} onClick={() => setShowLoginForm(false)}>Registreren</button></Col>
        </Row>
    );
}

export function UserAuthentication() {

    const {showLoginFormUI, setShowLoginFormUI} = useUserAuthContext();
    const [showLoginForm, setShowLoginForm] = useState(true);

    return (
        <>
            <Offcanvas className={"login-form"} show={showLoginFormUI} onHide={() => setShowLoginFormUI(false)}>
                <div className={"sidebar-wrapper"}>
                    <Offcanvas.Header className={"login-form-header"} closeButton>
                        <Offcanvas.Title className={"login-form-header"}>Welkom terug</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>

                        <FormSelector setShowLoginForm={setShowLoginForm}/>

                        <hr/>

                        <p id={"error-message"}></p>
                        <LoginForm showLoginForm={showLoginForm}/>
                        <RegisterForm showLoginForm={showLoginForm}/>

                    </Offcanvas.Body>
                </div>
            </Offcanvas>
        </>
    );

}