import {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {RECIPES} from "../data/data";
import {useSearchRecipeContext} from "./SearchRecipeContext";

const RecipeContext = createContext();

export function RecipeProvider(props) {

    const {isVeggie, isLowCal, maxCal, duration, difficulty, allergens} = useSearchRecipeContext();
    const recipes = RECIPES;

    useEffect(() => {
        recipes.forEach(recipe => {

            if(!Array.isArray(recipe.allergens)) return;

            recipe.allergens = {
                "lactose": recipe.allergens.includes("Melk (inclusief lactose)"),
                "gluten": recipe.allergens.includes("Gluten"),
                "vis": recipe.allergens.includes("Vis"),
                "pinda": recipe.allergens.includes("Pinda's"),
                "noten": recipe.allergens.includes("Noten"),
                "mosterd": recipe.allergens.includes("Mosterd"),
                "soja": recipe.allergens.includes("Soja"),
                "eieren": recipe.allergens.includes("Eieren"),
                "sulfiet": recipe.allergens.includes("Sulfiet"),
                "selderij": recipe.allergens.includes("Selderij"),
                "sesamzaad": recipe.allergens.includes("Sesamzaad"),
                "cashewnoten": recipe.allergens.includes("Cashewnoten"),
                "schaaldieren": recipe.allergens.includes("Schaaldieren"),
                "lupine": recipe.allergens.includes("Lupine"),
                "amandelnoten": recipe.allergens.includes("Amandelnoten")
            };
        });
        // eslint-disable-next-line
    }, []);

    const [recipe, setRecipe] = useState(recipes[Math.floor(Math.random() * recipes.length)]);

    const newRandomRecipe = useCallback(() => {
        setRecipe(RECIPES[Math.floor(Math.random() * RECIPES.length)]);
    }, []);

    const setRecipesUsingFilters = useCallback(() => {

        const difficulties = ["MAKKELIJK", "GEMIDDELD", "MOEILIJK"];
        const filteredRecipes = [...RECIPES.filter(recipe =>
            (isVeggie ? recipe.labels.includes("Veggie") : recipe) &&
            (isLowCal ? recipe.labels.includes("Caloriebewust") : recipe) &&
            (parseInt(recipe.kcal.split(" ")[0]) <= maxCal || maxCal === 2000) &&
            ((parseInt(recipe.duration.split(" ")[0]) <= duration && recipe.duration.split(" ")[1] !== "uur") || duration === 60) &&
            (recipe.difficulty.toUpperCase() === difficulties[difficulty])
        )];

        const withAllergens = filteredRecipes.filter(recipe =>
            !(allergens["lactose"] && recipe.allergens["lactose"]) &&
            !(allergens["gluten"] && recipe.allergens["gluten"]) &&
            !(allergens["vis"] && recipe.allergens["vis"]) &&
            !(allergens["pinda"] && recipe.allergens["pinda"]) &&
            !(allergens["noten"] && recipe.allergens["noten"]) &&
            !(allergens["mosterd"] && recipe.allergens["mosterd"]) &&
            !(allergens["soja"] && recipe.allergens["soja"]) &&
            !(allergens["eieren"] && recipe.allergens["eieren"]) &&
            !(allergens["sulfiet"] && recipe.allergens["sulfiet"]) &&
            !(allergens["selderij"] && recipe.allergens["selderij"]) &&
            !(allergens["sesamzaad"] && recipe.allergens["sesamzaad"]) &&
            !(allergens["cashewnoten"] && recipe.allergens["cashewnoten"]) &&
            !(allergens["schaaldieren"] && recipe.allergens["schaaldieren"]) &&
            !(allergens["lupine"] && recipe.allergens["lupine"]) &&
            !(allergens["amandelnoten"] && recipe.allergens["amandelnoten"])
        );

        setRecipe(withAllergens[Math.floor(Math.random() * withAllergens.length)]);
        
    }, [allergens, difficulty, duration, isLowCal, isVeggie, maxCal]);

    const api = useMemo(() => ({
        recipe, setRecipe, newRandomRecipe, setRecipesUsingFilters
    }), [recipe, setRecipe, newRandomRecipe, setRecipesUsingFilters]);

    return <RecipeContext.Provider value={api}>
        {props.children}
    </RecipeContext.Provider>;
}

export const useRecipeContext = () => useContext(RecipeContext);