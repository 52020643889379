export const ALLERGENS = {
    "lactose": false,
    "gluten": false,
    "vis": false,
    "pinda": false,
    "noten": false,
    "mosterd": false,
    "soja": false,
    "eieren": false,
    "sulfiet": false,
    "selderij": false,
    "sesamzaad": false,
    "cashewnoten": false,
    "schaaldieren": false,
    "lupine": false,
    "amandelnoten": false
};

export const RECIPES =
[
    {
        "title": "Romige groentelasagne met spinazie",
        "extra": "Met courgette en grana padano",
        "labels": [
            "Chef's Choice",
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Eieren",
            "Gluten"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "828 kcal",
        "ingredients": [
            "Courgette",
            "Ui",
            "Knoflookteen",
            "Verse oregano",
            "Spinazie",
            "Kookroom",
            "Geraspte grana padano",
            "Lasagnebladen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/593aac53c6243b235318c55c-ef3aad09.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-groentelasagne-met-spinazie-593aac53c6243b235318c55c-02437dea.pdf"
    },
    {
        "title": "Salade ni\u00e7oise met volkoren couscous",
        "extra": "met verschillende groenten en tonijn",
        "labels": [
            "Caloriebewust"
        ],
        "allergens": [
            "Eieren",
            "Vis",
            "Gluten"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "534 kcal",
        "ingredients": [
            "Ei",
            "Rode ui",
            "Knoflookteen",
            "Sperziebonen",
            "Rode paprika",
            "Tonijn in olijfolie",
            "Volkoren couscous",
            "Komkommer"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/593aac54c6243b235318c55d-775bb5f1.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/salade-nicoise-met-volkoren-couscous-593aac54c6243b235318c55d-5717e84c.pdf"
    },
    {
        "title": "Burger met pittige groene peper en piccalilly",
        "extra": "Met aardappelpartjes en salade",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Mosterd"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "880 kcal",
        "ingredients": [
            "Aardappelpartjes",
            "Gedroogde tijm",
            "Tomaat",
            "Komkommer",
            "Groene peper",
            "Mesclun",
            "Witte ciabatta",
            "Gekruide runderburger",
            "Piccalilly"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/burger-met-pittige-groene-peper-en-piccalilly-157cb46d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/burger-met-pittige-groene-peper-en-piccalilly-59b2b4352c9e865a4a7f4862-738f0207.pdf"
    },
    {
        "title": "Andijviestamppot met Brandt & Levie-worst",
        "extra": "Met kruidenkaas en cherrytomaatjes",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "773 kcal",
        "ingredients": [
            "Kruimige aardappelen",
            "Rode ui",
            "Rode cherrytomaten",
            "Varkensworst met peterselie en knoflook",
            "Gesneden andijvie",
            "Kruidenkaasblokjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/andijviestamppot-met-brandt-levie-worst-d081176e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/andijviestamppot-met-brandt-levie-worst-59d5fbafd56afa030f72e0d2-900eeaa1.pdf"
    },
    {
        "title": "Gebakken zeebaars met salieboter",
        "extra": "Met kruidige aardappelen en prei",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice",
            "Caloriebewust",
            "Familie"
        ],
        "allergens": [
            "Selderij",
            "Mosterd",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "611 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Kerriepoeder",
            "Prei",
            "Rode cherrytomaten",
            "Knoflookteen",
            "Verse salie",
            "Zeebaarsfilet met huid"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-zeebaars-met-salieboter-ddd58316.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-zeebaars-met-salieboter-59e069f12c9e863b9623dfb2-9cb2a228.pdf"
    },
    {
        "title": "Parelcouscous met pompoen en feta",
        "extra": "Met kastanjechampignons, hazelnoten en dragon",
        "labels": [
            "Eet mij eerst",
            "Veggie",
            "Chef's Choice",
            "Caloriebewust"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "612 kcal",
        "ingredients": [
            "Ui",
            "Kastanjechampignons",
            "Pompoenblokjes",
            "Parelcouscous",
            "Verse dragon",
            "Feta",
            "Hazelnoten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscous-met-pompoen-en-feta-49261336.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscous-met-pompoen-en-feta-5a3120fb2c3e087f67002372-6cd72e44.pdf"
    },
    {
        "title": "Orzo met broccoli, pecorino en pijnboompitten",
        "extra": "Met verse tijm",
        "labels": [
            "Veggie",
            "Caloriebewust",
            "Chef's Choice"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "684 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Champignons",
            "Verse tijm",
            "Pijnboompitten",
            "Orzo",
            "Geraspte pecorino"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/orzo-met-broccoli-pecorino-en-pijnboompitten-9e517ea2.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/orzo-met-broccoli-pecorino-en-pijnboompitten-5a312aedc288001ad0345602-7a195404.pdf"
    },
    {
        "title": "Zoete paprika-bataatsoep",
        "extra": "Met cr\u00e8me fra\u00eeche en een kurkumabroodje",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "592 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Zoete aardappel",
            "Rode paprika",
            "Kurkumabroodje",
            "Pompoenpitten",
            "Volle yoghurt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/zoete-paprika-bataatsoep-c8abd8ce.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/zoete-paprika-bataatsoep-5a31336fc2880020c0350dc2-adfa85d2.pdf"
    },
    {
        "title": "Koolrabistamppot met gerookte kip",
        "extra": "Met appel en romige saus van geitenkaas",
        "labels": ["Familie"],
        "allergens": [
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "607 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Koolrabi",
            "Appel",
            "Sjalot",
            "Gerookte kipfilet",
            "Hazelnoten",
            "Verse geitenkaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/koolrabistamppot-met-gerookte-kip-d6df57c5.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/koolrabistamppot-met-gerookte-kip-5a313a41c28800258632c4e2-b6dd983b.pdf"
    },
    {
        "title": "Udonnoedels met pittige pindasaus",
        "extra": "Met komkommer, avocado en koriander",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Pinda's"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "724 kcal",
        "ingredients": [
            "Verse udonnoedels",
            "Komkommer",
            "Bosui",
            "Verse koriander",
            "Limoen",
            "Verse gember",
            "Knoflookteen",
            "Avocado",
            "Pindakaas",
            "Gezouten pinda's"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/undonnoedels-met-pittige-pindasaus-71d4b2d9.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/undonnoedels-met-pittige-pindasaus-5a313e312c3e08148a062182-9f8fd52d.pdf"
    },
    {
        "title": "Casarecce met kipgehakt in roomsaus",
        "extra": "Met spinazie en cherrytomaatjes",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "755 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Rode cherrytomaten",
            "Casarecce",
            "Kipgehakt met Italiaanse kruiden",
            "Paprikapoeder",
            "Kookroom",
            "Spinazie"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/caserecce-met-kipgehakt-in-roomsaus-56051d6a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/casarecce-met-kipgehakt-in-roomsaus-5a3143652c3e081768138fc2-16569ccb.pdf"
    },
    {
        "title": "Risotto met groene asperges en eekhoorntjesbrood",
        "extra": "Met portobello uit de oven",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Moeilijk",
        "kcal": "670 kcal",
        "ingredients": [
            "Gedroogd eekhoorntjesbrood",
            "Sjalot",
            "Knoflookteen",
            "Groene asperges",
            "Risottorijst",
            "Portobello",
            "Geitenkaas, geraspt",
            "Gedroogde rozemarijn",
            "Citroen",
            "Verse dragon"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/risotto-met-groene-asperges-en-eekhoorntjesbrood-bcdeb5c8.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/risotto-met-groene-asperges-en-eekhoorntjesbrood-5a323c67c28800568f36bb72-214d2831.pdf"
    },
    {
        "title": "Zeebaars met geroosterde bloemkool-spinaziesalade",
        "extra": "Met gebakken aardappelpartjes en botersaus",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "677 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Bloemkool",
            "Tomaat",
            "Sjalot",
            "Knoflookteen",
            "Babyspinazie",
            "Zeebaarsfilet met huid"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/winterse-zeebaars-met-geroosterd-1e9737f4.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/zeebaars-met-geroosterde-bloemkool-spinaziesalade-582de6f1fb2bc6307f8b456b-ba7c5bea.pdf"
    },
    {
        "title": "Italiaanse orzo met gekruid rundergehakt",
        "extra": "Met romige tomatensaus",
        "labels": [
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "830 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Orzo",
            "Tomatenpuree",
            "Rundergehakt",
            "Groentemix: Paprika, Prei, Chinese Kool, Witte Kool & Winterpeen",
            "Kruidenroomkaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/italiaanse-orzo-met-gekruid-rundergehakt-742b808d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/italiaanse-orzo-met-gekruid-rundergehakt-59217ab899052d5c7a4b636b-852ae892.pdf"
    },
    {
        "title": "Aubergine uit de oven met rigatoni",
        "extra": "Met tomaat, oude kaas en salade",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten",
            "Selderij"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "734 kcal",
        "ingredients": [
            "Aubergine",
            "Tomaat",
            "Knoflookteen",
            "Passata",
            "Geraspte oude kaas",
            "Rigatoni",
            "Rucolamelange",
            "Vers basilicum"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/5943d9d42310a817f4234898-72d614e0.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/aubergine-uit-de-oven-met-tomaat-oude-kaas-en-rigatoni-5943d9d42310a817f4234898-d3fc1d15.pdf"
    },
    {
        "title": "Tomatenrisotto met buffelmozzarella",
        "extra": "Met verse basilicum, rode peper en pecorino",
        "labels": [
            "Veggie",
            "Caloriebewust",
            "Chef's Choice"
        ],
        "allergens": [
            "Selderij",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Moeilijk",
        "kcal": "642 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Bleekselderij",
            "Pruimtomaat",
            "Vers basilicum",
            "Risottorijst",
            "Rode peper",
            "Buffelmozzarella",
            "Geraspte pecorino"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/595014893131004d223113f7-f33a0496.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/tomatenrisotto-met-buffelmozzarella-595014893131004d223113f7-3e082648.pdf"
    },
    {
        "title": "Oranje groenten-pindasoep met ei",
        "extra": "Met Libanees platbrood",
        "labels": [
            "Eet mij eerst",
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Selderij",
            "Mosterd",
            "Eieren",
            "Gluten",
            "Pinda's"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "823 kcal",
        "ingredients": [
            "Knoflookteen",
            "Wortel",
            "Bosui",
            "Pompoenblokjes",
            "Kerriepoeder",
            "Ei",
            "Wit platbrood",
            "Pindakaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/5968d2712310a80c4c51c03b-324e33ce.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/oranje-groenten-pindasoep-met-ei-5968d2712310a80c4c51c03b-d5bdf7b5.pdf"
    },
    {
        "title": "Ovenkrieltjes met kip en groene pesto",
        "extra": "Met sperziebonen",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "781 kcal",
        "ingredients": [
            "Krieltjes",
            "Sperziebonen",
            "Groene pesto",
            "Kippendijreepjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/ovenkrieltjes-met-kip-en-groene-pesto-268519ca.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/ovenkrieltjes-met-kip-en-groene-pesto-59842c1f51d3f1294c5ff152-7b60a7db.pdf"
    },
    {
        "title": "Parelcouscous met garnalen en courgette",
        "extra": "Met verse tijm, rode peper en citroen",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Schaaldieren"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "611 kcal",
        "ingredients": [
            "Rode ui",
            "Knoflookteen",
            "Rode peper",
            "Citroen",
            "Verse tijm",
            "Courgette",
            "Parelcouscous",
            "Garnalen",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscous-met-garnalen-en-courgette-fb8cd485.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscous-met-garnalen-en-courgette-5a3b8d3c2c3e080c7e40a172-454b11ba.pdf"
    },
    {
        "title": "Pittige noedels met omeletreepjes",
        "extra": "Met Aziatische groenten, pinda's en limoen",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Pinda's",
            "Eieren",
            "Gluten",
            "Soja"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "709 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Limoen",
            "Gezouten pinda's",
            "Ei",
            "Verse udonnoedels",
            "Chinese groentemix",
            "Sojasaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pittige-noedels-met-omeletreepjes-562c324f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pittige-noedels-met-omeletreepjes-5a3bac922c3e081f830a3ae2-1d48bb12.pdf"
    },
    {
        "title": "Kruidige jambalaya met pittige kip",
        "extra": "Met spekjes, paprika en sperziebonen",
        "labels": ["Familie"],
        "allergens": [
            "Pinda's",
            "Eieren",
            "Gluten",
            "Soja"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "787 kcal",
        "ingredients": [
            "Rode ui",
            "Knoflookteen",
            "Oranje paprika",
            "Sperziebonen",
            "Verse bladpeterselie",
            "Paprikapoeder",
            "Gemalen komijnzaad",
            "Gemalen kaneel",
            "Tomatenpuree",
            "Surinaamse rijst",
            "Spekblokjes",
            "Kippendijreepjes",
            "Gemalen korianderzaad"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kruidige-jambalaya-met-pittige-kip-de79e411.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kruidige-jambalaya-met-pittige-kip-5a3bb1c4c288004f5b27dfc2-9170c458.pdf"
    },
    {
        "title": "Romige fettuccine met broccoli",
        "extra": "Met geitenkaas, walnoten en zongedroogde tomaatjes",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Noten",
            "Gluten",
            "Melk (inclusief lactose)",
            "Eieren"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "805 kcal",
        "ingredients": [
            "Champignons",
            "Sjalot",
            "Knoflookteen",
            "Zongedroogde tomaten",
            "Walnoten",
            "Fettuccine",
            "Gedroogde oregano",
            "Kookroom",
            "Geitenkaas, geraspt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-fettuccine-met-broccoli-59489cce.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-fettuccine-met-broccoli-5a3bb42c2c3e0825d447be32-affd12d9.pdf"
    },
    {
        "title": "Pitabroodjes met kalkoenshoarma",
        "extra": "Met gele peen, yoghurt en frisse veldsla",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "806 kcal",
        "ingredients": [
            "Gele wortel",
            "Prei",
            "Kalkoenshoarma",
            "Volkoren pitabroodje",
            "Veldsla",
            "Naturel yoghurt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pitabroodjes-met-kalkoenshoarma-b4ac60f3.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pitabroodjes-met-kalkoenshoarma-5a3bb8b0c2880053ae3a9412-2d478e62.pdf"
    },
    {
        "title": "Steak frites met dragonmayonaise",
        "extra": "Met gepofte cherrytomaat en groene salade",
        "labels": ["Familie"],
        "allergens": [
            "Eieren",
            "Mosterd",
            "Sulfiet"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "895 kcal",
        "ingredients": [
            "Kogelbiefstuk",
            "Vastkokende aardappelen",
            "Rode cherrytomaten",
            "Botersla",
            "Verse kervel",
            "Verse dragon",
            "Bosui",
            "Kappertjes en gesneden augurken",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/steak-frites-met-dragonmayonaise-a02892bf.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/steak-frites-met-dragonmayonaise-5a3bbe9d2c3e082aeb7666a2-9d63ab0d.pdf"
    },
    {
        "title": "Fettuccine met roomsaus en serranoham",
        "extra": "Met champignons en citroen",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice"
        ],
        "allergens": [
            "Gluten",
            "Noten",
            "Eieren",
            "Melk (inclusief lactose)",
            "Lupine",
            "Mosterd",
            "Sesamzaad",
            "Soja"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "814 kcal",
        "ingredients": [
            "Sjalot",
            "Champignons",
            "Gedroogde oregano",
            "Fettuccine",
            "Walnoten",
            "Serranoham",
            "Citroen",
            "Biologische cr\u00e8me fra\u00eeche",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/593167e23bddb56e503ee9a7-0cade429.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/fettuccine-met-roomsaus-en-serranoham-593167e23bddb56e503ee9a7-dd5f6996.pdf"
    },
    {
        "title": "Broccoli-courgettesoep met olijvenciabatta",
        "extra": "Met courgettelinten en pompoenpitten",
        "labels": [
            "Caloriebewust",
            "Veggie",
            "Chef's Choice"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "598 kcal",
        "ingredients": [
            "Ui",
            "Kruimige aardappelen",
            "Champignons",
            "Courgette",
            "Pompoenpitten",
            "Olijvenciabatta",
            "Biologische cr\u00e8me fra\u00eeche"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/broccoli-courgettesoep-met-olijvenciabatta-075aaf23.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/broccoli-courgettesoep-met-olijvenciabatta-59a0441ba1e1ea38384b0962-bf901091.pdf"
    },
    {
        "title": "Gevulde portobello met feta en salade",
        "extra": "Met rucola, veldsa, bataat en linzen",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice",
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "748 kcal",
        "ingredients": [
            "Groene linzen",
            "Zoete aardappel",
            "Gedroogde tijm",
            "Rode paprika",
            "Feta",
            "Portobello",
            "Gemengde sla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gevulde-portobello-met-feta-en-salade-d44cd4b5.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gevulde-portobello-met-feta-en-salade-59a83729a2882a1e1f2700d2-82cde0f5.pdf"
    },
    {
        "title": "Traditionele shakshuka met ei",
        "extra": "Met geitenkaas en speltbaguette",
        "labels": [
            "Caloriebewust",
            "Chef's Choice",
            "Veggie"
        ],
        "allergens": [
            "Eieren",
            "Melk (inclusief lactose)",
            "Gluten"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "601 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Rode peper",
            "Rode paprika",
            "Pruimtomaat",
            "Verse krulpeterselie",
            "Ei",
            "Verse geitenkaas",
            "Speltbaguette"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/traditionele-shakshuka-met-ei-7b8d58fd.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/traditionele-shakshuka-met-ei-59ba6a5dc94eac4ad518f852-f7aa6222.pdf"
    },
    {
        "title": "Schelvisfilet met bieslooksaus",
        "extra": "Met gebakken aaardappeltjes en bospeen",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Vis"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "551 kcal",
        "ingredients": [
            "Wortel",
            "Vastkokende aardappelen",
            "Verse bieslook",
            "Volle yoghurt",
            "Schelvisfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/schelvisfilet-met-bieslooksaus-d7d8b82d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/schelvisfilet-met-bieslooksaus-5a43a47b2c3e0855fd3b7fb2-beb1436a.pdf"
    },
    {
        "title": "Zalm in zoet-pittige marinade",
        "extra": "Met knoflookbroccoli en zilvervliesrijst",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Vis"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "721 kcal",
        "ingredients": [
            "Knoflookteen",
            "Verse gember",
            "Rode peper",
            "Champignons",
            "Sojasaus",
            "Zalmfilet met huid",
            "Zilvervliesrijst"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/zalm-in-zoet-pittige-marinade-1df9ce67.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/zalm-in-zoet-pittige-marinade-5a43b5a2c2880022af7badc6-90821e48.pdf"
    },
    {
        "title": "Gnocchi met kip in romige saus",
        "extra": "Met venkel en basilicum",
        "labels": [
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten",
            "Eieren",
            "Sulfiet",
            "Tarwe",
            "Selderij"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "730 kcal",
        "ingredients": [
            "Rode ui",
            "Tomaat",
            "Venkel",
            "Kippendijreepjes",
            "Kookroom",
            "Gnocchi",
            "Vers basilicum",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gnocchi-met-kip-in-romige-saus-f320f719.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gnocchi-met-kip-in-romige-saus-5a43b935c2880025c10a55f2-01f49eb7.pdf"
    },
    {
        "title": "Rigatoni met aubergine-tomatensaus",
        "extra": "Met olijven, provolone en pijnboompitten",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Selderij",
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "726 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Aubergine",
            "Pruimtomaat",
            "Vers basilicum",
            "Leccino olijven",
            "Rigatoni",
            "Pijnboompitten",
            "Paprikapoeder",
            "Tomatenpuree",
            "Gedroogde oregano",
            "Provolonevlokken"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rigatoni-met-aubergine-tomatensaus-dadbb224.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rigatoni-met-aubergine-tomatensaus-5a44bf16c288005d4d62aa52-efb3c094.pdf"
    },
    {
        "title": "Oosterse tartaar met kastanjechampignons",
        "extra": "Met rijst, paksoi en geroosterd sesamzaad",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Sesamzaad",
            "Vis",
            "Gluten",
            "Soja"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "520 kcal",
        "ingredients": [
            "Verse gember",
            "Knoflookteen",
            "Sjalot",
            "Sesamzaad",
            "Kastanjechampignons",
            "Bosui",
            "Paksoi",
            "Surinaamse rijst",
            "Vissaus",
            "Rundertartaar",
            "Sojasaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/oosterse-tartaar-met-kastangjechampignons-36eb9145.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/oosterse-tartaar-met-kastangjechampignons-5a44e2832c3e08319454bd82-07634937.pdf"
    },
    {
        "title": "Parelcouscous met champignons en gruy\u00e8re",
        "extra": "Met rucola en walnoten",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "658 kcal",
        "ingredients": [
            "Parelcouscous",
            "Knoflookteen",
            "Champignons",
            "Geraspte gruy\u00e8re",
            "Rucola",
            "Walnoten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscous-met-champignons-en-gruyere-9e6e69dd.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscous-met-champignons-en-gruyere-5a44f86a2c3e0840ba6b7582-0f79b6f8.pdf"
    },
    {
        "title": "Rigatoni met broccoli en spekjes",
        "extra": "Met cr\u00e8me fra\u00eeche en belegen kaas",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "874 kcal",
        "ingredients": [
            "Champignons",
            "Rigatoni",
            "Ui",
            "Knoflookteen",
            "Verse bladpeterselie",
            "Spekblokjes",
            "Biologische cr\u00e8me fra\u00eeche",
            "Geraspte belegen kaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rigatoni-met-broccoli-en-spekjes-0ac3f512.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rigatoni-met-broccoli-en-spekjes-5a4502972c3e0846bb768ab2-76f698d0.pdf"
    },
    {
        "title": "Garnalen in verse tomatensaus",
        "extra": "Met romige aardappelpuree en geroosterde bimi",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Schaaldieren",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "717 kcal",
        "ingredients": [
            "Kruimige aardappelen",
            "Broccolini",
            "Sjalot",
            "Mini-romatomaten",
            "Verse bladpeterselie",
            "Citroen",
            "Garnalen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/garnalen-in-verse-tomatensaus-4d210cbb.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/garnalen-in-verse-tomatensaus-5a4509832c3e084b6732a602-c5dfd94a.pdf"
    },
    {
        "title": "Schelvis met gesmoorde prei",
        "extra": "Met gekarameliseerde witlof en gekookte aardappelen",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Chef's Choice"
        ],
        "allergens": [
            "Mosterd",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "553 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Witlof",
            "Prei",
            "Nootmuskaat",
            "Geel mosterdzaad",
            "Schelvisfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/58ceb773043c3c4b8c2996ac-bb643652.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/schelvis-met-gesmoorde-prei-58ceb773043c3c4b8c2996ac-f342c895.pdf"
    },
    {
        "title": "Gebakken kabeljauwfilet met botersaus",
        "extra": "Met zoete parelcouscous, peen en tomaat",
        "labels": [
            "Eet mij eerst",
            "Caloriebewust",
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "682 kcal",
        "ingredients": [
            "Wortel",
            "Rode cherrytomaten",
            "Verse bladpeterselie",
            "Tuinkers",
            "Parelcouscous",
            "Rozijnen",
            "Kabeljauwfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/5968d2682310a80c4c51c036-b6ec6c7c.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-kabeljauwfilet-met-botersaus-5968d2682310a80c4c51c036-2cab3712.pdf"
    },
    {
        "title": "Stamppot van pastinaak en bataat",
        "extra": "Met rucola, pecannoten en kruidenkaasblokjes",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "688 kcal",
        "ingredients": [
            "Kruimige aardappelen",
            "Zoete aardappel",
            "Pastinaak",
            "Courgette",
            "Verse rozemarijn",
            "Rucola",
            "Pecannoten",
            "Kruidenkaasblokjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/stampot-met-pastinaak-en-bataat-0a87dd02.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/stamppot-met-pastinaak-en-bataat-59c4ec70c9fd0843ff772672-485907a7.pdf"
    },
    {
        "title": "Kip-cheeseburgers met zoete rode ui",
        "extra": "Met geroosterde bataat en frisse salade",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Sesamzaad",
            "Lupine"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "845 kcal",
        "ingredients": [
            "Zoete aardappel",
            "Mini-hamburgerbol",
            "Rode ui",
            "Tomaat",
            "Romaine sla",
            "Gekruide kipburger",
            "Geraspte cheddar"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kip-cheeseburgers-met-zoete-rode-ui-93294ad0.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kip-cheeseburgers-met-zoete-rode-ui-59fafacdc9fd0834f121efa2-b0c9fc07.pdf"
    },
    {
        "title": "Groentelasagne met zelfgemaakte roomsaus",
        "extra": "Met aubergine, spinazie en oude kaas",
        "labels": [
            "Veggie",
            "Chef's Choice"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten",
            "Eieren"
        ],
        "duration": "50 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "854 kcal",
        "ingredients": [
            "Aubergine",
            "Ui",
            "Knoflookteen",
            "Gedroogde rozemarijn",
            "Spinazie",
            "Kookroom",
            "Geraspte oude kaas",
            "Lasagnebladen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/groentelasagne-met-zelfgemaakte-roomsaus-16070365.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/groentelasagne-met-zelfgemaakte-roomsaus-5a04600aa1e1ea7e25173ce2-6898fa73.pdf"
    },
    {
        "title": "Kokossoep met noedels, sugarsnaps en ei",
        "extra": "Met Chinese kool en citroengras",
        "labels": [
            "Eet mij eerst",
            "Veggie",
            "Chef's Choice"
        ],
        "allergens": [
            "Eieren",
            "Gluten",
            "Soja"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "781 kcal",
        "ingredients": [
            "Verse gember",
            "Rode peper",
            "Chinese kool",
            "Sugarsnaps",
            "Limoen",
            "Kokosmelk",
            "Ei",
            "Gemalen citroengras",
            "Noedels",
            "Sojasaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kokossoep-met-noedels-sugarsnaps-en-ei-3a0a2921.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kokossoep-met-noedels-sugarsnaps-en-ei-5a4cfdb52c3e0855175b65d2-cafeee35.pdf"
    },
    {
        "title": "Bietenrisotto met feta en munt",
        "extra": "Met veldsla en walnoten",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "658 kcal",
        "ingredients": [
            "Sjalot",
            "Walnoten",
            "Risottorijst",
            "Gekookte rode bieten",
            "Feta",
            "Verse munt",
            "Veldsla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/bietenrisotto-met-feta-en-munt-518ec281.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/bietenrisotto-met-feta-en-munt-5a4df0fb2c3e0805300fbdd2-3a88e009.pdf"
    },
    {
        "title": "Kippendij met citroen en krieltjes",
        "extra": "Met gebakken snijbonen en knoflook",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Makkelijk",
        "kcal": "835 kcal",
        "ingredients": [
            "Citroen",
            "Krieltjes",
            "Gedroogde rozemarijn",
            "Kippendij met bot",
            "Verse tijm",
            "Knoflookteen",
            "Gesneden snijbonen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kippendij-met-citroen-en-krieltjes-ec3d821e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kippendij-met-citroen-en-krieltjes-5a4df68c2c3e08086a039672-5037fcac.pdf"
    },
    {
        "title": "Vegetarische caesar salade",
        "extra": "Met sperziebonen, kappertjes en croutons",
        "labels": [
            "Veggie",
            "Caloriebewust"
        ],
        "allergens": [
            "Eieren",
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Mosterd"
        ],
        "duration": "20 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "728 kcal",
        "ingredients": [
            "Sperziebonen",
            "Ei",
            "Witte ciabatta",
            "Mini-romaine sla",
            "Citroen",
            "Mayonaise",
            "Geraspte grana padano",
            "Kappertjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/vegetarische-caesar-salade-854b23b1.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/vegetarische-caesar-salade-5a4dfc6e2c3e080ca7615581-78d71cc0.pdf"
    },
    {
        "title": "Japanse ramen met gebakken kabeljauw",
        "extra": "Met udon noedels, spitskool en broccoli",
        "labels": [
            "Caloriebewust"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Vis"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "447 kcal",
        "ingredients": [
            "Verse gember",
            "Rode peper",
            "Wortel",
            "Champignons",
            "Spitskool en broccoli",
            "Verse udonnoedels",
            "Sojasaus",
            "Kabeljauwfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/japanse-ramen-met-gebakken-kabeljauw-2168790d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/japanse-ramen-met-gebakken-kabeljauw-5a4e00c02c3e080f3a56e432-c5011a23.pdf"
    },
    {
        "title": "Varkenshaas met orecchiette",
        "extra": "Met paddenstoelen-roomsaus en rucola",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "854 kcal",
        "ingredients": [
            "Sjalot",
            "Knoflookteen",
            "Gemengde gesneden paddenstoelen",
            "Varkenshaas",
            "Gedroogde rozemarijn",
            "Orecchiette",
            "Biologische cr\u00e8me fra\u00eeche",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/varkenshaas-met-orecchiette-7e3b10d3.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/varkenshaas-met-orecchiette-5a4e196f2c3e081f7e1b32c2-e2b93140.pdf"
    },
    {
        "title": "Italiaanse pitabroodjes met rundergehakt",
        "extra": "Met aardappelpartjes, tomaat-pestosalade en sla",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "1001 kcal",
        "ingredients": [
            "Aardappelpartjes",
            "Gedroogde rozemarijn",
            "Volkoren pitabroodje",
            "Tomaat",
            "Groene pesto",
            "Rundergehakt met Italiaanse kruiden",
            "IJsbergsla en veldsla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/italiaanse-pitabroodjes-met-rundergehakt-f52e33c0.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/italiaanse-pitabroodjes-met-rundergehakt-5a4e2f8ec28800099b1b3271-470ccef0.pdf"
    },
    {
        "title": "Risotto van orzo met paddenstoelen",
        "extra": "Met kruidenkaas en verse basilicum",
        "labels": [
            "Veggie",
            "Caloriebewust",
            "Familie"
        ],
        "allergens": [
            "Selderij",
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "612 kcal",
        "ingredients": [
            "Sjalot",
            "Knoflookteen",
            "Vers basilicum",
            "Bosui",
            "Orzo",
            "Pompoenpitten",
            "Gemengde gesneden paddenstoelen",
            "Kruidenkaasblokjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/58fbbc76171c5852577ed12f-97a74342.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/risotto-van-orzo-met-paddenstoelen-58fbbc76171c5852577ed12f-53dbc3f7.pdf"
    },
    {
        "title": "Tomaat-paprikasoep met dragon",
        "extra": "Met parelcouscous en soepstengels",
        "labels": [
            "Veggie",
            "Chef's Choice"
        ],
        "allergens": [
            "Sulfiet",
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Eieren"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "678 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Pruimtomaat",
            "Rode paprika",
            "Gedroogde abrikozen",
            "Verse dragon",
            "Parelcouscous",
            "Witte ciabatta",
            "Biologische zure room",
            "Geraspte grana padano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/tomaat-paprikasoep-met-parelcouscous-en-dragon-df56b27d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/tomaat-paprikasoep-met-parelcouscous-en-dragon-59842e8cd56afa0fba33bc62-2ab59036.pdf"
    },
    {
        "title": "Couscous met halloumi en gebakken rode paprika",
        "extra": "Met limoen-knoflookolie en yoghurt",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Moeilijk",
        "kcal": "816 kcal",
        "ingredients": [
            "Rode paprika",
            "Volkoren couscous",
            "Rozijnen",
            "Amandelen",
            "Knoflookteen",
            "Verse koriander",
            "Limoen",
            "Halloumi",
            "Volle yoghurt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/couscous-met-halloumi-en-gepofte-paprika-555a4a39.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/couscous-met-halloumi-en-gebakken-rode-paprika-598478d1c9fd08618e7e2e42-675707e0.pdf"
    },
    {
        "title": "Kipdrumsticks met uienjus en aardappelen",
        "extra": "Met geroosterde broccoli en verse peterselie",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Sulfiet",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "845 kcal",
        "ingredients": [
            "Champignons",
            "Gemarineerde kipdrumsticks",
            "Knoflookteen",
            "Vastkokende aardappelen",
            "Verse bladpeterselie",
            "Pikante uienchutney"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kipdrumsticks-met-uienjus-en-aardappelen-533cca4d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kipdrumsticks-met-uienjus-en-aardappelen-59a035faa1e1ea655f738ec2-3531384e.pdf"
    },
    {
        "title": "Italiaanse platbroodpizza met buffelmozzarella",
        "extra": "Met courgette en gele paprika",
        "labels": [
            "Veggie",
            "Chef's Choice"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "670 kcal",
        "ingredients": [
            "Knoflookteen",
            "Courgette",
            "Gele paprika",
            "Buffelmozzarella",
            "Passata",
            "Paprikapoeder",
            "Wit platbrood",
            "Geraspte belegen kaas",
            "Gedroogde oregano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/italiaanse-platbroodpizza-s-met-buffelmozzarella-92266d67.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/italiaanse-platbroodpizza-s-met-buffelmozzarella-59b28965450cfa47ce47fb32-5128d34a.pdf"
    },
    {
        "title": "Romige aardappelschotel met kabeljauw en spekjes",
        "extra": "Met vers geraspte nootmuskaat",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "636 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Prei",
            "Nootmuskaat",
            "Spekblokjes",
            "Kabeljauwfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-aardappelschotel-met-kabeljauw-en-spekjes-8080e87e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-aardappelschotel-met-kabeljauw-en-spekjes-59ba38027cc0c1556f03f162-9bab2b59.pdf"
    },
    {
        "title": "Rigatoni met kippendijstukjes en pesto",
        "extra": "Met sperziebonen",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "879 kcal",
        "ingredients": [
            "Sperziebonen",
            "Rigatoni",
            "Knoflookteen",
            "Kippendijreepjes",
            "Groene pesto"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rigatoni-met-kippendijstukjes-en-pesto-aa01d0ef.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rigatoni-met-kippendijstukjes-en-pesto-59ba3fcbad1d6c4255316e92-e1aca43a.pdf"
    },
    {
        "title": "Rundertartaar met champignons",
        "extra": "Met aardappelen en frisse salade",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Chef's Choice"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "621 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Rode ui",
            "Verse rozemarijn",
            "Verse marjolein",
            "Champignons",
            "Komkommer",
            "Tomaat",
            "Mesclun",
            "Rundertartaar"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rundertartaar-met-champignons-677464bb.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rundertartaar-met-champignons-59e9adfb043c3c09023d8492-17ac5b8c.pdf"
    },
    {
        "title": "Oosters vispakketje met prei en tomaat",
        "extra": "Met plakkerige kokosrijst",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "623 kcal",
        "ingredients": [
            "Verse gember",
            "Rode peper",
            "Prei",
            "Pruimtomaat",
            "Limoen",
            "Schelvisfilet",
            "Kokosmelk",
            "Zilvervliesrijst"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/oosters-vispakketje-met-prei-en-tomaat-6f2df953.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/oosters-vispakketje-met-prei-en-tomaat-5a043fba7901f978301f2722-e55064ff.pdf"
    },
    {
        "title": "Bulgur met gebakken champignons",
        "extra": "Met een zachtgekookt ei, pecorino en verse kruiden",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "643 kcal",
        "ingredients": [
            "Knoflookteen",
            "Sjalot",
            "Citroen",
            "Verse dragon",
            "Verse bladpeterselie",
            "Champignons",
            "Kastanjechampignons",
            "Bulgur",
            "Ei",
            "Mascarpone",
            "Geraspte pecorino"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/bulgur-met-gebakken-champignons-e3ba839f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/bulgur-met-gebakken-champignons-5a5616232c3e0874da734052-900cf3ec.pdf"
    },
    {
        "title": "Heekfilet met aardappelschijfjes en prei",
        "extra": "Met rode ui en dillemayonaise",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis",
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "703 kcal",
        "ingredients": [
            "Aardappelschijfjes",
            "Rode ui",
            "Prei",
            "Heekfilet met huid",
            "Verse dille",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/heekfilet-met-aardappelrjes-en-prei-4608fe58.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/heekfilet-met-aardappelrjes-en-prei-5a561d252c3e0879dc377d62-f005b2cc.pdf"
    },
    {
        "title": "Gebakken zeebaars met citroenroomsaus",
        "extra": "Met aardpeer en krieltjes",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Eieren",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "50 minuten",
        "difficulty": "Moeilijk",
        "kcal": "1235 kcal",
        "ingredients": [
            "Krieltjes",
            "Aardpeer",
            "Citroen",
            "Verse citroenmelisse",
            "Ei",
            "Zeebaarsfilet met huid",
            "Slagroom"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-zeebaars-met-citroenroomsaus-5b9f53fd.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-zeebaars-met-citroenroomsaus-5a5621f7c2880056d80dad92-57996f5d.pdf"
    },
    {
        "title": "Courgette-pestosoep",
        "extra": "Met gegratineerde speltbaguette",
        "labels": [
            "Veggie",
            "Chef's Choice"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Noten",
            "Gluten"
        ],
        "duration": "35 minuten",
        "difficulty": "Makkelijk",
        "kcal": "754 kcal",
        "ingredients": [
            "Ui",
            "Vastkokende aardappelen",
            "Courgette",
            "Pompoenpitten",
            "Groene pesto",
            "Speltbaguette",
            "Geraspte belegen kaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/56ab8dbf6ced6ec9098b456e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/courgette-pestosoep-56ab8dbf6ced6ec9098b456e-13c0f9b1.pdf"
    },
    {
        "title": "Dorade in kappertjes-botersaus",
        "extra": "Met pikante spinaziesalade",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice"
        ],
        "allergens": [
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "752 kcal",
        "ingredients": [
            "Kruimige aardappelen",
            "Sjalot",
            "Knoflookteen",
            "Gele peper",
            "Rode cherrytomaten",
            "Spinazie",
            "Doradefilet met huid",
            "Kappertjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/dorade-in-kappertjes-botersaus-8cc6cad7.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/dorade-in-kappertjes-botersaus-57a4ba566ced6ed23c8b456b-192c3192.pdf"
    },
    {
        "title": "Champignonrisotto van orzo",
        "extra": "Met walnoten en Parmezaans kaas",
        "labels": [
            "Veggie",
            "Chef's Choice",
            "Caloriebewust"
        ],
        "allergens": [
            "Noten",
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "673 kcal",
        "ingredients": [
            "Ui",
            "Bosui",
            "Kastanjechampignons",
            "Walnoten",
            "Orzo",
            "Geraspte parmigiano reggiano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/champignonrisotto-van-orzo-f5a233b9.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/champignonrisotto-van-orzo-5854291cfb2bc69e0e8b456a-306832eb.pdf"
    },
    {
        "title": "Spaanse rijstschotel met garnalen",
        "extra": "Met olijven en citroen",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Schaaldieren"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "635 kcal",
        "ingredients": [
            "Knoflookteen",
            "Ui",
            "Rode paprika",
            "Wortel",
            "Gemalen kurkuma",
            "Basmatirijst",
            "Tomatenpuree",
            "Leccino olijven",
            "Citroen",
            "Grote garnalen",
            "Gemalen korianderzaad"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/spaanse-rijstschotel-met-garnalen-en-olijven-3d4b7843.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/spaanse-rijstschotel-met-garnalen-59a829b0043c3c4dfa364e92-cf9c6281.pdf"
    },
    {
        "title": "Gebakken rijst met spiegelei en cashewnoten",
        "extra": "Met roerbakgroenten en bosui",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Sesamzaad",
            "Pinda's",
            "Noten",
            "Eieren",
            "Gluten",
            "Soja"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "842 kcal",
        "ingredients": [
            "Zilvervliesrijst",
            "Sesamzaad",
            "Gezouten cashewnoten",
            "Knoflookteen",
            "Verse gember",
            "Bosui",
            "Sperziebonen",
            "Ei",
            "Sojasaus",
            "Wortel"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-rijst-met-spiegelei-en-cashewnoten-dea715b4.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-rijst-met-spiegelei-en-cashewnoten-59e07768ad1d6c531f27b042-bafd1696.pdf"
    },
    {
        "title": "Kip met coppa di parma en sperziebonen",
        "extra": "Met rozemarijn-aardappeltjes",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "733 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Gedroogde rozemarijn",
            "Sperziebonen",
            "Kippendijfilet",
            "Coppa di Parma",
            "Rode ui",
            "Knoflookteen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kip-met-coppa-di-parma-en-sperziebonen-801bb984.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kip-met-coppa-di-parma-en-sperziebonen-5a042d3d043c3c4fa2424a32-1042f2f3.pdf"
    },
    {
        "title": "Penne met linzen-bolognesesaus en feta",
        "extra": "Met rucola en pompoenpitten",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "880 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Rode cherrytomaten",
            "Linzen",
            "Pompoenpitten",
            "Gedroogde oregano",
            "Tomatenpuree",
            "Volkoren penne",
            "Rucola",
            "Feta"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/penne-met-linzen-bolognesesaus-en-feta-30f63ea8.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/penne-met-linzen-bolognesesaus-en-feta-5a0d8e65ad1d6c592833e082-8109dff2.pdf"
    },
    {
        "title": "Andijviestamppot met gehaktballetjes",
        "extra": "Met zongedroogde tomaten en oude kaas",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "910 kcal",
        "ingredients": [
            "Kruimige aardappelen",
            "Rode ui",
            "Zongedroogde tomaten",
            "Rundergehaktballetjes met Italiaanse kruiden",
            "Gesneden andijvie",
            "Oude-kaasblokjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/andijviestamppot-met-gehaktballetjes-1374b75b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/andijviestamppot-met-gehaktballetjes-5a6096f92c3e083d805b33c2-a3191b40.pdf"
    },
    {
        "title": "Buffelmozzarella en spinazie uit de oven",
        "extra": "Met parelcouscous, tomaat, dille en pijnboompitten",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "678 kcal",
        "ingredients": [
            "Pijnboompitten",
            "Knoflookteen",
            "Sjalot",
            "Spinazie",
            "Limoen",
            "Tomaat",
            "Verse dille",
            "Parelcouscous",
            "Buffelmozzarella"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/buffelmozzarella-en-spinazie-uit-de-oven-c3e9aba0.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/buffelmozzarella-en-spinazie-uit-de-oven-5a60a4eec2880045a50ae774-edff9c14.pdf"
    },
    {
        "title": "Curry met kip en sperziebonen",
        "extra": "Met couscous, munt en amandelen",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "679 kcal",
        "ingredients": [
            "Sperziebonen",
            "Kippendijreepjes",
            "Kokosmelk",
            "Rode currypasta",
            "Volkoren couscous",
            "Verse munt",
            "Amandelen",
            "Naturel yoghurt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/curry-met-kip-en-sperziebonen-761116ef.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/curry-met-kip-en-sperziebonen-5a60ae19c288004a5f6f9ff2-4caa19d7.pdf"
    },
    {
        "title": "Conchiglie met rode pesto en feta",
        "extra": "Met rode paprika, veldsla en basilicum",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Selderij",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "815 kcal",
        "ingredients": [
            "Sjalot",
            "Rode paprika",
            "Tomaat",
            "Conchiglie",
            "Feta",
            "Vers basilicum",
            "Rode pesto",
            "Veldsla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/conchiglie-met-rode-pesto-en-feta-1a9b3d2e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/conchiglie-met-rode-pesto-en-feta-5a60b18e2c3e084a8b40bb72-90e4020d.pdf"
    },
    {
        "title": "Gekonfijte parelhoen met aardappelpuree",
        "extra": "Met in boter gesmoorde mini-romaine en hazelnoten",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "1000 kcal",
        "ingredients": [
            "Hazelnoten",
            "Kruimige aardappelen",
            "Mini-romaine sla",
            "Verse bieslook",
            "Verse dragon",
            "Gekonfijte parelhoenpoot",
            "Biologische cr\u00e8me fra\u00eeche",
            "Sjalot"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gekonfijte-parelhoen-met-aardappelpuree-54670145.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gekonfijte-parelhoen-met-aardappelpuree-5a60b6702c3e084d2b003bd2-da7a0675.pdf"
    },
    {
        "title": "Zeeuwse mosselen met spekjes en krieltjes",
        "extra": "Met venkel en prei",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice"
        ],
        "allergens": [
            "Weekdieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "782 kcal",
        "ingredients": [
            "Krieltjes",
            "Prei",
            "Venkel",
            "Knoflookteen",
            "Spekblokjes",
            "Gedroogde tijm",
            "Mosselen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/597b0f56a1e1ea2f572b8c6a-9a347de9.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/zeeuwse-mosselen-met-spekjes-en-krieltjes-597b0f56a1e1ea2f572b8c6a-780569db.pdf"
    },
    {
        "title": "Siciliaanse orzo met gekruid kipgehakt en rozijnen",
        "extra": "Met broccoli, pecorino en pijnboompitten",
        "labels": [
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "770 kcal",
        "ingredients": [
            "Rode ui",
            "Knoflookteen",
            "Champignons",
            "Orzo",
            "Rozijnen",
            "Pijnboompitten",
            "Kipgehakt met Italiaanse kruiden",
            "Geraspte pecorino"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/597b0f57a1e1ea2f572b8c6b-e8c2d42d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/siciliaanse-orzo-met-gekruid-kipgehakt-en-rozijnen-597b0f57a1e1ea2f572b8c6b-51a5a395.pdf"
    },
    {
        "title": "Cheeseburger met tomaat en cheddar",
        "extra": "Met gebakken aardappeltjes en mayonaise",
        "labels": [
            "Chef's Choice"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Sesamzaad",
            "Lupine",
            "Eieren",
            "Mosterd"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "973 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Mini-hamburgerbol",
            "Rode ui",
            "Vleestomaat",
            "Botersla",
            "Runderburger",
            "Geraspte cheddar",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/cheeseburger-met-tomaat-en-cheddar-ad6e04e6.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/cheeseburger-met-tomaat-en-cheddar-59c4c28ca2882a57b87cbbd2-79daa714.pdf"
    },
    {
        "title": "Bataatstamppot met oude kaas",
        "extra": "Met veldsla, rucola en knapperige pecannoten",
        "labels": [
            "Caloriebewust",
            "Veggie",
            "Chef's Choice"
        ],
        "allergens": [
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "651 kcal",
        "ingredients": [
            "Pecannoten",
            "Zoete aardappel",
            "Kruimige aardappelen",
            "Rode ui",
            "Tomaat",
            "Rucolamelange",
            "Oude-kaasblokjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/bataatstamppot-met-oude-kaas-faaa6167.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/bataatstamppot-met-oude-kaas-59e9fa48c9fd080974591b72-098fcc9d.pdf"
    },
    {
        "title": "Koreaanse biefstukwraps met groenten",
        "extra": "Met zoetzure komkommer",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Soja"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "594 kcal",
        "ingredients": [
            "Knoflookteen",
            "Verse gember",
            "Rode peper",
            "Biefstukreepjes",
            "Sojasaus",
            "Komkommer",
            "Groentemix",
            "Verse koriander",
            "Limoen",
            "Witte tortilla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/koreaanse-biefstukwraps-met-groenten-ae523955.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/koreaanse-biefstukwraps-met-groenten-59fb1cdfa1e1ea06495aa102-5cdea4ff.pdf"
    },
    {
        "title": "Farfalle met spinazie-preisaus",
        "extra": "Met groene pesto en rode peper",
        "labels": [
            "Eet mij eerst",
            "Veggie",
            "Chef's Choice"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "780 kcal",
        "ingredients": [
            "Sjalot",
            "Knoflookteen",
            "Prei",
            "Farfalle",
            "Geraspte parmigiano reggiano",
            "Spinazie",
            "Groene pesto",
            "Rode peper"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/farfalle-met-spinazie-preisaus-1c4b7334.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/farfalle-met-spinazie-preisaus-5a16bbd1053468305b775fc2-f32d0675.pdf"
    },
    {
        "title": "Visstoof met kokosmelk en limoen",
        "extra": "Met rijst en verse koriander",
        "labels": [
            "Eet mij eerst",
            "Caloriebewust",
            "Familie"
        ],
        "allergens": [
            "Selderij",
            "Mosterd",
            "Vis"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "617 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Rode paprika",
            "Kokosmelk",
            "Kerriepoeder",
            "Tomatenblokjes",
            "Pandanrijst",
            "Koolvisfilet zonder huid",
            "Verse koriander",
            "Limoen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/visstoof-met-kokosmelk-en-limoen-458ccf4a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/visstoof-met-kokosmelk-en-limoen-5a684862ae08b558d024bfa2-b79320d8.pdf"
    },
    {
        "title": "Aubergine met yoghurt-tahinsaus",
        "extra": "Met tomaat, feta en spiegelei",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Sesamzaad",
            "Eieren"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "672 kcal",
        "ingredients": [
            "Aubergine",
            "Vastkokende aardappelen",
            "Knoflookteen",
            "Rode cherrytomaten",
            "Citroen",
            "Feta",
            "Verse bladpeterselie",
            "Yoghurt-tahinsaus",
            "Ei"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/aubergine-met-yoghurt-tahinsaus-56d55a34.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/aubergine-met-yoghurt-tahinsaus-5a68583dae08b5658f6bd842-199c18ac.pdf"
    },
    {
        "title": "Aardappel-preisoep met cr\u00e8me fra\u00eeche",
        "extra": "Met bieslook en zelfgemaakte crostini's",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten",
            "Soja"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "734 kcal",
        "ingredients": [
            "Prei",
            "Kruimige aardappelen",
            "Knoflookteen",
            "Geraspte parmigiano reggiano",
            "Witte ciabatta",
            "Verse bieslook",
            "Citroen",
            "Biologische cr\u00e8me fra\u00eeche"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/aardappel-preisoep-met-creme-fraiche-c91af2dd.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/aardappel-preisoep-met-creme-fraiche-5a68704930006c76462dd621-e34e2e9a.pdf"
    },
    {
        "title": "Indonesische curry-noedelsoep",
        "extra": "Met koriander en pinda's",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Selderij",
            "Pinda's"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "685 kcal",
        "ingredients": [
            "Rode peper",
            "Knoflookteen",
            "Groentemix van kastanjechampignons, prei, broccoli en courgette",
            "Besengek-currypasta",
            "Kokosmelk",
            "Verse koriander",
            "Gezouten pinda's",
            "Verse udonnoedels"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/indonesische-curry-noedelsoep-b62050fb.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/indonesische-curry-noedelsoep-5a68791aae08b57d005b0aa1-50ce8671.pdf"
    },
    {
        "title": "Garnalen in venkel-tomatensaus",
        "extra": "Met parelcouscous en kappertjes",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Schaaldieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "639 kcal",
        "ingredients": [
            "Knoflookteen",
            "Rode cherrytomaten",
            "Parelcouscous",
            "Verse bladpeterselie",
            "Gemalen venkelzaad",
            "Garnalen",
            "Kappertjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/garnalen-in-venkel-tomatensaus-42c7643d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/garnalen-in-venkel-tomatensaus-5a687c8e30006c7e62194c01-3538f8a3.pdf"
    },
    {
        "title": "Varkenshaas met pistache-tijmkorst",
        "extra": "Met gekarameliseerde witlof en romige knolselderij-appelpuree",
        "labels": ["Familie"],
        "allergens": [
            "Noten",
            "Selderij",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "784 kcal",
        "ingredients": [
            "Verse tijm",
            "Pistachenoten",
            "Knoflookteen",
            "Varkenshaas",
            "Kruimige aardappelen",
            "Knolselderij",
            "Appel",
            "Witlof",
            "Mascarpone"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/varkenshaas-met-pistache-tijmkorst-475c068d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/varkenshaas-met-pistache-tijmkorst-5a689f9b30006c187340acf2-389119ff.pdf"
    },
    {
        "title": "Japanse noedelsoep met kipfilet",
        "extra": "Met kastanjechampignons en paksoi",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Chef's Choice"
        ],
        "allergens": [
            "Gluten",
            "Soja"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "510 kcal",
        "ingredients": [
            "Verse gember",
            "Rode peper",
            "Kastanjechampignons",
            "Paksoi",
            "Gemarineerde kipfiletreepjes",
            "Verse udonnoedels",
            "Sojasaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/593aac5cc6243b235318c562-62d580ba.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/japanse-noedelsoep-met-kipfilet-593aac5cc6243b235318c562-16f8f5a2.pdf"
    },
    {
        "title": "Rigatonisalade met courgette en feta",
        "extra": "Met cherrytomaten en pijnboompitten",
        "labels": [
            "Veggie",
            "Chef's Choice"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Makkelijk",
        "kcal": "807 kcal",
        "ingredients": [
            "Rigatoni",
            "Rode cherrytomaten",
            "Verse krulpeterselie",
            "Courgette",
            "Knoflookteen",
            "Pijnboompitten",
            "Paprikapoeder",
            "Feta"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/5950148e3131004d223113fb-2c5271f4.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rigatonisalade-met-courgette-en-feta-5950148e3131004d223113fb-45496e97.pdf"
    },
    {
        "title": "Schelvis met spek en sperziebonen",
        "extra": "Met goudbruine aardappelschijfjes",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Vis",
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "821 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Sperziebonen",
            "Ontbijtspek",
            "Schelvisfilet",
            "Nootmuskaat",
            "Ravigotesaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/5971e23e51d3f15b110a9e85-734a47dc.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/schelvis-met-spek-en-sperziebonen-5971e23e51d3f15b110a9e85-64b7ee03.pdf"
    },
    {
        "title": "Rijst met pittige groenten en falafel",
        "extra": "Met harissa en frisse tzatziki",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Selderij",
            "Mosterd"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "853 kcal",
        "ingredients": [
            "Basmatirijst",
            "Knoflookteen",
            "Komkommer",
            "Magere yoghurt",
            "Champignons",
            "Wortel",
            "Harissa",
            "Kerriepoeder",
            "Falafel"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/5971e24751d3f15b110a9e8c-a290bcc4.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rijst-met-pittige-groenten-en-falafel-5971e24751d3f15b110a9e8c-03c7360d.pdf"
    },
    {
        "title": "Romige parelcouscous met heekfilet",
        "extra": "Met witlof en prei",
        "labels": [
            "Eet mij eerst",
            "Caloriebewust"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Vis",
            "Selderij",
            "Mosterd"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "715 kcal",
        "ingredients": [
            "Knoflookteen",
            "Prei",
            "Witlof",
            "Parelcouscous",
            "Kruidenroomkaas",
            "Heekfilet met huid",
            "Kerriepoeder"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-parelcouscous-met-heekfilet-2fd4d2cf.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-parelcouscous-met-heekfilet-59e9bb757901f95b0f536172-b9c11bd5.pdf"
    },
    {
        "title": "Peruaanse aardappelsoep met feta",
        "extra": "Met courgette, cashewnoten en komijn",
        "labels": [
            "Veggie",
            "Caloriebewust",
            "Chef's Choice"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Pinda's",
            "Noten",
            "Gluten",
            "Soja"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "766 kcal",
        "ingredients": [
            "Knoflookteen",
            "Ui",
            "Zoete aardappel",
            "Kruimige aardappelen",
            "Courgette",
            "Gemalen komijnzaad",
            "Paprikapoeder",
            "Verse koriander",
            "Feta",
            "Cashewnoten",
            "Witte ciabatta"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/peruaanse-aardappelsoep-met-feta-b8c881c5.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/peruaanse-aardappelsoep-met-feta-59f6ee24043c3c16c45ec362-3eff1952.pdf"
    },
    {
        "title": "Casarecce met kipgehakt in roomsaus",
        "extra": "Met spinazie en cherrytomaatjes",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "755 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Rode cherrytomaten",
            "Casarecce",
            "Kipgehakt met Italiaanse kruiden",
            "Paprikapoeder",
            "Kookroom",
            "Spinazie"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/caserecce-met-kipgehakt-in-roomsaus-56051d6a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/casarecce-met-kipgehakt-in-roomsaus-5a3143652c3e081768138fc2-16569ccb.pdf"
    },
    {
        "title": "Klassieke bloemkool met gehaktballen",
        "extra": "Met gekookte aardappelen en komkommer-dillesaus",
        "labels": [
            "Chef's Choice"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "682 kcal",
        "ingredients": [
            "Bloemkool",
            "Vastkokende aardappelen",
            "Verse dille",
            "Komkommer",
            "Magere yoghurt",
            "Rundergehaktballetjes met oosterse kruiden"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/klassieke-bloemkool-met-gehaktballen-0a6e60e2.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/klassieke-bloemkool-met-gehaktballen-5a708dac30006c7f995b6d32-228f780f.pdf"
    },
    {
        "title": "Volkoren spaghetti met cherrytomaten en gruy\u00e8re",
        "extra": "Met Turkse rode peper, munt en pompoenpitten",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "694 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Turkse rode peper",
            "Rode cherrytomaten",
            "Volkoren spaghetti",
            "Pompoenpitten",
            "Gedroogde oregano",
            "Citroen",
            "Verse munt",
            "Geraspte gruy\u00e8re"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/volkoren-spaghetti-met-cherrytomaten-en-gruyere-8fcb4994.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/volkoren-spaghetti-met-cherrytomaten-en-gruyere-5a7092b330006c053768d2f2-88f94d4b.pdf"
    },
    {
        "title": "Pittige risotto met ontbijtspek en prei",
        "extra": "Met rucola en citroen",
        "labels": ["Familie"],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "689 kcal",
        "ingredients": [
            "Knoflookteen",
            "Risottorijst",
            "Geraspte pecorino",
            "Prei",
            "Rode peper",
            "Ontbijtspek",
            "Citroen",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pittige-risotto-met-ontbijtspek-en-prei-bc8e49af.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pittige-risotto-met-ontbijtspek-en-prei-5a71ad4f30006c627d57dfb2-1b0d9aba.pdf"
    },
    {
        "title": "Platbroodpizza met groene pesto",
        "extra": "Met buffelmozzarella, champignons en rucola",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "762 kcal",
        "ingredients": [
            "Sjalot",
            "Champignons",
            "Wit platbrood",
            "Groene pesto",
            "Buffelmozzarella",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/platbroodpizza-met-groene-pesto-3fa32fcd.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/platbroodpizza-met-groene-pesto-5a71b2f9ae08b566dd46dfb2-50c813ee.pdf"
    },
    {
        "title": "Kogelbiefstuk met sjalot-tijmsaus",
        "extra": "Met aardappelgratin en haricots verts",
        "labels": ["Familie"],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Moeilijk",
        "kcal": "1034 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Sjalot",
            "Knoflookteen",
            "Slagroom",
            "Geraspte oude kaas",
            "Gedroogde tijm",
            "Haricots verts",
            "Kogelbiefstuk"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kogelbiefstuk-met-sjalot-tijmsaus-62baebe6.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kogelbiefstuk-met-sjalot-tijmsaus-5a71b662ae08b568f520c1f2-3b227609.pdf"
    },
    {
        "title": "Spaghetti bolognese met kipgehakt",
        "extra": "Met geraspte belegen kaas",
        "labels": [
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "709 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Groene paprika",
            "Pruimtomaat",
            "Kipgehakt met Italiaanse kruiden",
            "Tomatenpuree",
            "Volkoren spaghetti",
            "Geraspte belegen kaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/5943d9d72310a817f423489a-c0c91cc9.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/spaghetti-bolognese-met-kipgehakt-5943d9d72310a817f423489a-b147cef2.pdf"
    },
    {
        "title": "Risotto met mascarpone en gekruide varkensworst",
        "extra": "Met venkelsalade en grana padano",
        "labels": [
            "Chef's Choice"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Eieren"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "759 kcal",
        "ingredients": [
            "Ui",
            "Rode peper",
            "Venkel",
            "Varkensworst met citroen en tijm",
            "Citroen",
            "Venkelzaad",
            "Risottorijst",
            "Mascarpone",
            "Geraspte grana padano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/risotto-met-mascarpone-en-gekruide-varkensworst-8a4a3a96.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/risotto-met-mascarpone-en-gekruide-varkensworst-59b15e8451d3f103e16ff6e2-f34d9a91.pdf"
    },
    {
        "title": "Aziatische noedels met roerei en pinda's",
        "extra": "Met bosui en limoen",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Eieren",
            "Pinda's"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "733 kcal",
        "ingredients": [
            "Knoflookteen",
            "Sjalot",
            "Rode peper",
            "Bosui",
            "Limoen",
            "Verse gember",
            "Hollandse groentemix",
            "Sojasaus",
            "Ei",
            "Noedels",
            "Gezouten pinda's"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/aziatische-noedels-met-roerei-en-pinda-s-ae41311f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/aziatische-noedels-met-roerei-en-pinda-s-59ce30c230a03b3c83174212-a7a78c1d.pdf"
    },
    {
        "title": "Gebakken zeebaars met salieboter",
        "extra": "Met kruidige aardappelen en prei",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice",
            "Caloriebewust",
            "Familie"
        ],
        "allergens": [
            "Selderij",
            "Mosterd",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "611 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Kerriepoeder",
            "Prei",
            "Rode cherrytomaten",
            "Knoflookteen",
            "Verse salie",
            "Zeebaarsfilet met huid"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-zeebaars-met-salieboter-ddd58316.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-zeebaars-met-salieboter-59e069f12c9e863b9623dfb2-9cb2a228.pdf"
    },
    {
        "title": "Gele rijst met spinazie-kokoscurry",
        "extra": "Met tomaat en cashewnoten",
        "labels": [
            "Eet mij eerst",
            "Veggie",
            "Chef's Choice"
        ],
        "allergens": [
            "Pinda's",
            "Noten",
            "Selderij",
            "Mosterd"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "708 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Tomaat",
            "Gemalen kurkuma",
            "Pandanrijst",
            "Cashewnoten",
            "Kokosrasp",
            "Kerriepoeder",
            "Kokosmelk",
            "Spinazie"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/spinazie-curry-met-gele-rijst-659e8f44.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gele-rijst-met-spinazie-kokoscurry-59f04ea830a03b4b7753fa72-c2c2403d.pdf"
    },
    {
        "title": "Portobello met geitenkaas uit de oven",
        "extra": "Met walnoten, honing en verse rozemarijn",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "684 kcal",
        "ingredients": [
            "Roseval aardappelen",
            "Verse rozemarijn",
            "Rode ui",
            "Courgette",
            "Portobello",
            "Verse geitenkaas",
            "Walnoten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/portobello-met-geitenkaas-uit-de-oven-7ffadc62.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/portobello-met-geitenkaas-uit-de-oven-5a27c326a2882a7fd070f8b3-e3d06a7c.pdf"
    },
    {
        "title": "Parelcouscous met pompoen en feta",
        "extra": "Met kastanjechampignons, hazelnoten en dragon",
        "labels": [
            "Eet mij eerst",
            "Veggie",
            "Chef's Choice",
            "Caloriebewust"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "612 kcal",
        "ingredients": [
            "Ui",
            "Kastanjechampignons",
            "Pompoenblokjes",
            "Parelcouscous",
            "Verse dragon",
            "Feta",
            "Hazelnoten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscous-met-pompoen-en-feta-49261336.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscous-met-pompoen-en-feta-5a3120fb2c3e087f67002372-6cd72e44.pdf"
    },
    {
        "title": "Rijke broccoli met gebakken kabeljauw",
        "extra": "Met krieltjes, grana padano en pijnboompitten",
        "labels": [
            "Eet mij eerst",
            "Caloriebewust"
        ],
        "allergens": [
            "Vis",
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "572 kcal",
        "ingredients": [
            "Krieltjes",
            "Champignons",
            "Pijnboompitten",
            "Sjalot",
            "Citroen",
            "Kabeljauwfilet",
            "Geraspte grana padano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rijke-broccoli-met-gebakken-kabeljauw-ca42209e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rijke-broccoli-met-gebakken-kabeljauw-5a7b3f1630006c0fe1575a12-fede458c.pdf"
    },
    {
        "title": "Wraps met kruidige aubergine en tartaar",
        "extra": "Met feta, munt en yoghurt-tahinsaus",
        "labels": ["Familie"],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten",
            "Sesamzaad"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "823 kcal",
        "ingredients": [
            "Gemalen komijnzaad",
            "Gemalen kurkuma",
            "Aubergine",
            "Feta",
            "Verse munt",
            "Tomaat",
            "IJsbergsla",
            "Rundertartaar",
            "Witte tortilla",
            "Yoghurt-tahinsaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/wraps-met-kruidige-aubergine-en-tartaar-ce01fd6f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/wraps-met-kruidige-aubergine-en-tartaar-5a7b46b730006c16df5b7d51-d1fdd33e.pdf"
    },
    {
        "title": "Broodje omelet met pittige blauwe kaas",
        "extra": "Met gebakken portobello en coleslaw",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Sesamzaad",
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "885 kcal",
        "ingredients": [
            "Knoflookteen",
            "Verse bladpeterselie",
            "Portobello",
            "Rodekool en wortel",
            "Biologisch wit broodje met roggedesem",
            "Ei",
            "Danablu"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/broodje-omelet-met-pittige-blauwe-kaas-2fc8ed3d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/broodje-omelet-met-pittige-blauwe-kaas-5a7c1d79ae08b536ee7519a2-3cd52396.pdf"
    },
    {
        "title": "Casarecce met pesto, tonijn en spekjes",
        "extra": "Met paprika, courgette en pijnboompitten",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Vis",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "801 kcal",
        "ingredients": [
            "Casarecce",
            "Spekblokjes",
            "Groentemix van paprika, prei, gele wortel en courgette",
            "Pijnboompitten",
            "Tonijn in water",
            "Groene pesto"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/casarecce-met-pesto-tonijn-en-spekjes-7b80c9db.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/casarecce-met-pesto-tonijn-en-spekjes-5a7c2a01ae08b53ecc06ce02-a1cc9c0c.pdf"
    },
    {
        "title": "Pollo tonnato met zuurdesembrood",
        "extra": "Gepocheerde kip met tonijnsaus",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Sesamzaad",
            "Vis",
            "Eieren",
            "Mosterd",
            "Selderij",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Moeilijk",
        "kcal": "1251 kcal",
        "ingredients": [
            "Rode cherrytomaten",
            "Biologisch wit broodje met roggedesem",
            "Tonijn in water",
            "Kappertjes",
            "Mayonaise",
            "Vers basilicum",
            "Verse bladpeterselie",
            "Verse bieslook",
            "Kipfilet",
            "Gemengde sla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pollo-tonnato-met-zuurdesembrood-0fbe18d4.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pollo-tonnato-met-zuurdesembrood-5a7c3f00ae08b54e504241e2-3a452015.pdf"
    },
    {
        "title": "Italiaanse orzo met gekruid rundergehakt",
        "extra": "Met romige tomatensaus",
        "labels": [
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "830 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Orzo",
            "Tomatenpuree",
            "Rundergehakt",
            "Groentemix: Paprika, Prei, Chinese Kool, Witte Kool & Winterpeen",
            "Kruidenroomkaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/italiaanse-orzo-met-gekruid-rundergehakt-742b808d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/italiaanse-orzo-met-gekruid-rundergehakt-59217ab899052d5c7a4b636b-852ae892.pdf"
    },
    {
        "title": "Traditionele shakshuka met ei",
        "extra": "Met geitenkaas en speltbaguette",
        "labels": [
            "Caloriebewust",
            "Chef's Choice",
            "Veggie"
        ],
        "allergens": [
            "Eieren",
            "Melk (inclusief lactose)",
            "Gluten"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "601 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Rode peper",
            "Rode paprika",
            "Pruimtomaat",
            "Verse krulpeterselie",
            "Ei",
            "Verse geitenkaas",
            "Speltbaguette"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/traditionele-shakshuka-met-ei-7b8d58fd.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/traditionele-shakshuka-met-ei-59ba6a5dc94eac4ad518f852-f7aa6222.pdf"
    },
    {
        "title": "Lasagne met prei en courgette",
        "extra": "Met verse kruiden en grana padano",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Eieren",
            "Gluten"
        ],
        "duration": "55 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "808 kcal",
        "ingredients": [
            "Knoflookteen",
            "Prei",
            "Courgette",
            "Verse tijm",
            "Verse oregano",
            "Verse salie",
            "Kookroom",
            "Geraspte grana padano",
            "Lasagnebladen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/lasagne-met-prei-en-courgette-29da1c56.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/lasagne-met-prei-en-courgette-59d60011053468169f341e52-44d9c6d8.pdf"
    },
    {
        "title": "Kipgyros met gebakken aardappeltjes",
        "extra": "Met koolrabi en labne",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice",
            "Caloriebewust"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Eieren",
            "Sulfiet"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "650 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Koolrabi",
            "Pruimtomaat",
            "Kipgyros",
            "Gedroogde tijm",
            "Komkommer",
            "Labne"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kipgyros-met-gebakken-aardappeltjes-78fe59ac.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kipgyros-met-gebakken-aardappeltjes-59d73857a1e1ea19e654c8f2-f4ef2862.pdf"
    },
    {
        "title": "Tricolore pasta in tonijn-pestosaus",
        "extra": "Met cherrytomaten en gemengde sla",
        "labels": [
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Vis",
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "836 kcal",
        "ingredients": [
            "Ui",
            "Rode cherrytomaten",
            "Tonijn in olijfolie",
            "Conchiglie tricolore",
            "Mesclun",
            "Groene pesto"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/tricolore-pasta-in-tonijn-pestosaus-5ac60ca4.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/tricolore-pasta-in-tonijn-pestosaus-5a041c5751d3f15b6f471e12-930b8249.pdf"
    },
    {
        "title": "Parelcouscous met gekleurde peen",
        "extra": "Met geroosterde paprika, feta en amandelen",
        "labels": [
            "Caloriebewust",
            "Chef's Choice",
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Noten",
            "Gluten"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "798 kcal",
        "ingredients": [
            "Rode paprika",
            "Knoflookteen",
            "Verse krulpeterselie",
            "Feta",
            "Amandelen",
            "Karwijzaad",
            "Gekleurde peenmix",
            "Parelcouscous",
            "Magere yoghurt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscous-met-gekleurde-peen-5a409450.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscous-met-gekleurde-peen-5a84375430006c34cd1fbf82-297d1ed8.pdf"
    },
    {
        "title": "Koolsteaks met wijtingfilet",
        "extra": "Met aardappelpuree en spekjes",
        "labels": ["Familie"],
        "allergens": [
            "Melk (inclusief lactose)",
            "Vis"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "737 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Knoflookteen",
            "Verse tijm",
            "Geraspte parmigiano reggiano",
            "Witte kool",
            "Spekblokjes",
            "Wijtingfilet met huid"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/koolsteaks-met-wijtingfilet-f8a5b527.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/koolsteaks-met-wijtingfilet-5a843bc430006c37b072e8f2-b39df174.pdf"
    },
    {
        "title": "Wortel-pastinaaksoep",
        "extra": "Met amandel-korianderolie en een rozenbroodje",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Tarwe",
            "Gerst",
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "788 kcal",
        "ingredients": [
            "Ui",
            "Vastkokende aardappelen",
            "Pastinaak",
            "Wortel",
            "Gemalen kurkuma",
            "Biologisch bruin rozenbroodje",
            "Amandelen",
            "Verse koriander"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/wortel-pastinaaksoep-40630b59.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/wortel-pastinaaksoep-5a844178ae08b53f2114e2e1-4690a19d.pdf"
    },
    {
        "title": "Zoete varkensfiletreepjes met parelcouscous",
        "extra": "Met abrikozen, paprika en pittige harissa",
        "labels": ["Familie"],
        "allergens": [
            "Sulfiet",
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Makkelijk",
        "kcal": "742 kcal",
        "ingredients": [
            "Rode ui",
            "Rode paprika",
            "Courgette",
            "Gedroogde abrikozen",
            "Varkensfilet",
            "Harissa",
            "Gemalen kaneel",
            "Passata",
            "Parelcouscous"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/zoete-varkensfiletreepjes-met-couscous-50371f2d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/zoete-varkensfiletreepjes-met-couscous-5a8448f230006c418c31f5c1-ddc11f0a.pdf"
    },
    {
        "title": "Fusilli met mais, courgette en feta",
        "extra": "Met limoen en verse kruiden",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Selderij",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "781 kcal",
        "ingredients": [
            "Fusilli",
            "Mais",
            "Courgette",
            "Verse krulpeterselie",
            "Vers basilicum",
            "Limoen",
            "Feta"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/fusilli-met-mais-courgette-en-feta-805c40f6.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/fusilli-met-mais-courgette-en-feta-5a85505330006c01430201d2-0fdc5ed9.pdf"
    },
    {
        "title": "Noedels met 5-kruidenkip",
        "extra": "Met roerbakgroenten en rode peper",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Soja"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "653 kcal",
        "ingredients": [
            "Rode peper",
            "Knoflookteen",
            "Kippendijreepjes met vijfkruiden",
            "Groentemix met peultjes",
            "Sojasaus",
            "Noedels"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/noedels-met-5-kruidenkip-af06476b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/noedels-met-5-kruidenkip-5a855830ae08b509167809c1-ca548b4f.pdf"
    },
    {
        "title": "Romige knolselderijpuree met bietjes",
        "extra": "Met paddenstoelen en zilveruitjessaus",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Selderij",
            "Noten",
            "Melk (inclusief lactose)",
            "Sulfiet"
        ],
        "duration": "50 minuten",
        "difficulty": "Moeilijk",
        "kcal": "879 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Knolselderij",
            "Hazelnoten",
            "Handsinaasappel",
            "Gekookte rode bieten",
            "Slagroom",
            "Zilveruitjes",
            "Steranijs",
            "Laurierblad",
            "Sjalot",
            "Knoflookteen",
            "Verse tijm",
            "Gemengde gesneden paddenstoelen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-knolselderijpuree-met-bietjes-3e2449da.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-knolselderijpuree-met-bietjes-5a85678c30006c11916ccde2-a038aafa.pdf"
    },
    {
        "title": "Schelvisfilet met tomatentapenade",
        "extra": "Met ratatouille en rijst",
        "labels": [
            "Caloriebewust",
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Sulfiet",
            "Vis"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "679 kcal",
        "ingredients": [
            "Basmatirijst",
            "Ui",
            "Knoflookteen",
            "Rode peper",
            "Pruimtomaat",
            "Aubergine",
            "Paprikapoeder",
            "Tomatentapenade",
            "Schelvisfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/schelvisfilet-met-tomatentapenade-215b8270.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/schelvisfilet-met-tomatentapenade-5a8e9246ae08b54b30235532-92c9b94d.pdf"
    },
    {
        "title": "Andijviestamppot met Brandt&Levie-worst",
        "extra": "Met kruidenkaas en cherrytomaatjes",
        "labels": [
            "Chef's Choice"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "773 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Rode ui",
            "Rode cherrytomaten",
            "Varkensworst met peterselie en knoflook",
            "Gesneden andijvie",
            "Kruidenkaasblokjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/andijviestamppot-met-brandt-levie-worst-c0d1ab32.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/andijviestamppot-met-brandt-levie-worst-5a8e98da30006c533d7255a2-6a60f47a.pdf"
    },
    {
        "title": "Orzo met verse oreganopesto",
        "extra": "Met champignons en rode paprika",
        "labels": [
            "Chef's Choice",
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "908 kcal",
        "ingredients": [
            "Pijnboompitten",
            "Sjalot",
            "Knoflookteen",
            "Rode paprika",
            "Champignons",
            "Orzo",
            "Verse oregano",
            "Geraspte parmigiano reggiano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/orzo-met-verse-oreganopesto-7cae276c.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/orzo-met-verse-oreganopesto-5a8e9e3a30006c56f5291a42-710d7a70.pdf"
    },
    {
        "title": "Verse makreel met knapperige aardappelpuree",
        "extra": "Met kappertjes, sjalot en gepofte tomaat",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Vis"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "956 kcal",
        "ingredients": [
            "Kruimige aardappelen",
            "Sjalot",
            "Kappertjes",
            "Tomaat",
            "Biologische cr\u00e8me fra\u00eeche",
            "Verse makreelfilet met huid"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/verse-makreel-met-knapperige-aardappelpuree-18adbd8b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/verse-makreel-met-knapperige-aardappelpuree-5a8ea382ae08b556fe5084e2-f1d938b9.pdf"
    },
    {
        "title": "Snelle mujadara met linzen en rijst",
        "extra": "Met spiegelei en yoghurt-tahinsaus",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Eieren",
            "Melk (inclusief lactose)",
            "Sesamzaad"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "702 kcal",
        "ingredients": [
            "Basmatirijst",
            "Sjalot",
            "Rode paprika",
            "Linzen",
            "Gemalen komijnzaad",
            "Gemalen korianderzaad",
            "Citroen",
            "Verse koriander",
            "Ei",
            "Yoghurt-tahinsaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/snelle-mujadara-met-linzen-en-rijst-bfe053ea.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/snelle-mujadara-met-linzen-en-rijst-5a8eafd030006c62ef2c62e2-9088c6e5.pdf"
    },
    {
        "title": "Kabeljauwfilet met bieslookmayonaise",
        "extra": "Met knoflookkrieltjes en broccoli",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis",
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "818 kcal",
        "ingredients": [
            "Krieltjes",
            "Champignons",
            "Knoflookteen",
            "Verse bieslook",
            "Kabeljauwfilet",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kabeljauwfilet-met-bieslookmayonaise-80ec87d3.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kabeljauwfilet-met-bieslookmayonaise-5a8eb44430006c664168bbc2-9b13de5a.pdf"
    },
    {
        "title": "Kokos-noedelssoep met kippendij",
        "extra": "Met vers citroengras en basilicum",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Chef's Choice"
        ],
        "allergens": [
            "Gluten",
            "Selderij",
            "Vis"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "626 kcal",
        "ingredients": [
            "Verse gember",
            "Rode peper",
            "Wortel",
            "Kippendijreepjes",
            "Kokosmelk",
            "Gemalen citroengras",
            "Snijbonen",
            "Verse udonnoedels",
            "Vers basilicum",
            "Limoen",
            "Vissaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kokos-noedelssoep-met-kippendij-2e13172d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kokos-noedelssoep-met-kippendij-5a8eb51230006c66ba475452-0704094e.pdf"
    },
    {
        "title": "Portobello-burger met gebakken ei",
        "extra": "Met gebakken aardappelen en geroosterde courgette",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten",
            "Sesamzaad",
            "Lupine",
            "Eieren",
            "Mosterd"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "970 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Gedroogde rozemarijn",
            "Gedroogde tijm",
            "Rode ui",
            "Courgette",
            "Portobello",
            "Geraspte belegen kaas",
            "Hamburgerbol",
            "Ei",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/portobello-burger-met-gebakken-ei-574b1a29.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/portobello-burger-met-gebakken-ei-5a8ebc6cae08b5689531de32-af9410f3.pdf"
    },
    {
        "title": "Salade met buffelmozzarella en serranoham",
        "extra": "Met geschaafde venkel en geroosterde druiven",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten",
            "Noten",
            "Eieren",
            "Lupine",
            "Mosterd",
            "Sesamzaad",
            "Soja"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "1128 kcal",
        "ingredients": [
            "Venkel",
            "Verse munt",
            "Buffelmozzarella",
            "Blauwe druiven, pitloos",
            "Baguette",
            "Walnoten",
            "Kappertjes",
            "Gemengde sla",
            "Serranoham"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/salade-met-buffelmozzarella-en-serranoham-043f45da.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/salade-met-buffelmozzarella-en-serranoham-5a8ebec930006c6d5e051282-3267d485.pdf"
    },
    {
        "title": "Parelgerst met geroosterde groenten",
        "extra": "Met feta en amandelen",
        "labels": [
            "Caloriebewust",
            "Chef's Choice",
            "Veggie"
        ],
        "allergens": [
            "Noten",
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "744 kcal",
        "ingredients": [
            "Courgette",
            "Rode cherrytomaten",
            "Verse tijm",
            "Bosui",
            "Amandelen",
            "Parelgerst",
            "Feta"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelgerst-met-groosterde-groenten-be10e7ed.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelgerst-met-groosterde-groenten-5a8ec54230006c72261b6312-33fe4e9b.pdf"
    },
    {
        "title": "Pulled chicken met koolsla",
        "extra": "Op een broodje met zoete rode ui en augurk",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Eieren",
            "Mosterd",
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "743 kcal",
        "ingredients": [
            "Rode ui",
            "Kippendijfilet",
            "Gerookt paprikapoeder",
            "Mayonaise",
            "Groentemix",
            "Augurken",
            "Kurkumabroodje"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pulled-chicken-met-koolsla-f70bbd25.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pulled-chicken-met-koolsla-5a8ed80230006c01be7bc142-5c97d0ab.pdf"
    },
    {
        "title": "Klassieke Indiase curry met yoghurt",
        "extra": "Met linzen, bataat en paksoi",
        "labels": [
            "Eet mij eerst",
            "Veggie"
        ],
        "allergens": [
            "Selderij",
            "Mosterd",
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "864 kcal",
        "ingredients": [
            "Knoflookteen",
            "Rode peper",
            "Kokosmelk",
            "Kerriepoeder",
            "Gemalen kurkuma",
            "Groene linzen",
            "Zoete aardappel",
            "Volkoren pitabroodje",
            "Paksoi",
            "Sojasaus",
            "Verse koriander",
            "Magere yoghurt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/klassieke-indiase-curry-met-yoghurt-53baf209.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/klassieke-indiase-curry-met-yoghurt-5a8edb2630006c043d490942-3b93fbfe.pdf"
    },
    {
        "title": "Orzo-risotto met kabeljauw",
        "extra": "Met spekjes, citroen en tijm",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "736 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Venkel",
            "Courgette",
            "Spekblokjes",
            "Orzo",
            "Citroen",
            "Kabeljauwfilet met huid",
            "Gedroogde tijm"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/orzo-risotto-met-kabeljauw-5aa6da7d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/orzo-risotto-met-kabeljauw-5a9544a6ae08b57814769772-141e20a1.pdf"
    },
    {
        "title": "Platbroodpizza met kipgyros",
        "extra": "Met rode kool en paprika",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Makkelijk",
        "kcal": "807 kcal",
        "ingredients": [
            "Rode paprika",
            "Rode ui",
            "Kipgyros",
            "Tomatenpuree",
            "Gedroogde tijm",
            "Wit platbrood",
            "Geraspte oude kaas",
            "Gesneden rodekool"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/platbroodpizza-met-kipgyros-b4769338.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/platbroodpizza-met-kipgyros-5a954dc3ae08b57ec238fd82-f9ef2380.pdf"
    },
    {
        "title": "Conchiglie met rode pesto en feta",
        "extra": "Met mini-romatomaatjes, rucola en basilicum",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Selderij",
            "Noten"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "806 kcal",
        "ingredients": [
            "Sjalot",
            "Mini-romatomaten",
            "Conchiglie tricolore",
            "Feta",
            "Vers basilicum",
            "Rode pesto",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/conchiglie-met-rode-pesto-en-feta-650d8856.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/conchiglie-met-rode-pesto-en-feta-5a955283ae08b503872468e2-96171b93.pdf"
    },
    {
        "title": "Gebakken zeebaars met pittige bataat",
        "extra": "Met geroosterde bloemkool en komijn",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "788 kcal",
        "ingredients": [
            "Zoete aardappel",
            "Rode peper",
            "Knoflookteen",
            "Bloemkool",
            "Gemalen komijnzaad",
            "Citroen",
            "Zeebaarsfilet met huid"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-zeebaars-met-pittige-bataat-21190758.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-zeebaars-met-pittige-bataat-5a95587b30006c09d750f681-02cba78e.pdf"
    },
    {
        "title": "Burger met pittige groene peper en piccalilly",
        "extra": "Met aardappelpartjes en salade",
        "labels": [
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Mosterd"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "826 kcal",
        "ingredients": [
            "Gedroogde tijm",
            "Tomaat",
            "Komkommer",
            "Groene peper",
            "Gemengde sla",
            "Witte ciabatta",
            "Gekruide runderburger",
            "Piccalilly",
            "Aardappelpartjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/burger-met-pittige-groene-peper-en-piccalilly-ea962aa8.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/burger-met-pittige-groene-peper-en-piccalilly-5a9566e7ae08b512e6215f52-d99b4630.pdf"
    },
    {
        "title": "Orzo met mini-romatomaten uit de oven",
        "extra": "Met courgette, gruy\u00e8re en citroen",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "801 kcal",
        "ingredients": [
            "Mini-romatomaten",
            "Sjalot",
            "Knoflookteen",
            "Courgette",
            "Citroen",
            "Orzo",
            "Gedroogde oregano",
            "Geraspte gruy\u00e8re"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/orzo-met-mini-romatomaten-uit-de-oven-625fb6be.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/orzo-met-mini-romatomaten-uit-de-oven-5a956ed2ae08b5196d7bb012-04e3a0c6.pdf"
    },
    {
        "title": "Pikante harissacouscous met feta",
        "extra": "Met aubergine, tomaat en olijven",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "664 kcal",
        "ingredients": [
            "Ui",
            "Aubergine",
            "Tomaat",
            "Gedroogde tijm",
            "Leccino olijven",
            "Tomatenpuree",
            "Harissa",
            "Volkoren couscous",
            "Feta",
            "Veldsla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pikante-harissacouscous-met-feta-68d2547f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pikante-harissacouscous-met-feta-5a957805ae08b52072409c42-67e7cd3f.pdf"
    },
    {
        "title": "Pompoen-pindasoep met ei",
        "extra": "Met peen en Libanees platbrood",
        "labels": [
            "Eet mij eerst",
            "Veggie"
        ],
        "allergens": [
            "Selderij",
            "Mosterd",
            "Eieren",
            "Gluten",
            "Pinda's"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "823 kcal",
        "ingredients": [
            "Knoflookteen",
            "Wortel",
            "Bosui",
            "Pompoenblokjes",
            "Kerriepoeder",
            "Ei",
            "Wit platbrood",
            "Pindakaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pompoen-pindasoep-met-ei-a4d6c1d6.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pompoen-pindasoep-met-ei-5a957e15ae08b5258b06bce2-d48180fe.pdf"
    },
    {
        "title": "Knolselderijstoemp met serranoham",
        "extra": "Met kruidenroomkaas en bieslook",
        "labels": [
            "Caloriebewust"
        ],
        "allergens": [
            "Selderij",
            "Eieren",
            "Melk (inclusief lactose)",
            "Lupine",
            "Mosterd",
            "Sesamzaad",
            "Soja"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "657 kcal",
        "ingredients": [
            "Knolselderij",
            "Vastkokende aardappelen",
            "Serranoham",
            "Verse bieslook",
            "Kruidenroomkaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/knolselderijstoemp-met-serranoham-1a15bb7f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/knolselderijstoemp-met-serranoham-5a9584fcae08b52ba904c362-f287657f.pdf"
    },
    {
        "title": "Nasi goreng met spiegelei",
        "extra": "Met limoen, pinda's en koriander",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Eieren",
            "Gluten",
            "Soja",
            "Pinda's"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "796 kcal",
        "ingredients": [
            "Basmatirijst",
            "Limoen",
            "Bosui",
            "Verse koriander",
            "Gemalen korianderzaad",
            "Gemalen kurkuma",
            "Ei",
            "Sojasaus",
            "Gezouten pinda's",
            "Groentemix"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/nasi-goreng-met-spiegelei-7cbaa154.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/nasi-goreng-met-spiegelei-5a967db1ae08b5728d399092-3fa345d1.pdf"
    },
    {
        "title": "Warme aardappelsalade met gerookte forel",
        "extra": "Met rode paprika, citroen en dille",
        "labels": [
            "Caloriebewust"
        ],
        "allergens": [
            "Vis"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "576 kcal",
        "ingredients": [
            "Krieltjes",
            "Rode paprika",
            "Rode ui",
            "Knoflookteen",
            "Mini-romaine sla",
            "Citroen",
            "Verse dille",
            "Gerookte forelfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/warme-aardappelsalade-met-gerookte-forel-22511ee3.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/warme-aardappelsalade-met-gerookte-forel-5a968476ae08b5775849b4a2-afe0aece.pdf"
    },
    {
        "title": "Kip met notenkorst en salade caprese",
        "extra": "Met fettucine, kappertjes en olijven",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Noten",
            "Gluten",
            "Selderij",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Moeilijk",
        "kcal": "1105 kcal",
        "ingredients": [
            "Walnoten",
            "Hazelnoten",
            "Knoflookteen",
            "Kipfilet",
            "Fettuccine",
            "Tomaat",
            "Vers basilicum",
            "Olijf-kappertjesmix",
            "Buffelmozzarella"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kip-met-notenkorst-en-salade-caprese-0ff33f5e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kip-met-notenkorst-en-salade-caprese-5a9688a330006c7b0b0aac32-fbb6eb7b.pdf"
    },
    {
        "title": "Kokossoep met schelvis en udonnoedels",
        "extra": "Met snijbonen, paksoi en sojasaus",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Vis"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "568 kcal",
        "ingredients": [
            "Knoflookteen",
            "Snijbonen",
            "Paksoi",
            "Kokosmelk",
            "Gemalen citroengras",
            "Sojasaus",
            "Schelvisfilet",
            "Verse udonnoedels"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kokossoep-met-schelvis-en-udonnoedels-b10debdf.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kokossoep-met-schelvis-en-udonnoedels-5a9fa559ae08b565fb337722-10fa96d9.pdf"
    },
    {
        "title": "Kruidige k\u00f6fte in zoet-pittige saus",
        "extra": "Met rijst en krulpeterselie",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Soja",
            "Vis"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "809 kcal",
        "ingredients": [
            "Sjalot",
            "Turkse rode peper",
            "Groene paprika",
            "Tomaat",
            "Gekruid rundergehakt",
            "Zilvervliesrijst",
            "Gemalen kaneel",
            "Gemalen komijnzaad",
            "Tomatenpuree",
            "Rozijnen",
            "Verse krulpeterselie"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kruidige-kofte-in-zoet-pittige-saus-a3a32799.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kruidige-kofte-in-zoet-pittige-saus-5a9fad8230006c6d8543c9f2-d7846f6a.pdf"
    },
    {
        "title": "Romige spinaziegratin met spiegelei",
        "extra": "Met gebakken aardappeltjes",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Eieren"
        ],
        "duration": "45 minuten",
        "difficulty": "Makkelijk",
        "kcal": "819 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Sjalot",
            "Knoflookteen",
            "Tomaat",
            "Nootmuskaat",
            "Gedroogde tijm",
            "Spinazie",
            "Biologische cr\u00e8me fra\u00eeche",
            "Geraspte belegen kaas",
            "Ei"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-spinaziegratin-met-spiegelei-d9409447.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-spinaziegratin-met-spiegelei-5a9fb74430006c73fd114142-0a174c19.pdf"
    },
    {
        "title": "Gepocheerde kabeljauw met krieltjes",
        "extra": "Met salie-hazelnootboter, courgette en tomatensalade",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Noten",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Moeilijk",
        "kcal": "700 kcal",
        "ingredients": [
            "Krieltjes",
            "Verse citroentijm",
            "Courgette",
            "Verse salie",
            "Hazelnoten",
            "Kabeljauwfilet",
            "Rode cherrytomaten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gepocheerde-kabeljauw-met-krieltjes-f644ab75.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gepocheerde-kabeljauw-met-krieltjes-5a9fc5d0ae08b57c277f7422-a3694e7b.pdf"
    },
    {
        "title": "Rigatoni met kippendijstukjes en pesto",
        "extra": "Met sperziebonen",
        "labels": [
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "846 kcal",
        "ingredients": [
            "Sperziebonen",
            "Rigatoni",
            "Knoflookteen",
            "Kippendijreepjes",
            "Groene pesto"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rigatoni-met-kippendijstukjes-en-pesto-9adde62f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rigatoni-met-kippendijstukjes-en-pesto-5a9fdde530006c10b45ab7e2-2607406a.pdf"
    },
    {
        "title": "Zoete paprika-bataatsoep",
        "extra": "Met cr\u00e8me fra\u00eeche en een kurkumabroodje",
        "labels": [
            "Caloriebewust",
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "688 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Zoete aardappel",
            "Rode paprika",
            "Kurkumabroodje",
            "Pompoenpitten",
            "Biologische cr\u00e8me fra\u00eeche"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/zoete-paprika-bataatsoep-4e8ed9b3.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/zoete-paprika-bataatsoep-5a9fe18c30006c1350578312-a9998068.pdf"
    },
    {
        "title": "Spaghetti aglio olio met venkel",
        "extra": "Met grana padano, basilicum en pijnboompitten",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Selderij",
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "761 kcal",
        "ingredients": [
            "Knoflookteen",
            "Venkel",
            "Rode cherrytomaten",
            "Pijnboompitten",
            "Volkoren spaghetti",
            "Sjalot",
            "Vers basilicum",
            "Gedroogde oregano",
            "Geraspte grana padano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/spaghetti-aglio-olio-met-venkel-21a195ca.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/spaghetti-aglio-olio-met-venkel-5a9fe866ae08b51873145881-6139e494.pdf"
    },
    {
        "title": "Warme quinoasalade met gele biet",
        "extra": "Met feta en en avocado",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "815 kcal",
        "ingredients": [
            "Gele biet",
            "Rode ui",
            "Quinoa",
            "Gemalen komijnzaad",
            "Paprikapoeder",
            "Avocado",
            "Feta",
            "Gemengde sla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/warme-quinoasalade-met-gele-biet-bca5531a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/warme-quinoasalade-met-gele-biet-5a9fec83ae08b51bca58d222-fefbc2c7.pdf"
    },
    {
        "title": "Kip korma met broccoli",
        "extra": "Met zilvervliesrijst, koriander en amandelen",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Amandelnoten",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "1001 kcal",
        "ingredients": [
            "Knoflookteen",
            "Verse gember",
            "Verse kurkuma",
            "Kippendijreepjes",
            "Zilvervliesrijst",
            "Amandelschaafsel",
            "Ui",
            "Champignons",
            "Verse koriander",
            "Limoen",
            "Gemalen kaneel",
            "Gemalen kardemom",
            "Kokosmelk"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kip-korma-met-broccoli-2a22ae6b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kip-korma-met-broccoli-5aa10068ae08b574db6bca32-3c530753.pdf"
    },
    {
        "title": "Romige bospaddenstoelen-couscous",
        "extra": "Met pecorino, cashewnoten en peterselie",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Pinda's",
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "805 kcal",
        "ingredients": [
            "Volkoren couscous",
            "Verse bladpeterselie",
            "Prei",
            "Kastanjechampignons",
            "Cashewnoten",
            "Bospaddenstoelenpesto",
            "Geraspte pecorino"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-bospaddenstoelen-couscous-7c521f8f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-bospaddenstoelen-couscous-5aa104deae08b579574e4dd2-eca736b7.pdf"
    },
    {
        "title": "Pittig broodje gerookte makreel",
        "extra": "Met zoetzure komkommersalade",
        "labels": ["Familie"],
        "allergens": [
            "Vis",
            "Gluten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "768 kcal",
        "ingredients": [
            "Komkommer",
            "Rode ui",
            "Mini-romaine sla",
            "Gerookte makreel",
            "Harissa",
            "Kleine baguette"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pittig-broodje-gerookte-makreel-11295ecc.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pittig-broodje-gerookte-makreel-5aa109a830006c7acb5d1c42-776a3122.pdf"
    },
    {
        "title": "Aardappelkoekjes met warmgerookte zalm",
        "extra": "Met zure room, dille en koolrabi-appelsalade",
        "labels": ["Familie"],
        "allergens": [
            "Vis",
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "1045 kcal",
        "ingredients": [
            "Koolrabi",
            "Appel",
            "Radijs",
            "Citroen",
            "Verse dille",
            "Verse bieslook",
            "Warmgerookte zalm",
            "Vastkokende aardappelen",
            "Sjalot",
            "Ei",
            "Biologische zure room"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/aardappelkoekjes-met-warmgerookte-zalm-e064ff5b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/aardappelkoekjes-met-warmgerookte-zalm-5aa1109c30006c7f56473cf2-6a746d0d.pdf"
    },
    {
        "title": "Gebakken kabeljauwfilet met botersaus",
        "extra": "Met zoete parelcouscous, peen en tomaat",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "682 kcal",
        "ingredients": [
            "Wortel",
            "Rode cherrytomaten",
            "Verse bladpeterselie",
            "Tuinkers",
            "Parelcouscous",
            "Rozijnen",
            "Kabeljauwfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-kabeljauwfilet-met-botersaus-bb2bfad8.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-kabeljauwfilet-met-botersaus-5aa930ccae08b521eb381152-b2829adf.pdf"
    },
    {
        "title": "Ovenkrieltjes met kip en groene pesto",
        "extra": "Met sperziebonen",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "35 minuten",
        "difficulty": "Makkelijk",
        "kcal": "793 kcal",
        "ingredients": [
            "Krieltjes",
            "Sperziebonen",
            "Groene pesto",
            "Kippendijreepjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/ovenkrieltjes-met-kip-en-groene-pesto-cb0ea6e6.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/ovenkrieltjes-met-kip-en-groene-pesto-5aa934d4ae08b5258729d6b2-353eadfa.pdf"
    },
    {
        "title": "Risotto met courgette en buffelmozzarella",
        "extra": "Met bleekselderij en basilicum",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Selderij",
            "Melk (inclusief lactose)",
            "Eieren"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "771 kcal",
        "ingredients": [
            "Rode ui",
            "Knoflookteen",
            "Bleekselderij",
            "Rode peper",
            "Vers basilicum",
            "Courgette",
            "Risottorijst",
            "Buffelmozzarella",
            "Geraspte grana padano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/risotto-met-cou-rgette-en-buffelmozzarella-ab25304a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/risotto-met-cou-rgette-en-buffelmozzarella-5aa93903ae08b52875752222-a9efd5ad.pdf"
    },
    {
        "title": "Schelvisfilet met linzen en spekjes",
        "extra": "Met peen en prei",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis"
        ],
        "duration": "35 minuten",
        "difficulty": "Makkelijk",
        "kcal": "543 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Wortel",
            "Prei",
            "Spekblokjes",
            "Linzen",
            "Schelvisfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/schelvis-met-linzen-en-spekjes-a3df034e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/schelvis-met-linzen-en-spekjes-5aa93eeb30006c2e75091682-e9429924.pdf"
    },
    {
        "title": "Tabouleh met varkenshaasreepjes",
        "extra": "Met tomaat en verse kruiden",
        "labels": [
            "Familie"
        ],
        "allergens": [
            "Gluten"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "758 kcal",
        "ingredients": [
            "Rode ui",
            "Volkoren bulgur",
            "Tomaat",
            "Verse koriander",
            "Verse krulpeterselie",
            "Verse munt",
            "Varkenshaasreepjes met gyroskruiden",
            "Rucolamelange"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/tabouleh-met-varkenshaasreepjes-0010f268.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/tabouleh-met-varkenshaasreepjes-5aa9453330006c33f6125372-66ea68c5.pdf"
    },
    {
        "title": "Spinazie-bataatcurry met spiegelei en yoghurt",
        "extra": "Met rode peper en verse koriander",
        "labels": [
            "Caloriebewust",
            "Familie"
        ],
        "allergens": [
            "Selderij",
            "Mosterd",
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "648 kcal",
        "ingredients": [
            "Basmatirijst",
            "Ui",
            "Knoflookteen",
            "Rode peper",
            "Zoete aardappel",
            "Kokosmelk",
            "Kerriepoeder",
            "Verse koriander",
            "Ei",
            "Spinazie",
            "Naturel yoghurt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/spinazie-bataatcurry-met-spiegelei-en-yoghurt-c2366a60.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/spinazie-bataatcurry-met-spiegelei-en-yoghurt-5aaa412030006c70ff25cef2-7c8db135.pdf"
    },
    {
        "title": "Pompoen-kikkererwtensoep met komijn",
        "extra": "Met zure room en speltbaguette",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Sulfiet"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "592 kcal",
        "ingredients": [
            "Ui",
            "Rode peper",
            "Kabocha pompoen",
            "Kikkererwten",
            "Gemalen komijnzaad",
            "Speltbaguette",
            "Verse munt",
            "Biologische zure room"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pompoen-kikkererwtensoep-met-komijn-ff94697c.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pompoen-kikkererwtensoep-met-komijn-5aaa4d79ae08b57b99106491-c258b8c0.pdf"
    },
    {
        "title": "Parelcouscous met gebakken sjalotjes",
        "extra": "Met kastanjechampignons en citroenricotta",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "672 kcal",
        "ingredients": [
            "Sjalot",
            "Kastanjechampignons",
            "Citroen",
            "Verse kervel",
            "Verse dragon",
            "Parelcouscous",
            "Ricotta",
            "Geraspte pecorino"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscous-met-gebakken-sjalotjes-b2d5d910.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscous-met-gebakken-sjalotjes-5aaa5e46ae08b507b23059f1-32fe3ea8.pdf"
    },
    {
        "title": "Groentestoofpotje met rundermerguez",
        "extra": "Met gebakken aardappeltjes en rozijnen",
        "labels": ["Familie"],
        "allergens": [
            "Soja",
            "Mosterd",
            "Selderij"],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "868 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Paprikapoeder",
            "Rundermerguez",
            "Courgette",
            "Rode paprika",
            "Wortel",
            "Gemalen komijnzaad",
            "Gemalen kaneel",
            "Rozijnen",
            "Verse krulpeterselie"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/groentestoofpotje-met-rundermerguez-8d54c846.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/groentestoofpotje-met-rundermerguez-5aaa69aaae08b50f850ec3c2-325cf383.pdf"
    },
    {
        "title": "Gebakken gnocchi met groene pesto",
        "extra": "Met courgettelinten, rucola en cherrytomaten",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Eieren",
            "Sulfiet",
            "Tarwe",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "835 kcal",
        "ingredients": [
            "Courgette",
            "Rode cherrytomaten",
            "Gnocchi",
            "Groene pesto",
            "Rucola",
            "Provolonevlokken"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-gnocchi-met-groene-pesto-793e1883.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-gnocchi-met-groene-pesto-5aaa874830006c25db4d3341-08deb0cd.pdf"
    },
    {
        "title": "Garnalenburger met labne",
        "extra": "Met broccoli-rucolasalade en amandelen",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Sesamzaad",
            "Lupine",
            "Noten",
            "Eieren",
            "Sulfiet",
            "Schaaldieren",
            "Vis",
            "Soja",
            "Weekdieren"
        ],
        "duration": "20 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "714 kcal",
        "ingredients": [
            "Champignons",
            "Sjalot",
            "Hamburgerbol",
            "Amandelen",
            "Rucola",
            "Labne",
            "Garnalenburger"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/garnalenburger-met-labne-15db3322.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/garnalenburger-met-labne-5aaa9182ae08b52c5526d532-5007328f.pdf"
    },
    {
        "title": "Garnalen in verse tomatensaus",
        "extra": "Met romige aardappelpuree en haricots verts",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Schaaldieren",
            "Melk (inclusief lactose)",
            "Vis"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "788 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Haricots verts",
            "Sjalot",
            "Mini-romatomaten",
            "Verse bladpeterselie",
            "Citroen",
            "Garnalen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/garnalen-in-verse-tomatensaus-460d614c.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/garnalen-in-verse-tomatensaus-5aaa9e3bae08b536762be4d2-1eca79a0.pdf"
    },
    {
        "title": "Parelcouscous met garnalen en courgette",
        "extra": "Met verse tijm, rode peper en citroen",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Schaaldieren"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "2456 kJ",
        "ingredients": [
            "Rode ui",
            "Knoflookteen",
            "Rode peper",
            "Citroen",
            "Verse tijm",
            "Courgette",
            "Parelcouscous",
            "Garnalen",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscous-met-garnalen-en-courgette-f11af025.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscous-met-garnalen-en-courgette-5ab22b7aae08b57a922c7f52-ebe0bb74.pdf"
    },
    {
        "title": "Shakshuka met rundertartaar",
        "extra": "Met ei en aubergine",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Eieren"
        ],
        "duration": "50 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "662 kcal",
        "ingredients": [
            "Aubergine",
            "Ui",
            "Knoflookteen",
            "Rode peper",
            "Rode paprika",
            "Tomaat",
            "Ciabatta met zonnebloempitten",
            "Ei",
            "Verse krulpeterselie",
            "Rundertartaar",
            "Gemalen komijnzaad",
            "Paprikapoeder"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/shakshuka-met-rundertartaar-7060e6f8.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/shakshuka-met-rundertartaar-5ab23022ae08b57e201918a2-2bd70365.pdf"
    },
    {
        "title": "Bataatstamppot met oude kaas",
        "extra": "Met veldsla, rucola en pecannoten",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "646 kcal",
        "ingredients": [
            "Pecannoten",
            "Zoete aardappel",
            "Vastkokende aardappelen",
            "Rode ui",
            "Tomaat",
            "Rucolamelange",
            "Oude-kaasblokjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/bataatstamppot-met-oude-kaas-02508479.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/bataatstamppot-met-oude-kaas-5ab2365530006c021f5cea42-2f6f41a3.pdf"
    },
    {
        "title": "Schelvis met zelfgemaakte kruidenroomkaas",
        "extra": "Met geroerbakte komkommer, aardappels en cherrytomaten",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "547 kcal",
        "ingredients": [
            "Drieling aardappelen",
            "Schelvisfilet",
            "Komkommer",
            "Rode cherrytomaten",
            "Knoflookteen",
            "Verse dille",
            "Verse bieslook",
            "Roomkaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/schelvis-met-zelfgemaakte-kruidenroomkaas-c4b90f33.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/schelvis-met-zelfgemaakte-kruidenroomkaas-5ab2718a30006c33b90122a2-712ab4fe.pdf"
    },
    {
        "title": "Kipfilethaasjes met appel, cranberry's en pitten",
        "extra": "Met krieltjes en little gem",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "865 kcal",
        "ingredients": [
            "Krieltjes",
            "Rode ui",
            "Appel",
            "Kipfilethaasjes",
            "Cranberry-pittenmix",
            "Mesclun"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kipfilethaasjes-met-appel-cranberry-s-en-pitten-8e3c496a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kipfilethaasjes-met-appel-cranberry-s-en-pitten-5ab2778630006c38db40a712-3e7aaaa4.pdf"
    },
    {
        "title": "Tomatensoep met paprikapesto-bruschette",
        "extra": "Met peen en grana padano",
        "labels": [
            "Caloriebewust",
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Cashewnoten",
            "Eieren"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "531 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Wortel",
            "Tomaat",
            "Gedroogde tijm",
            "Paprikapoeder",
            "Tomatenpuree",
            "Bruine ciabatta",
            "Paprikapesto",
            "Geraspte grana padano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/tomatensoep-met-paprikapesto-bruschette-7926afd5.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/tomatensoep-met-paprikapesto-bruschette-5ab27c5e30006c3cc4244022-680f94cb.pdf"
    },
    {
        "title": "Gebakken rijst met spiegelei en cashewnoten",
        "extra": "Met roerbakgroenten en lente-ui",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Sesamzaad",
            "Pinda's",
            "Noten",
            "Eieren",
            "Gluten",
            "Soja"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "877 kcal",
        "ingredients": [
            "Zilvervliesrijst",
            "Sesamzaad",
            "Gezouten cashewnoten",
            "Wortel",
            "Sperziebonen",
            "Knoflookteen",
            "Verse gember",
            "Bosui",
            "Ei",
            "Sojasaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-rijst-met-spiegelei-en-cashewnoten-f94766e4.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-rijst-met-spiegelei-en-cashewnoten-5ab282bd30006c42b928e6b2-e5051699.pdf"
    },
    {
        "title": "Bulgur-linzensalade met blauwe kaas",
        "extra": "Met avocado en amandelen",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Amandelnoten",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "734 kcal",
        "ingredients": [
            "Groene linzen",
            "Bulgur",
            "Rode ui",
            "Amandelschaafsel",
            "Avocado",
            "Mesclun",
            "Danablu"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/bulgur-linzensalade-met-blauwe-kaas-b4c23e22.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/bulgur-linzensalade-met-blauwe-kaas-5ab2857030006c45f1222951-585afd8c.pdf"
    },
    {
        "title": "Kapsalon met kipgyros",
        "extra": "Met zelfgemaakte ovenfriet, sla en tomaatjes",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "811 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Knoflookteen",
            "Mini-romatomaten",
            "Verse bieslook",
            "Mayonaise",
            "Kipgyros",
            "Gemengde sla",
            "Biologische karnemelk"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kapsalon-met-kipgyros-4bc6544e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kapsalon-met-kipgyros-5ab378acae08b50db36e5cb2-a821a968.pdf"
    },
    {
        "title": "Farfalle met bospaddestoelenpesto",
        "extra": "Met kastanjechampignons, veldsla en pecorino",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "776 kcal",
        "ingredients": [
            "Farfalle",
            "Sjalot",
            "Kastanjechampignons",
            "Bospaddenstoelenpesto",
            "Veldsla",
            "Verse krulpeterselie",
            "Geraspte pecorino"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/farfalle-met-bospaddestoelenpesto-bb23f70e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/farfalle-met-bospaddenstoelenpesto-5ab37d83ae08b51161171c31-a175da8e.pdf"
    },
    {
        "title": "Biefstukpuntjes in soja-ketjapdressing",
        "extra": "Met rijst en paksoi",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Soja"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "586 kcal",
        "ingredients": [
            "Knoflookteen",
            "Verse gember",
            "Rode peper",
            "Pandanrijst",
            "Biefstukreepjes",
            "Sojasaus",
            "Paksoi"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/biefstukpuntjes-in-soja-ketjapdressing-96f9f333.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/biefstukpuntjes-in-soja-ketjapdressing-5ab37ea5ae08b5121378c862-83a57ea5.pdf"
    },
    {
        "title": "Burger deluxe met aardappelpartjes",
        "extra": "Een burger van kalfsvlees met gebakken ei en spek",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Tarwe",
            "Gerst",
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "1219 kcal",
        "ingredients": [
            "Roseval aardappelen",
            "Biologisch bruin rozenbroodje",
            "Mesclun",
            "Mini-romatomaten",
            "Rode ui",
            "Ontbijtspek",
            "Kalfsburger",
            "Ei",
            "Kappertjes en gesneden augurken",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/burger-deluxe-met-aardappeltjes-500f6ff2.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/burger-deluxe-met-aardappeltjes-5ab386b4ae08b517843cb452-4dd36bc6.pdf"
    },
    {
        "title": "Schelvis met spek en sperziebonen",
        "extra": "Met goudbruine aardappelschijfjes",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Vis",
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "805 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Sperziebonen",
            "Ontbijtspek",
            "Schelvisfilet",
            "Nootmuskaat",
            "Ravigotesaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/schelvis-met-spek-en-sperziebonen-d14415b6.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/schelvis-met-spek-en-sperziebonen-5abb463b30006c437e1f60b2-7d0cc5e1.pdf"
    },
    {
        "title": "Kip-cheeseburgers met zoete rode ui",
        "extra": "Met gebakken aardappeltjes en frisse salade",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Sesamzaad",
            "Lupine"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "958 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Rode ui",
            "Vleestomaat",
            "Botersla",
            "Gekruide kipburger",
            "Mini-hamburgerbol",
            "Geraspte cheddar"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kip-cheeseburgers-met-zoete-rode-ui-f9a33c8b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kip-cheeseburgers-met-zoete-rode-ui-5abb4a59ae08b54b714bbd82-076f9037.pdf"
    },
    {
        "title": "Orzo met broccoli, pecorino en pijnboompitten",
        "extra": "Met verse tijm",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "684 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Champignons",
            "Verse tijm",
            "Orzo",
            "Geraspte pecorino",
            "Pijnboompitten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/orzo-met-broccoli-pecorino-en-pijnboompitten-3fae2371.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/orzo-met-broccoli-pecorino-en-pijnboompitten-5abb4d92ae08b54d3b4f18b2-e90ab1ef.pdf"
    },
    {
        "title": "Scandinavische salade met mosterdschol",
        "extra": "Met gebakken aardappelen",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis",
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Makkelijk",
        "kcal": "672 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Komkommer",
            "Verse dille",
            "Sjalot",
            "Mesclun",
            "Scholfilet",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/scandinavische-salade-met-mosterdschol-b53ca320.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/scandinavische-salade-met-mosterdschol-5abb538aae08b551827ab9c2-53cad414.pdf"
    },
    {
        "title": "Gekruide varkenshaasreepjes in sinaasappelsaus",
        "extra": "Met roerbakgroenten en rijst",
        "labels": [
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Soja"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "757 kcal",
        "ingredients": [
            "Pandanrijst",
            "Handsinaasappel",
            "Knoflookteen",
            "Verse gember",
            "Groentemix: paprika, prei, Chinese kool, witte kool, babymais & winterpeen",
            "Sojasaus",
            "Varkenshaasreepjes met Indonesische kruiden"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gekruide-varkenshaasreepjes-in-sinaasappelsaus-bad9d15f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gekruide-varkenshaasreepjes-in-sinaasappelsaus-5abb55cfae08b5550d21da22-5611ecdd.pdf"
    },
    {
        "title": "Romige groentelasagne met spinazie",
        "extra": "Met courgette en grana padano",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Eieren",
            "Gluten"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "933 kcal",
        "ingredients": [
            "Courgette",
            "Ui",
            "Knoflookteen",
            "Verse oregano",
            "Spinazie",
            "Kookroom",
            "Geraspte grana padano",
            "Lasagnebladen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-groentelasagne-met-spinazie-97d43ca6.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-groentelasagne-met-spinazie-5abb5ebb30006c53634d4872-99b15b24.pdf"
    },
    {
        "title": "Fluweelzachte broccolisoep met danablu",
        "extra": "Met geroosterde ciabatta, kruidenroomkaas en pijnboompitten",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "802 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Champignons",
            "Vastkokende aardappelen",
            "Pijnboompitten",
            "Gedroogde tijm",
            "Witte ciabatta",
            "Kruidenroomkaas",
            "Danablu"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/fluweelzachte-broccolisoep-met-danablu-f298a3a0.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/fluweelzachte-broccolisoep-met-danablu-5abb75b830006c63021d4752-330164a3.pdf"
    },
    {
        "title": "Geroosterde feta met honing en bataat",
        "extra": "Met groene linzen en cherrytomaten",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "932 kcal",
        "ingredients": [
            "Groene linzen",
            "Zoete aardappel",
            "Sjalot",
            "Knoflookteen",
            "Rode cherrytomaten",
            "Feta",
            "Citroen",
            "Verse bladpeterselie",
            "Rucolamelange"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/geroosterde-feta-met-honing-en-bataat-d75dda67.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/geroosterde-feta-met-honing-en-bataat-5abb79ab30006c65e169c862-7432160b.pdf"
    },
    {
        "title": "Geblakerde prei met varkensworst",
        "extra": "Met aardappelpuree en hazelnoten",
        "labels": ["Familie"],
        "allergens": [
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "927 kcal",
        "ingredients": [
            "Prei",
            "Vastkokende aardappelen",
            "Varkensworst met peterselie en knoflook",
            "Hazelnoten",
            "Citroen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/geblakerde-prei-met-varkensworst-5a08edd6.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/geblakerde-prei-met-varkensworst-5abba739ae08b50eb8489062-983098a2.pdf"
    },
    {
        "title": "Mediterrane salade met falafel",
        "extra": "Met parelcouscous, olijven en hummus",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Sesamzaad",
            "Sulfiet",
            "Mosterd"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "967 kcal",
        "ingredients": [
            "Parelcouscous",
            "Sperziebonen",
            "Falafel",
            "Verse dille",
            "Verse bladpeterselie",
            "Leccino olijven",
            "Hummus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/mediterrane-salade-met-falafel-ec1e47b0.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/mediterrane-salade-met-falafel-5abc9aacae08b549f22ab002-adf8a965.pdf"
    },
    {
        "title": "Gebakken kabeljauw met ratatouille",
        "extra": "Met gremolata en olijvenbrood",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Vis",
            "Sulfiet",
            "Melk (inclusief lactose)"
        ],
        "duration": "50 minuten",
        "difficulty": "Moeilijk",
        "kcal": "851 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Aubergine",
            "Courgette",
            "Gele paprika",
            "Gepelde tomaten",
            "Laurierblad",
            "Gedroogde oregano",
            "Verse bladpeterselie",
            "Citroen",
            "Olijvenciabatta",
            "Kabeljauwfilet met huid"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-kabeljauw-met-ratatouille-ad39a62f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-kabeljauw-met-ratatouille-5abcceabae08b56b943bf852-bc3f2eed.pdf"
    },
    {
        "title": "Wraps met peen en gekruid kipgehakt",
        "extra": "Met yoghurt-komkommersaus",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "842 kcal",
        "ingredients": [
            "Gekleurde peenmix",
            "Knoflookteen",
            "Komkommer",
            "Verse munt",
            "Volle yoghurt",
            "Kipgehakt met Mexicaanse kruiden",
            "Witte tortilla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/wraps-met-peen-en-gekruid-kipgehakt-9b0c9534.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/wraps-met-peen-en-gekruid-kipgehakt-5abcd26330006c6960789f02-3afdea2e.pdf"
    },
    {
        "title": "Fettuccine met garnalen en courgette",
        "extra": "Met cr\u00e8me fra\u00eeche. tomaat en rozemarijn",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Schaaldieren"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "704 kcal",
        "ingredients": [
            "Courgette",
            "Gedroogde rozemarijn",
            "Knoflookteen",
            "Tomaat",
            "Citroen",
            "Fettuccine",
            "Biologische cr\u00e8me fra\u00eeche",
            "Garnalen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-fettuccine-met-garnalen-en-rozemarijn-c7bd867b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-fettuccine-met-garnalen-en-rozemarijn-5ac4804030006c1fa77afa92-3b5fd311.pdf"
    },
    {
        "title": "Rundertartaar met champignons",
        "extra": "Met aardappelpartjes en frisse salade",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "670 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Rode ui",
            "Verse rozemarijn",
            "Verse marjolein",
            "Champignons",
            "Komkommer",
            "Tomaat",
            "Mesclun",
            "Rundertartaar"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rundertartaar-met-champignons-0a4c59da.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rundertartaar-met-champignons-5ac484f5ae08b525b87cd631-8a84ba4b.pdf"
    },
    {
        "title": "Champignonrisotto van orzo",
        "extra": "Met walnoten en Parmezaanse kaas",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Noten",
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "673 kcal",
        "ingredients": [
            "Ui",
            "Bosui",
            "Kastanjechampignons",
            "Walnoten",
            "Orzo",
            "Geraspte parmigiano reggiano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/champignonrisotto-van-orzo-e327e8b9.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/champignonrisotto-van-orzo-5ac48a96ae08b529967298f2-4a0f49d7.pdf"
    },
    {
        "title": "Verse makreel met knoflook-peterseliekrieltjes",
        "extra": "Met spinazie, tomaat en kappertjes",
        "labels": ["Familie"],
        "allergens": [
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "745 kcal",
        "ingredients": [
            "Knoflookteen",
            "Verse bladpeterselie",
            "Krieltjes",
            "Pruimtomaat",
            "Kappertjes",
            "Spinazie",
            "Verse makreelfilet met huid"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/verse-makreel-met-knoflook-peterseliekrieltjes-07931774.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/verse-makreel-met-knoflook-peterseliekrieltjes-5ac48ef230006c298b25b4a2-6be15757.pdf"
    },
    {
        "title": "Romige farfalle met gekruid kipgehakt",
        "extra": "Met venkel, spinazie en belegen kaas",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "813 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Rode peper",
            "Venkel",
            "Farfalle",
            "Kipgehakt met Italiaanse kruiden",
            "Spinazie",
            "Biologische cr\u00e8me fra\u00eeche",
            "Geraspte belegen kaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-farfalle-met-gekruid-kipgehakt-568a3d74.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-farfalle-met-gekruid-kipgehakt-5ac4948530006c2d91523a32-b18c8598.pdf"
    },
    {
        "title": "Tomaat-paprikasoep met dragon",
        "extra": "Met parelcouscous en soepstengels",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Sulfiet",
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Eieren"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "678 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Tomaat",
            "Rode paprika",
            "Gedroogde abrikozen",
            "Verse dragon",
            "Parelcouscous",
            "Witte ciabatta",
            "Biologische zure room",
            "Geraspte grana padano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/tomaat-paprikasoep-met-dragon-ac70931a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/tomaat-paprikasoep-met-dragon-5ac4aefaae08b542f773ced2-37931c2b.pdf"
    },
    {
        "title": "Gele rijst met spinazie-kokoscurry",
        "extra": "Met tomaat, cashewnoten en een spiegelei",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Pinda's",
            "Noten",
            "Selderij",
            "Mosterd",
            "Eieren"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "847 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Tomaat",
            "Gemalen kurkuma",
            "Pandanrijst",
            "Cashewnoten",
            "Kokosrasp",
            "Kerriepoeder",
            "Kokosmelk",
            "Ei",
            "Spinazie"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gele-rijst-met-spinazie-kokoscurry-8966cf82.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gele-rijst-met-spinazie-kokoscurry-5ac5ed8630006c4f6867c4c2-1100f9f8.pdf"
    },
    {
        "title": "Salade met peer, danablu en walnoot",
        "extra": "Met gepofte aardappel en courgettelinten",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "715 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Walnoten",
            "Courgette",
            "Peer",
            "Gemengde sla",
            "Danablu"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/salade-met-peer-danablue-en-walnoot-8cff6724.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/salade-met-peer-danablue-en-walnoot-5ac5ef2130006c50cf757da1-0a280720.pdf"
    },
    {
        "title": "Pad thai met kippendij en roerei",
        "extra": "Met noedels, koriander en pinda's",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Pinda's",
            "Eieren",
            "Vis",
            "Gluten",
            "Soja"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "844 kcal",
        "ingredients": [
            "Limoen",
            "Verse koriander",
            "Gezouten pinda's",
            "Knoflookteen",
            "Ei",
            "Kippendijreepjes",
            "Wittekool en peen",
            "Vissaus",
            "Sojasaus",
            "Verse udonnoedels"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pad-thai-met-kippendij-noedels-en-roerei-421c40c7.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pad-thai-met-kippendij-noedels-en-roerei-5ac5f5d430006c549329b182-e1ec8641.pdf"
    },
    {
        "title": "Parelcouscoussalade met avocado en feta",
        "extra": "Met tomaat, limoen en koriander",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "810 kcal",
        "ingredients": [
            "Sjalot",
            "Tomaat",
            "Avocado",
            "Parelcouscous",
            "Limoen",
            "Verse koriander",
            "Pompoenpitten",
            "Veldsla",
            "Feta"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscoussalade-met-avocado-en-feta-9b34641b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscoussalade-met-avocado-en-feta-5ac601f230006c5d2819e6c2-31f74a98.pdf"
    },
    {
        "title": "Rigatoni met broccoli en spekjes",
        "extra": "Met cr\u00e8me fra\u00eeche en belegen kaas",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "876 kcal",
        "ingredients": [
            "Champignons",
            "Rigatoni",
            "Ui, gesneden",
            "Knoflookteen",
            "Verse bladpeterselie",
            "Spekblokjes",
            "Biologische cr\u00e8me fra\u00eeche",
            "Geraspte belegen kaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rigatoni-met-broccoli-en-spekjes-20a98467.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rigatoni-met-broccoli-en-spekjes-5ac6061730006c60240dce02-1764814a.pdf"
    },
    {
        "title": "Varkenshaas met orecchiette",
        "extra": "Met paddenstoelen-roomsaus en rucola",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "854 kcal",
        "ingredients": [
            "Sjalot",
            "Knoflookteen",
            "Gemengde gesneden paddenstoelen",
            "Varkenshaas",
            "Gedroogde rozemarijn",
            "Orecchiette",
            "Biologische cr\u00e8me fra\u00eeche",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/varkenshaas-met-orecchiette-c0ef39bd.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/varkenshaas-met-orecchiette-5ac61806ae08b56f2a0b4ab1-30df78eb.pdf"
    },
    {
        "title": "Lauwwarme salade met gerookte forel",
        "extra": "Met aardappel, boontjes, appel en tuinkers",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Vis"
        ],
        "duration": "30 minuten",
        "difficulty": "Makkelijk",
        "kcal": "657 kcal",
        "ingredients": [
            "Roseval aardappelen",
            "Sperziebonen",
            "Appel",
            "Tuinkers",
            "Gerookte forelfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/lauwwarme-salade-met-gerookte-forel-40a1fd92.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/lauwwarme-salade-met-gerookte-forel-5acc6653ae08b535ea7ddfb2-d6c42ece.pdf"
    },
    {
        "title": "Kippendij in romige cheddar-biersaus",
        "extra": "Met geroosterde aardappeltjes en broccoli",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "872 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Champignons",
            "Sjalot",
            "Verse salie",
            "Kippendijreepjes",
            "Affligem Blond",
            "Biologische cr\u00e8me fra\u00eeche",
            "Geraspte cheddar"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kippendij-in-romige-cheddar-biersaus-c77f1821.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kippendij-in-romige-cheddar-biersaus-5acc6ce930006c399d3477b2-e5850cbd.pdf"
    },
    {
        "title": "Tomatenrisotto met buffelmozzarella",
        "extra": "Met verse basilicum, rode peper en pecorino",
        "labels": ["Familie"],
        "allergens": [
            "Selderij",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Moeilijk",
        "kcal": "639 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Bleekselderij",
            "Pruimtomaat",
            "Vers basilicum",
            "Risottorijst",
            "Rode peper",
            "Buffelmozzarella",
            "Geraspte pecorino"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/tomatenrisotto-met-buffelmozzarella-61d801b1.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/tomatenrisotto-met-buffelmozzarella-5acc712230006c3cb855ef02-f635e880.pdf"
    },
    {
        "title": "Vispakketje van schelvis met zeekraal",
        "extra": "Met tomaat, aardappelpuree en dille",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "562 kcal",
        "ingredients": [
            "Kruimige aardappelen",
            "Tomaat",
            "Schelvisfilet",
            "Zeekraal",
            "Sjalot",
            "Knoflookteen",
            "Verse dille",
            "Citroen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/vispakketje-van-schelvis-met-zeekraal-9849a174.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/vispakketje-van-schelvis-met-zeekraal-5acc753830006c3fec179352-0fdea585.pdf"
    },
    {
        "title": "Koreaanse biefstukwraps met groenten",
        "extra": "Met zoetzure komkommer",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Soja"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "614 kcal",
        "ingredients": [
            "Knoflookteen",
            "Verse gember",
            "Rode peper",
            "Sojasaus",
            "Biefstukreepjes",
            "Komkommer",
            "Verse koriander",
            "Limoen",
            "Witte tortilla",
            "Wittekool en peen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/koreaanse-biefstukwraps-met-groenten-413f5a35.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/koreaanse-biefstukwraps-met-groenten-5acc785aae08b5422c255655-41f8a057.pdf"
    },
    {
        "title": "Aubergine uit de oven met rigatoni",
        "extra": "Met tomaat, oude kaas en salade",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten",
            "Selderij"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "759 kcal",
        "ingredients": [
            "Aubergine",
            "Tomaat",
            "Knoflookteen",
            "Passata",
            "Geraspte oude kaas",
            "Rigatoni",
            "Rucolamelange",
            "Vers basilicum"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/aubergine-uit-de-oven-met-rigatoni-c637879b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/aubergine-uit-de-oven-met-rigatoni-5acc7b5fae08b5447146af52-7a72b013.pdf"
    },
    {
        "title": "Courgettesoep met bospaddenstoelenpesto",
        "extra": "Met gegratineerde speltbaguette",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "810 kcal",
        "ingredients": [
            "Ui",
            "Vastkokende aardappelen",
            "Courgette",
            "Pompoenpitten",
            "Speltbaguette",
            "Bospaddenstoelenpesto",
            "Geraspte belegen kaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/courgettesoep-met-bospaddenstoelenpesto-2bec0bea.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/courgettesoep-met-bospaddenstoelenpesto-5acc7e92ae08b546de44a222-44a70a37.pdf"
    },
    {
        "title": "Linzensalade met spinazie en danablu",
        "extra": "Met bieten en pecannoten",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "599 kcal",
        "ingredients": [
            "Rode ui",
            "Gekookte rode bieten",
            "Spinazie",
            "Linzen",
            "Verse bladpeterselie",
            "Pecannoten",
            "Danablu"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/linzensalade-met-spinazie-en-danablu-8679f48c.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/linzensalade-met-spinazie-en-danablu-5acc8254ae08b549ae224fc2-d81ecf61.pdf"
    },
    {
        "title": "Pittige Thaise hamburger met limoenmayonaise",
        "extra": "Met paksoi en bataatfrieten uit de oven",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Sesamzaad",
            "Gluten",
            "Soja",
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)",
            "Lupine"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "996 kcal",
        "ingredients": [
            "Zoete aardappel",
            "Sesamzaad",
            "Verse koriander",
            "Rode ui",
            "Paksoi",
            "Sojasaus",
            "Limoen",
            "Mayonaise",
            "Hamburgerbol",
            "Half-om-half hamburger met Thaise kruiden"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pittige-thaise-hamburger-met-limoenmayonaise-ittige-thaise-hamburger-met-limoenmayonaise-f12b90e8.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pittige-thaise-hamburger-met-limoenmayonaise-ittige-thaise-hamburger-met-limoenmayonaise-5acc85a330006c4b61615dc2-03220195.pdf"
    },
    {
        "title": "Conchiglie met rode pesto en feta",
        "extra": "Met rode paprika, veldsla en basilicum",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Selderij",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "855 kcal",
        "ingredients": [
            "Sjalot",
            "Rode paprika",
            "Tomaat",
            "Conchiglie",
            "Feta",
            "Vers basilicum",
            "Rode pesto",
            "Veldsla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/conchiglie-met-rode-pesto-en-feta-2e098d45.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/conchiglie-met-rode-pesto-en-feta-5acc9b34ae08b55bc305e9c2-86b35435.pdf"
    },
    {
        "title": "Japanse ramen met gebakken kabeljauw",
        "extra": "Met udonnoedels, spitskool en broccoli",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Soja",
            "Vis"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "484 kcal",
        "ingredients": [
            "Verse gember",
            "Rode peper",
            "Wortel",
            "Champignons",
            "Spitskool en broccoli",
            "Verse udonnoedels",
            "Sojasaus",
            "Kabeljauwfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/japanse-ramen-met-gebakken-kabeljauw-bdff892b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/japanse-ramen-met-gebakken-kabeljauw-5acc9f05ae08b55ee97447b2-be151a70.pdf"
    },
    {
        "title": "Gekonfijte kippendij met bagna cauda",
        "extra": "Met ansjovis, knoflook en zoetzure groenten",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Noten",
            "Vis"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "1046 kcal",
        "ingredients": [
            "Sperziebonen",
            "Gele biet",
            "Rode paprika",
            "Knoflookteen",
            "Gekonfijte kippendij",
            "Kleine baguette",
            "Walnoten",
            "Ansjovis",
            "Verse bladpeterselie"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gekonfijte-kippendij-met-bagna-cauda-328cfe1c.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gekonfijte-kippendij-met-bagna-cauda-5acca33e30006c638303d582-2dead0db.pdf"
    },
    {
        "title": "Gebakken heekfilet met aardappelsalade",
        "extra": "Met radijs, paprika en olijvencr\u00e9me",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Vis",
            "Melk (inclusief lactose)",
            "Mosterd"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "516 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Rode paprika",
            "Radijs",
            "Bosui",
            "Heekfilet met huid",
            "Olijvencr\u00e8me"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-heekfilet-met-aardappelsalade-a6bb03f8.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-heekfilet-met-aardappelsalade-5ad70bf430006c10e91358f2-62e38d93.pdf"
    },
    {
        "title": "Pindagehaktballetjes met noedels en bimi",
        "extra": "Met champignons, sojasaus en sesamolie",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Pinda's",
            "Gluten",
            "Soja",
            "Sesamzaad"
        ],
        "duration": "35 minuten",
        "difficulty": "Moeilijk",
        "kcal": "933 kcal",
        "ingredients": [
            "Knoflookteen",
            "Sjalot",
            "Rode peper",
            "Champignons",
            "Broccolini",
            "Gezouten pinda's",
            "Noedels",
            "Sojasaus",
            "Sesamolie",
            "Gekruide rundergehaktballetjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pindagehaktballetjes-met-noedels-en-bimi-75cf5d62.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pindagehaktballetjes-met-noedels-en-bimi-5ad71090ae08b515304bf152-8877f953.pdf"
    },
    {
        "title": "Orzo met pesto en prei",
        "extra": "Met venkel en provolone",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "705 kcal",
        "ingredients": [
            "Sjalot",
            "Knoflookteen",
            "Venkel",
            "Prei",
            "Orzo",
            "Groene pesto",
            "Provolonevlokken"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/orzo-met-pesto-en-prei-c9648e33.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/orzo-met-pesto-en-prei-5ad7180cae08b51aaf6070c2-74502dd5.pdf"
    },
    {
        "title": "Panzanella met gebakken kabeljauw",
        "extra": "Een klassieke Italiaanse salade van brood en tomaat",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Selderij",
            "Vis",
            "Sulfiet"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "649 kcal",
        "ingredients": [
            "Rode cherrytomaten",
            "Pruimtomaat",
            "Tomaat",
            "Vleestomaat",
            "Witte ciabatta",
            "Sjalot",
            "Knoflookteen",
            "Vers basilicum",
            "Kabeljauwfilet met huid"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/panzanella-met-gebakken-kabeljauw-e5d83ffb.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/panzanella-met-gebakken-kabeljauw-5ad72765ae08b5250a50bf42-9bcf384c.pdf"
    },
    {
        "title": "Japanse noedelsoep met kipfilet",
        "extra": "Met kastanjechampignons en paksoi",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Soja"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "516 kcal",
        "ingredients": [
            "Verse gember",
            "Rode peper",
            "Kastanjechampignons",
            "Paksoi",
            "Gemarineerde kipfiletreepjes",
            "Verse udonnoedels",
            "Sojasaus",
            "Zwarte peper"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/japanse-noedelsoep-59835ad9.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/japanse-noedelsoep-5ad75b7dae08b54c2e22e652-e7d5a305.pdf"
    },
    {
        "title": "Rucolastamppot met kruidenkaas",
        "extra": "Met courgette, tomaat en cr\u00e8me fra\u00eeche",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Selderij",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "762 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Courgette",
            "Rode cherrytomaten",
            "Rucola",
            "Vers basilicum",
            "Zonnebloempitten",
            "Biologische cr\u00e8me fra\u00eeche",
            "Kruidenkaasblokjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rucolastamppot-met-kruidenkaas-50da36fc.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rucolastamppot-met-kruidenkaas-5ad8574230006c1209244fe2-9815d79a.pdf"
    },
    {
        "title": "Quinoa met geroosterde gele biet",
        "extra": "Met verse geitenkaas, avocado en walnoten",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "963 kcal",
        "ingredients": [
            "Gele biet",
            "Quinoa",
            "Sjalot",
            "Spinazie",
            "Avocado",
            "Verse geitenkaas",
            "Walnoten",
            "Gemalen komijnzaad"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/quinoa-met-geroosterde-gele-biet-34f0f931.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/quinoa-met-geroosterde-gele-biet-5ad85b4cae08b51437199212-d7721467.pdf"
    },
    {
        "title": "Gevulde portobello met feta en salade",
        "extra": "Met paprika, bataat en linzen",
        "labels": [
            "Eet mij eerst",
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "748 kcal",
        "ingredients": [
            "Groene linzen",
            "Zoete aardappel",
            "Gedroogde tijm",
            "Rode paprika",
            "Feta",
            "Portobello",
            "Gemengde sla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gevulde-portobello-met-feta-en-salade-b1e25104.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gevulde-portobello-met-feta-en-salade-5ad85f41ae08b516ff4245c2-eadf4b0a.pdf"
    },
    {
        "title": "Pittige Thaise kipgehaktsalade",
        "extra": "Met little gem, pandanrijst en komkommer",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Pinda's",
            "Vis"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "799 kcal",
        "ingredients": [
            "Limoen",
            "Knoflookteen",
            "Rode peper",
            "Gezouten pinda's",
            "Verse koriander",
            "Mesclun",
            "Pandanrijst",
            "Komkommer",
            "Vissaus",
            "Kipgehakt met Italiaanse kruiden"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pittige-thaise-kipgehaktsalade-79f2ed77.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pittige-thaise-kipgehaktsalade-5ad87173ae08b523281d47b2-be6cf353.pdf"
    },
    {
        "title": "Parelcouscous in tomatensaus",
        "extra": "Met buffelmozzarella en bospaddenstoelenpesto",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "788 kcal",
        "ingredients": [
            "Knoflookteen",
            "Rode cherrytomaten",
            "Parelcouscous",
            "Gedroogde oregano",
            "Buffelmozzarella",
            "Bospaddenstoelenpesto"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscous-in-tomatensaus-c671ccd5.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscous-in-tomatensaus-5ad8781730006c28286d37a2-efa68c96.pdf"
    },
    {
        "title": "Snelle groene curry met witvis",
        "extra": "Met broccoli, rijst en gezouten pinda's",
        "labels": ["Familie"],
        "allergens": [
            "Vis",
            "Melk (inclusief lactose)",
            "Pinda's"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "722 kcal",
        "ingredients": [
            "Basmatirijst",
            "Champignons",
            "Koolvisfilet zonder huid",
            "Groene currypasta",
            "Kokosmelk",
            "Gezouten pinda's"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/snelle-groene-curry-met-witvis-fcf19c2b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/snelle-groene-curry-met-witvis-5ad87b8230006c2b0c21aad2-9fb1db54.pdf"
    },
    {
        "title": "Steak frites met dragonmayonaise",
        "extra": "Met gepofte cherrytomaat en groene salade",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Eieren",
            "Mosterd",
            "Sulfiet"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "888 kcal",
        "ingredients": [
            "Rumpsteak",
            "Vastkokende aardappelen",
            "Rode cherrytomaten",
            "Botersla",
            "Verse kervel",
            "Verse dragon",
            "Bosui",
            "Kappertjes en gesneden augurken",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/steak-frites-met-dragonmayonaise-ea09e21f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/steak-frites-met-dragonmayonaise-5ad87e8fae08b52c0b066a52-ff10948e.pdf"
    },
    {
        "title": "Gebakken zeebaars met salieboter",
        "extra": "Met tomaat, prei en kerrie-aardappelen",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Selderij",
            "Mosterd",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "591 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Kerriepoeder",
            "Prei",
            "Rode cherrytomaten",
            "Knoflookteen",
            "Verse salie",
            "Zeebaarsfilet met huid"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-zeebaars-met-salieboter-f314474a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-zeebaars-met-salieboter-5ae19ca930006c3e101514d2-a9461506.pdf"
    },
    {
        "title": "Kippendijstukjes met bospaddenstoelenpesto",
        "extra": "Met krieltjes, rode paprika en spinazie",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "715 kcal",
        "ingredients": [
            "Krieltjes",
            "Ui",
            "Rode paprika",
            "Spinazie",
            "Kippendijreepjes",
            "Bospaddenstoelenpesto"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kippendijstukjes-met-bospaddenstoelenpesto-8b8223d5.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kippendijstukjes-met-bospaddenstoelenpesto-5ae19fefae08b540d36ba6f2-6ab50848.pdf"
    },
    {
        "title": "Italiaanse platbroodpizza's met buffelmozzarella",
        "extra": "Met courgette en gele paprika",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "670 kcal",
        "ingredients": [
            "Knoflookteen",
            "Courgette",
            "Gele paprika",
            "Buffelmozzarella",
            "Passata",
            "Paprikapoeder",
            "Wit platbrood",
            "Belegen kaasplakken",
            "Gedroogde oregano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/italiaanse-platbroodpizza-s-met-buffelmozzarella-d960e8be.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/italiaanse-platbroodpizza-s-met-buffelmozzarella-5ae1a309ae08b542e349a872-686d5446.pdf"
    },
    {
        "title": "Garnalen met noedels en sperziebonen",
        "extra": "Met radijs, citroengras en sojasaus",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Schaaldieren",
            "Soja"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "505 kcal",
        "ingredients": [
            "Sperziebonen",
            "Rode peper",
            "Radijs",
            "Gemalen citroengras",
            "Noedels",
            "Garnalen",
            "Sojasaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/garnalen-met-noedels-en-sperziebonen-2804ea53.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/garnalen-met-noedels-en-sperziebonen-5ae1a8c930006c46ff1539c2-ef98e380.pdf"
    },
    {
        "title": "Duitse biefstuk met pittige bataatpuree",
        "extra": "Met gebakken knoflook-snijbonen",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "605 kcal",
        "ingredients": [
            "Zoete aardappel",
            "Kruimige aardappelen",
            "Knoflookteen",
            "Bosui",
            "Rode peper",
            "Gesneden snijbonen",
            "Duitse biefstuk"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/duitse-biefstuk-met-pittige-bataatpuree-29d17cb5.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/duitse-biefstuk-met-pittige-bataatpuree-5ae1ab57ae08b548854eb8c2-d59e1151.pdf"
    },
    {
        "title": "Orzo met spinazie en cherrytomaten",
        "extra": "Met grana padano en pijnboompitten",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "679 kcal",
        "ingredients": [
            "Sjalot",
            "Knoflookteen",
            "Rode cherrytomaten",
            "Orzo",
            "Pijnboompitten",
            "Paprikapoeder",
            "Spinazie",
            "Geraspte grana padano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/orzo-met-spinazie-en-cherrytomaten-8dddffef.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/orzo-met-spinazie-en-cherrytomaten-5ae1b16cae08b54d256d5454-d62a05ea.pdf"
    },
    {
        "title": "Peruaanse aardappelsoep met feta",
        "extra": "Met courgette, cashewnoten en komijn",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Pinda's",
            "Noten",
            "Gluten",
            "Soja"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "764 kcal",
        "ingredients": [
            "Knoflookteen",
            "Ui",
            "Zoete aardappel",
            "Kruimige aardappelen",
            "Courgette",
            "Gemalen komijnzaad",
            "Paprikapoeder",
            "Verse koriander",
            "Feta",
            "Cashewnoten",
            "Bruine ciabatta"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/peruaanse-aardappelsoep-met-feta-9a692866.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/peruaanse-aardappelsoep-met-feta-5ae1b631ae08b550af6fa492-7087e1ae.pdf"
    },
    {
        "title": "Kokosrijst met pindasaus en gesmoorde groenten",
        "extra": "Met een spiegelei en geroosterde pinda's",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Pinda's",
            "Selderij",
            "Mosterd",
            "Gluten",
            "Soja",
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "936 kcal",
        "ingredients": [
            "Pandanrijst",
            "Kokosrasp",
            "Knoflookteen",
            "Verse gember",
            "Pindakaas",
            "Hollandse groentemix",
            "Kerriepoeder",
            "Gemalen korianderzaad",
            "Sojasaus",
            "Verse koriander",
            "Gezouten pinda's",
            "Ei"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kokosrijst-met-pindasaus-en-gesmoorde-groenten-57d3e4c6.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kokosrijst-met-pindasaus-en-gesmoorde-groenten-5ae1b997ae08b552b52ef692-c6f133ef.pdf"
    },
    {
        "title": "Steak sandwich met gepofte paprika",
        "extra": "Met gekarameliseerde sjalot en blauwe kaas",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "810 kcal",
        "ingredients": [
            "Rode paprika",
            "Sjalot",
            "Mesclun",
            "Kleine baguette",
            "Biefstukreepjes",
            "Danablu"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/steak-sandwich-met-gepofte-paprika-053bc3ed.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/steak-sandwich-met-gepofte-paprika-5ae1c8bbae08b55e50441be2-6b9e9315.pdf"
    },
    {
        "title": "Romige fettuccine met broccoli",
        "extra": "Met geitenkaas, walnoten en zongedroogde tomaatjes",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Noten",
            "Gluten",
            "Melk (inclusief lactose)",
            "Eieren"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "805 kcal",
        "ingredients": [
            "Champignons",
            "Sjalot",
            "Knoflookteen",
            "Zongedroogde tomaten",
            "Walnoten",
            "Fettuccine",
            "Gedroogde oregano",
            "Kookroom",
            "Geitenkaas, geraspt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-fettuccine-met-broccoli-5945e048.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-fettuccine-met-broccoli-5ae1cc4e30006c60c6237fa2-906aa9ea.pdf"
    },
    {
        "title": "Kipshoarma met couscoussalade",
        "extra": "Avec des pains pita et une sauce \u00e0 l\u2019ail",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Eieren",
            "Mosterd",
            "Gluten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "901 kcal",
        "ingredients": [
            "Knoflookteen",
            "Mayonaise",
            "Volkoren couscous",
            "Kipshoarma",
            "Volkoren pitabroodje",
            "Tomaat",
            "Komkommer",
            "Verse munt",
            "Verse bladpeterselie",
            "Gemengde sla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kipshoarma-met-couscoussalade-46f7d415.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kipshoarma-met-couscoussalade-5ae1cf8630006c62f72b3b82-2bfc6e9c.pdf"
    },
    {
        "title": "Witte asperges met gribiche saus",
        "extra": "Met roseval aardappelen en een gekookt ei",
        "labels": ["Familie"],
        "allergens": [
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "50 minuten",
        "difficulty": "Moeilijk",
        "kcal": "727 kcal",
        "ingredients": [
            "Roseval aardappelen",
            "Witte asperges",
            "Ei",
            "Kappertjes en gesneden augurken",
            "Verse krulpeterselie",
            "Limoen",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/witte-asperges-met-gribiche-saus-3a0704ff.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/witte-asperges-met-gribiche-saus-5ae1d2acae08b566313219c2-d2b79453.pdf"
    },
    {
        "title": "Schelvisfilet met bieslooksaus",
        "extra": "Met gebakken aardappeltjes en bospeen",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Vis"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "568 kcal",
        "ingredients": [
            "Wortel",
            "Vastkokende aardappelen",
            "Verse bieslook",
            "Volle yoghurt",
            "Schelvisfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/schelvisfilet-met-bieslooksaus-445aa1e1.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/schelvisfilet-met-bieslooksaus-5ae97017ae08b520f4247612-d3ac730c.pdf"
    },
    {
        "title": "Risotto met venkel en gekruide varkensworst",
        "extra": "Met mascarpone en grana padano",
        "labels": ["Familie"],
        "allergens": [
            "Melk (inclusief lactose)",
            "Eieren"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "753 kcal",
        "ingredients": [
            "Ui",
            "Rode peper",
            "Venkel",
            "Varkensworst met citroen en tijm",
            "Citroen",
            "Venkelzaad",
            "Risottorijst",
            "Mascarpone",
            "Geraspte grana padano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/risotto-met-mascarpone-en-gekruide-varkensworst-2bfd81ac.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/risotto-met-mascarpone-en-gekruide-varkensworst-5ae9766cae08b52555047662-e750ab4c.pdf"
    },
    {
        "title": "Farfalle met spinazie-preisaus",
        "extra": "Met groene pesto en rode peper",
        "labels": [
            "Eet mij eerst",
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "762 kcal",
        "ingredients": [
            "Sjalot",
            "Knoflookteen",
            "Prei",
            "Rode peper",
            "Farfalle",
            "Geraspte parmigiano reggiano",
            "Spinazie",
            "Groene pesto"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/farfalle-met-spinazie-preisaus-6bcc8400.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/farfalle-met-spinazie-preisaus-5ae97ab5ae08b5286b467572-0b80d567.pdf"
    },
    {
        "title": "Parelcouscoussalade met gerookte makreel",
        "extra": "Met geroosterde gele biet, sinaasappel en hazelnoten",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Vis",
            "Noten"
        ],
        "duration": "35 minuten",
        "difficulty": "Makkelijk",
        "kcal": "874 kcal",
        "ingredients": [
            "Gele biet",
            "Parelcouscous",
            "Handsinaasappel",
            "Gerookte makreel",
            "Hazelnoten",
            "Veldsla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/salade-met-gerookte-makreel-en-sinaasappel-15a2cc78.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/salade-met-gerookte-makreel-en-sinaasappel-5ae97fab30006c2bb57247e2-e327df29.pdf"
    },
    {
        "title": "Rundergehaktballetjes in pittige tomatensaus",
        "extra": "Met kruidencouscous",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "770 kcal",
        "ingredients": [
            "Knoflookteen",
            "Rode peper",
            "Tomaat",
            "Passata",
            "Volkoren couscous",
            "Gekruide rundergehaktballetjes",
            "Verse munt",
            "Verse krulpeterselie",
            "Mini-romaine sla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rundergehaktballetjes-in-pittige-tomatensaus-f0a32500.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rundergehaktballetjes-in-pittige-tomatensaus-5ae9981930006c3d3a05cc12-6573be37.pdf"
    },
    {
        "title": "Rijkgevuld roerei met salade",
        "extra": "Met feta, gebakken aardappelen en mayonaise",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Eieren",
            "Mosterd"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "838 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Komkommer",
            "Tomaat",
            "Bosui",
            "Feta",
            "Mesclun",
            "Ei",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rijkgevuld-roerei-met-salade-aa08b881.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rijkgevuld-roerei-met-salade-5ae99fcd30006c432f395182-07b0dae6.pdf"
    },
    {
        "title": "Bataatpuree met gegratineerde aubergine",
        "extra": "Met oude geitenkaas en bieslook",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "571 kcal",
        "ingredients": [
            "Aubergine",
            "Oude geitenkaasvlokken",
            "Zoete aardappel",
            "Verse bieslook",
            "Rode paprika"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/bataatpuree-met-gegratineerde-aubergine-36f00aad.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/bataatpuree-met-gegratineerde-aubergine-5ae9a534ae08b5488416b6a2-1c9c1421.pdf"
    },
    {
        "title": "Rijst met kastanjechampignons in zoete sojasaus",
        "extra": "Met geroosterde broccoli, cashewnoten en spiegelei",
        "labels": [
            "Eet mij eerst",
            "Veggie"
        ],
        "allergens": [
            "Sesamzaad",
            "Eieren",
            "Gluten",
            "Soja",
            "Pinda's",
            "Noten"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "933 kcal",
        "ingredients": [
            "Basmatirijst",
            "Champignons",
            "Verse gember",
            "Knoflookteen",
            "Sjalot",
            "Kastanjechampignons",
            "Verse bladpeterselie",
            "Sesamzaad",
            "Ei",
            "Sojasaus",
            "Cashewnoten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rijst-met-kastanjechampignons-in-zoete-sojasaus-d20afab9.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rijst-met-kastanjechampignons-in-zoete-sojasaus-5aeac79cae08b525151d1182-0134c36f.pdf"
    },
    {
        "title": "Citroen-tijmkipfilet uit de oven",
        "extra": "Met gebakken krieltjes en snijbonen",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "657 kcal",
        "ingredients": [
            "Krieltjes",
            "Kipfilet",
            "Citroen",
            "Gedroogde rozemarijn",
            "Verse tijm",
            "Knoflookteen",
            "Gesneden snijbonen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/citroen-tijmkipfilet-uit-de-oven-c66e4f5c.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/citroen-tijmkipfilet-uit-de-oven-5aeacb50ae08b5270b7b1502-815a39dd.pdf"
    },
    {
        "title": "Drie-tomatensoep met kaascroutons",
        "extra": "Met gerookt paprikapoeder en zure room",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "851 kcal",
        "ingredients": [
            "Knoflookteen",
            "Tomaat",
            "Zongedroogde tomaten",
            "Gerookt paprikapoeder",
            "Rode cherrytomaten",
            "Witte ciabatta",
            "Geraspte belegen kaas",
            "Biologische zure room"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/drie-tomatensoep-met-kaascroutons-35768ad0.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/drie-tomatensoep-met-kaascroutons-5aeacea2ae08b529c00ab9e2-746056dd.pdf"
    },
    {
        "title": "Pita's met kruidige kip en salade",
        "extra": "Met geroosterde kikkererwten, komkommer en yoghurt-tahinsaus",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Sesamzaad"
        ],
        "duration": "20 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "776 kcal",
        "ingredients": [
            "Kikkererwten",
            "Cayennepeper",
            "Volkoren pitabroodje",
            "Rode ui",
            "Kippendijreepjes",
            "Mini-romaine sla",
            "Komkommer",
            "Yoghurt-tahinsaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pita-s-met-kruidige-kip-en-salade-64f1aa4d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pita-s-met-kruidige-kip-en-salade-5aead125ae08b52b220da1d2-747b5874.pdf"
    },
    {
        "title": "Gebakken zalmfilet met zeekraaltopping",
        "extra": "Met gebakken venkel, krieltjes en citroenmayonaise",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Eieren",
            "Mosterd",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "964 kcal",
        "ingredients": [
            "Krieltjes",
            "Ui",
            "Knoflookteen",
            "Venkel",
            "Citroen",
            "Verse dille",
            "Mayonaise",
            "Zalmfilet met huid",
            "Zeekraal"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-zalmfilet-met-zeekraaltopping-16669fc8.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-zalmfilet-met-zeekraaltopping-5aead5fb30006c2f4563e292-2342e857.pdf"
    },
    {
        "title": "Orzo met knoflookgarnalen en courgette",
        "extra": "Met verse kruiden en amandelschaafsel",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Amandelnoten",
            "Selderij",
            "Gluten",
            "Schaaldieren"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "505 kcal",
        "ingredients": [
            "Knoflookteen",
            "Sjalot",
            "Amandelschaafsel",
            "Vers basilicum",
            "Verse oregano",
            "Courgette",
            "Orzo",
            "Garnalen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/orzo-met-knoflookgarnalen-en-courgette-957ef106.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/orzo-met-knoflookgarnalen-en-courgette-5af16e37ae08b57f2c2e0312-c8ba453e.pdf"
    },
    {
        "title": "Kipfilet met pesto-aardappelen",
        "extra": "Met een kleurrijke zomersalade",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "30 minuten",
        "difficulty": "Moeilijk",
        "kcal": "700 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Kipfilet",
            "Groene pesto",
            "Rode paprika",
            "Appel",
            "Radijs"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kipfilet-met-pesto-aardappelen-8a290021.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kipfilet-met-pesto-aardappelen-5af18bf9ae08b516ea68e64b-43968282.pdf"
    },
    {
        "title": "Shakshuka met geitenkaas en ei",
        "extra": "Met krulpeterselie en speltbaguette",
        "labels": [
            "Caloriebewust"
        ],
        "allergens": [
            "Eieren",
            "Melk (inclusief lactose)",
            "Gluten"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "601 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Rode peper",
            "Rode paprika",
            "Pruimtomaat",
            "Verse krulpeterselie",
            "Ei",
            "Verse geitenkaas",
            "Speltbaguette"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/shakshuka-met-geitenkaas-en-ei-73049543.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/shakshuka-met-geitenkaas-en-ei-5af194c9ae08b51dac1c2672-6ed2ae7b.pdf"
    },
    {
        "title": "Gebakken kabeljauw met kokoscrumble",
        "extra": "Met geroosterde broccoli en limoenmayonaise",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Eieren",
            "Mosterd",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "796 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Champignons",
            "Limoen",
            "Mayonaise",
            "Kokosrasp",
            "Kabeljauwfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-kabeljauw-met-kokoscrumble-5fd8342e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-kabeljauw-met-kokoscrumble-5af1990e30006c20913a8792-dd54bccc.pdf"
    },
    {
        "title": "Italiaans gevuld pitabroodje",
        "extra": "Met rundergehakt, tomaat-pestosalade en bataatfrietjes",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "901 kcal",
        "ingredients": [
            "Zoete aardappel",
            "Gedroogde rozemarijn",
            "Volkoren pitabroodje",
            "Tomaat",
            "Groene pesto",
            "Rundergehakt met Italiaanse kruiden",
            "Gemengde sla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/italiaans-gevuld-pitabrood-63b7aa5d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/italiaans-gevuld-pitabrood-5af19bc830006c22f42c1c22-46e49587.pdf"
    },
    {
        "title": "Groentelasagne met zelfgemaakte roomsaus",
        "extra": "Met aubergine, spinazie en oude kaas",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten",
            "Eieren"
        ],
        "duration": "1 uur",
        "difficulty": "Gemiddeld",
        "kcal": "926 kcal",
        "ingredients": [
            "Aubergine",
            "Ui",
            "Knoflookteen",
            "Gedroogde rozemarijn",
            "Spinazie",
            "Kookroom",
            "Geraspte oude kaas",
            "Lasagnebladen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/groentelasagne-met-zelfgemaakte-roomsaus-9bf67080.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/groentelasagne-met-zelfgemaakte-roomsaus-5af1b205ae08b534815e9e52-897ee262.pdf"
    },
    {
        "title": "Gepofte aardappelen met tomatensaus",
        "extra": "Met kastanjechampignons en belegen kaas",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "50 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "756 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Sjalot",
            "Knoflookteen",
            "Kastanjechampignons",
            "Rode cherrytomaten",
            "Verse bieslook",
            "Verse bladpeterselie",
            "Passata",
            "Geitenkaas, geraspt",
            "Biologische zure room"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gepofte-aardappelen-met-tomatensaus-d528bc15.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gepofte-aardappelen-met-tomatensaus-5af2affc30006c77df518302-251d2e18.pdf"
    },
    {
        "title": "Kruidige tofu met rijst en pindasaus",
        "extra": "Met groenten, pinda's en kokos",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Pinda's",
            "Selderij",
            "Mosterd",
            "Soja",
            "Tarwe",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "917 kcal",
        "ingredients": [
            "Sperziebonen",
            "Rode paprika",
            "Rode peper",
            "Gezouten pinda's",
            "Pandanrijst",
            "Kerriepoeder",
            "Gekruide tofublokjes",
            "Pindakaas",
            "Kokosrasp"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kruidige-tofu-met-rijst-en-pindasaus-de23ff3c.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kruidige-tofu-met-rijst-en-pindasaus-5af2bb3bae08b5165d783102-587075ec.pdf"
    },
    {
        "title": "Chinese rijst met gemarineerde varkensreepjes",
        "extra": "Met ham, omelet en pittige zoetzure-komkommer",
        "labels": ["Familie"],
        "allergens": [
            "Eieren",
            "Pinda's"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "943 kcal",
        "ingredients": [
            "Knoflookteen",
            "Varkenshaasreepjes met Indonesische kruiden",
            "Pandanrijst",
            "Rode peper",
            "Komkommer",
            "Prei",
            "Ei",
            "Achterham",
            "Gezouten pinda's"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/chinese-rijst-met-gemarineerde-varkensreepjes-824fe93f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/chinese-rijst-met-gemarineerde-varkensreepjes-5af2d73a30006c170b6c6782-354c6192.pdf"
    },
    {
        "title": "Broodje omelet met pittige blauwe kaas",
        "extra": "Met gebakken portobello en coleslaw",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Sesamzaad",
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "886 kcal",
        "ingredients": [
            "Knoflookteen",
            "Verse bladpeterselie",
            "Portobello",
            "Rodekool en wortel",
            "Biologisch wit broodje met roggedesem",
            "Ei",
            "Danablu"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/broodje-omelet-met-pittige-blauwe-kaas-950c1b07.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/broodje-omelet-met-pittige-blauwe-kaas-5af2dea1ae08b530884cf342-561a6563.pdf"
    },
    {
        "title": "Curry met kip en sperziebonen",
        "extra": "Met couscous, munt en amandelen",
        "labels": [
            "Caloriebewust"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "679 kcal",
        "ingredients": [
            "Sperziebonen",
            "Kippendijreepjes",
            "Kokosmelk",
            "Rode currypasta",
            "Volkoren couscous",
            "Verse munt",
            "Amandelen",
            "Magere yoghurt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/curry-met-kip-en-sperziebonen-77a71fc3.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/curry-met-kip-en-sperziebonen-5af2ea4a30006c244c474f32-8ea8dad0.pdf"
    },
    {
        "title": "Gevulde kipfilet met coppa di parma",
        "extra": "Met ricotta, citroenrisotto en geroosterde courgette",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "40 minuten",
        "difficulty": "Moeilijk",
        "kcal": "944 kcal",
        "ingredients": [
            "Sjalot",
            "Knoflookteen",
            "Courgette",
            "Verse oregano",
            "Kipfilet",
            "Ricotta",
            "Coppa di Parma",
            "Risottorijst",
            "Geraspte parmigiano reggiano",
            "Citroen",
            "Hazelnoten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gevulde-kipfilet-met-coppa-di-parma-55cbafe1.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gevulde-kipfilet-met-coppa-di-parma-5af2f14f30006c2966670152-8202ed42.pdf"
    },
    {
        "title": "Oosters vispakketje met prei en tomaat",
        "extra": "Met plakkerige kokosrijst",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Vis"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "623 kcal",
        "ingredients": [
            "Verse gember",
            "Rode peper",
            "Prei",
            "Pruimtomaat",
            "Limoen",
            "Kokosmelk",
            "Schelvisfilet",
            "Zilvervliesrijst"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/oosters-vispakketje-met-prei-en-tomaat-97fbbc20.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/oosters-vispakketje-met-prei-en-tomaat-5afbec5a30006c4c6e4e6362-2255072e.pdf"
    },
    {
        "title": "Romige pasta met kipgehaktballetjes",
        "extra": "Met postelein en witte courgette",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Makkelijk",
        "kcal": "795 kcal",
        "ingredients": [
            "Knoflookteen",
            "Witte courgette",
            "Verse kervel",
            "Verse dragon",
            "Postelein",
            "Kipgehakt met Italiaanse kruiden",
            "Rigatoni",
            "Biologische cr\u00e8me fra\u00eeche"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-pasta-met-kipgehaktballetjes-d0e1e338.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-pasta-met-kipgehaktballetjes-5afbfb8530006c56ec086522-185d65aa.pdf"
    },
    {
        "title": "Romige spinaziegratin met spiegelei",
        "extra": "Met gebakken aardappeltjes",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Eieren"
        ],
        "duration": "40 minuten",
        "difficulty": "Makkelijk",
        "kcal": "819 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Sjalot",
            "Knoflookteen",
            "Tomaat",
            "Nootmuskaat",
            "Gedroogde tijm",
            "Spinazie",
            "Biologische cr\u00e8me fra\u00eeche",
            "Geraspte belegen kaas",
            "Ei"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-spinaziegratin-met-spiegelei-116df125.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-spinaziegratin-met-spiegelei-5afbfdee30006c58d94de062-0c095a58.pdf"
    },
    {
        "title": "Zalmfilet met Aziatische aardappelsalade",
        "extra": "Met gewokte broccoli en sesamzaadjes",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Sesamzaad",
            "Vis",
            "Eieren",
            "Mosterd"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "742 kcal",
        "ingredients": [
            "Roseval aardappelen",
            "Champignons",
            "Knoflookteen",
            "Sjalot",
            "Gemalen korianderzaad",
            "Sojasaus",
            "Sesamzaad",
            "Zalmfilet met huid",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/zalmfilet-met-aziatische-aardappelsalade-f38ff275.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/zalmfilet-met-aziatische-aardappelsalade-5afc01b9ae08b55ecf040912-d4e4c912.pdf"
    },
    {
        "title": "Tabouleh met varkenshaasreepjes",
        "extra": "Met rode paprika en verse kruiden",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "723 kcal",
        "ingredients": [
            "Bulgur",
            "Rode paprika",
            "Rode ui",
            "Verse munt",
            "Verse koriander",
            "Verse krulpeterselie",
            "Varkenshaasreepjes met gyroskruiden",
            "Gemengde sla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/tabouleh-met-varkenshaasreepjes-3c76fd66.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/tabouleh-met-varkenshaasreepjes-5afd3ef8ae08b54c546083d2-90c4a776.pdf"
    },
    {
        "title": "Zoete paprika-bataatsoep",
        "extra": "Met cr\u00e8me fra\u00eeche en een kurkumabroodje",
        "labels": [
            "Caloriebewust",
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "688 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Zoete aardappel",
            "Rode paprika",
            "Kurkumabroodje",
            "Pompoenpitten",
            "Biologische cr\u00e8me fra\u00eeche"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/zoete-paprika-bataatsoep-dcde415c.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/zoete-paprika-bataatsoep-5afd4124ae08b54e543558e1-141bc46b.pdf"
    },
    {
        "title": "Pastasalade met gekleurde tomaten en feta",
        "extra": "Met rucola en groene pesto",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "689 kcal",
        "ingredients": [
            "Fusilli",
            "Gekleurde cherrytomaten",
            "Bosui",
            "Rucola",
            "Feta",
            "Groene pesto"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pastasalade-met-gekleurde-tomaten-en-feta-538ff6b3.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pastasalade-met-gekleurde-tomaten-en-feta-5afd43fcae08b54faa3fa7f2-40780d7d.pdf"
    },
    {
        "title": "Gnocchi uit de oven met broccoli",
        "extra": "Met kappertjes en provolone",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Eieren",
            "Sulfiet",
            "Tarwe",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "951 kcal",
        "ingredients": [
            "Champignons",
            "Sjalot",
            "Knoflookteen",
            "Gnocchi",
            "Gedroogde oregano",
            "Kappertjes",
            "Provolonevlokken"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gnocchi-uit-de-oven-met-broccoli-8c7fef0d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gnocchi-uit-de-oven-met-broccoli-5afd466f30006c53664e1db2-bcb816f9.pdf"
    },
    {
        "title": "Gekruide kipburgers met naan",
        "extra": "Met ras-el-hanout-aubergine uit de oven en raita",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten",
            "Tarwe"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "733 kcal",
        "ingredients": [
            "Aubergine",
            "Ras el hanout",
            "Komkommer",
            "Verse munt",
            "Gemalen korianderzaad",
            "Volle yoghurt",
            "Gekruide kipburger",
            "Naanbrood",
            "Verse koriander",
            "Bosui"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gekruide-kipburgers-met-naan-8d17ef4e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gekruide-kipburgers-met-naan-5afd4982ae08b5539a018424-e9003bf6.pdf"
    },
    {
        "title": "Pita's met falafel en yazzara-dip",
        "extra": "Met mini-romaine, rode paprika en feta",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "978 kcal",
        "ingredients": [
            "Volkoren pitabroodje",
            "Rode paprika",
            "Mini-romaine sla",
            "Falafel",
            "Feta",
            "Yazzara"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pita-s-met-falafel-en-yazzara-dip-206a1a5a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pita-s-met-falafel-en-yazzara-dip-5afe90e330006c44e7275ef2-c44c8ead.pdf"
    },
    {
        "title": "Parelcouscoussalade met Duitse biefstuk",
        "extra": "Met gebakken portobello en pestodressing",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "851 kcal",
        "ingredients": [
            "Parelcouscous",
            "Knoflookteen",
            "Portobello",
            "Tomaat",
            "Groene pesto",
            "Duitse biefstuk",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscoussalade-met-duitse-biefstuk-27506644.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscoussalade-met-duitse-biefstuk-5afe93b330006c46f03dc812-7b20e1fd.pdf"
    },
    {
        "title": "Pollo tonnato met zuurdesembrood",
        "extra": "Gepocheerde kip met tonijnsaus",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Sesamzaad",
            "Vis",
            "Eieren",
            "Mosterd",
            "Selderij",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Moeilijk",
        "kcal": "1251 kcal",
        "ingredients": [
            "Rode cherrytomaten",
            "Biologisch wit broodje met roggedesem",
            "Tonijn in olijfolie",
            "Kappertjes",
            "Mayonaise",
            "Vers basilicum",
            "Verse bladpeterselie",
            "Verse bieslook",
            "Kipfilethaasjes",
            "Gemengde sla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pollo-tonnato-met-zuurdesembrood-4cf55e0a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pollo-tonnato-met-zuurdesembrood-5afe990030006c49a9120a92-8d75af27.pdf"
    },
    {
        "title": "Schelvisfilet met tomatentapenade",
        "extra": "Met ratatouille en rijst",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Sulfiet",
            "Vis"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "679 kcal",
        "ingredients": [
            "Basmatirijst",
            "Ui",
            "Knoflookteen",
            "Rode peper",
            "Pruimtomaat",
            "Aubergine",
            "Tomatentapenade",
            "Paprikapoeder",
            "Schelvisfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/schelvisfilet-met-tomatentapenade-7f9de009.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/schelvisfilet-met-tomatentapenade-5b052bcc30006c28b717fb02-0d14b29a.pdf"
    },
    {
        "title": "Cheeseburger met tomaat en oude kaas",
        "extra": "Met gebakken roseval aardappeltjes en mayonaise",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Sesamzaad",
            "Lupine",
            "Eieren",
            "Mosterd"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "992 kcal",
        "ingredients": [
            "Roseval aardappelen",
            "Hamburgerbol",
            "Rode ui",
            "Vleestomaat",
            "Botersla",
            "Half-om-half hamburger",
            "Old Amsterdam",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/cheeseburger-met-tomaat-en-oude-kaas-d1bd2c59.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/cheeseburger-met-tomaat-en-oude-kaas-5b052ef5ae08b52bbb731282-aece17a0.pdf"
    },
    {
        "title": "Risotto van orzo met paddenstoelen",
        "extra": "Met kruidenkaas en verse basilicum",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Selderij",
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "612 kcal",
        "ingredients": [
            "Sjalot",
            "Knoflookteen",
            "Vers basilicum",
            "Bosui",
            "Orzo",
            "Pompoenpitten",
            "Gemengde gesneden paddenstoelen",
            "Kruidenkaasblokjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/risotto-van-orzo-met-paddenstoelen-4fa866cf.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/risotto-van-orzo-met-paddenstoelen-5b05337230006c2df9298512-1cf39c52.pdf"
    },
    {
        "title": "Aardappelsalade met gerookte forel",
        "extra": "Met radijs en mosterd-dilledressing",
        "labels": [
            "Caloriebewust"
        ],
        "allergens": [
            "Vis"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "598 kcal",
        "ingredients": [
            "Krieltjes",
            "Sperziebonen",
            "Radijs",
            "Verse dille",
            "Gerookte forelfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/aardappelsalade-met-gerookte-forel-e0d27960.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/aardappelsalade-met-gerookte-forel-5b0535f4ae08b530db745b92-4599ff66.pdf"
    },
    {
        "title": "Kokos-noedelsoep met kippendij",
        "extra": "Met vers citroengras en basilicum",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Selderij",
            "Vis"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "643 kcal",
        "ingredients": [
            "Verse gember",
            "Rode peper",
            "Wortel",
            "Kipfiletreepjes met baharat",
            "Kokosmelk",
            "Gemalen citroengras",
            "Snijbonen",
            "Verse udonnoedels",
            "Vers basilicum",
            "Limoen",
            "Vissaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kokos-noedelsoep-met-kippendij-c2f552de.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kokos-noedelsoep-met-kippendij-5b0536e2ae08b5318762af92-6ccdca7f.pdf"
    },
    {
        "title": "Penne met linzen-bolognesesaus en feta",
        "extra": "Met rucola en pompoenpitten",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "855 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Rode cherrytomaten",
            "HAK linzen",
            "Pompoenpitten",
            "Gedroogde oregano",
            "Tomatenpuree",
            "Volkoren penne",
            "Rucola",
            "Feta"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/penne-met-linzen-bolognesesaus-en-feta-fe859c3c.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/penne-met-linzen-bolognesesaus-en-feta-5b05658e30006c537e4e41a2-94051e8e.pdf"
    },
    {
        "title": "Patatas bravas met geitenkaasomelet",
        "extra": "Met Turkse pepers en pittige tomatensaus",
        "labels": ["Familie"],
        "allergens": [
            "Eieren",
            "Melk (inclusief lactose)",
            "Mosterd",
            "Gluten"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "837 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Paprikapoeder",
            "Turkse groene peper",
            "Knoflookteen",
            "Rode peper",
            "Verse bladpeterselie",
            "Passata",
            "Ei",
            "Geitenkaas, geraspt",
            "Aioli"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/patatas-bravas-met-geitenkaasomelet-f33e7e87.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/patatas-bravas-met-geitenkaasomelet-5b057a6dae08b564d7555a82-346db5d2.pdf"
    },
    {
        "title": "Bataat en cherrytomaten uit de oven",
        "extra": "Met bimi-salade en spiegelei",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Eieren"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "600 kcal",
        "ingredients": [
            "Zoete aardappel",
            "Rode cherrytomaten",
            "Sjalot",
            "Knoflookteen",
            "Broccolini",
            "Verse krulpeterselie",
            "Gemengde sla",
            "Ei"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/bataat-en-cherrytomaten-uit-de-oven-b882e59f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/bataat-en-cherrytomaten-uit-de-oven-5b066f21ae08b5208b207572-96c06694.pdf"
    },
    {
        "title": "Kippendijfilet met gepofte paprika",
        "extra": "Met rijst en feta",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Amandelnoten"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "803 kcal",
        "ingredients": [
            "Rode paprika",
            "Citroen",
            "Kippendijfilet",
            "Mexicaanse kruiden",
            "Surinaamse rijst",
            "Verse bladpeterselie",
            "Feta",
            "Amandelschaafsel"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kippendijfilet-met-gepofte-paprika-a1215071.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kippendijfilet-met-gepofte-paprika-5b0671a0ae08b522640a8512-ebe93b72.pdf"
    },
    {
        "title": "Platbroodpizza met groene pesto",
        "extra": "Met buffelmozzarella, champignons en rucola",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "793 kcal",
        "ingredients": [
            "Sjalot",
            "Champignons",
            "Wit platbrood",
            "Groene pesto",
            "Buffelmozzarella",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/platbroodpizza-met-groene-pesto-2c0f499d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/platbroodpizza-met-groene-pesto-5b06781d30006c221a680342-1176d9f3.pdf"
    },
    {
        "title": "Kabeljauwfilet met bieslookmayonaise",
        "extra": "Met knoflookkrieltjes en broccoli",
        "labels": ["Familie"],
        "allergens": [
            "Vis",
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "833 kcal",
        "ingredients": [
            "Krieltjes",
            "Champignons",
            "Knoflookteen",
            "Verse bieslook",
            "Kabeljauwfilet",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kabeljauwfilet-met-bieslookmayonaise-58d2a462.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kabeljauwfilet-met-bieslookmayonaise-5b067c43ae08b52ada6b4982-6042b26d.pdf"
    },
    {
        "title": "Kalfsstoof in bladerdeegbakje",
        "extra": "Met frisse groene salade",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Lupine",
            "Noten",
            "Sulfiet"
        ],
        "duration": "40 minuten",
        "difficulty": "Moeilijk",
        "kcal": "730 kcal",
        "ingredients": [
            "Gele wortel",
            "Radijs",
            "Gemengde sla",
            "Verse kervel",
            "Verse dragon",
            "Kalfsstoof",
            "Sjalot",
            "Bladerdeegbakje",
            "Pistachenoten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kalfsstoof-in-bladerdeegbakje-a9cb9a1d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kalfsstoof-in-bladerdeegbakje-5b06818cae08b52e166fd0a2-519a2374.pdf"
    },
    {
        "title": "Orzo met garnalen en venkel",
        "extra": "Met provolone",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Schaaldieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "609 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Venkel",
            "Orzo",
            "Gedroogde tijm",
            "Garnalen",
            "Provolonevlokken"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/orzo-met-garnalen-en-venkel-7f7922c2.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/orzo-met-garnalen-en-venkel-5b0e685e30006c04993f27d2-3575f682.pdf"
    },
    {
        "title": "Langzaam gegaarde kip in bier",
        "extra": "Met rode paprika, prei en aardappel",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "50 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "750 kcal",
        "ingredients": [
            "Ui",
            "Prei",
            "Knoflookteen",
            "Vastkokende aardappelen",
            "Spekblokjes",
            "Kippendijfilet",
            "Laurierblad",
            "Heineken Extra Vers",
            "Rode paprika"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/langzaam-gegaarde-kip-in-bier-697c708f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/langzaam-gegaarde-kip-in-bier-5b0e6be6ae08b515370969e2-d8ede384.pdf"
    },
    {
        "title": "Portobello met geitenkaas uit de oven",
        "extra": "Met gebakken aardappeltjes en courgette",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "684 kcal",
        "ingredients": [
            "Roseval aardappelen",
            "Verse rozemarijn",
            "Rode ui",
            "Courgette",
            "Portobello",
            "Verse geitenkaas",
            "Walnoten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/portobello-met-geitenkaas-uit-de-oven-b94d3462.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/portobello-met-geitenkaas-uit-de-oven-5b0e737bae08b51b774848e2-aa5ff382.pdf"
    },
    {
        "title": "Pilav met gestoomde kabeljauw",
        "extra": "Met basmatirijst, paprika en spinazie",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Selderij",
            "Mosterd",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "560 kcal",
        "ingredients": [
            "Sjalot",
            "Rode peper",
            "Knoflookteen",
            "Rode paprika",
            "Verse gember",
            "Kerriepoeder",
            "Basmatirijst",
            "Spinazie",
            "Citroen",
            "Kabeljauwfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pilav-met-gestoomde-kabeljauw-9b58d938.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pilav-met-gestoomde-kabeljauw-5b0e8eaeae08b5310d365602-2130e144.pdf"
    },
    {
        "title": "Burger met pittige groene peper en piccalilly",
        "extra": "Met aardappelpartjes en salade",
        "labels": [
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Mosterd"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "925 kcal",
        "ingredients": [
            "Aardappelpartjes",
            "Gedroogde tijm",
            "Tomaat",
            "Komkommer",
            "Groene peper",
            "Gemengde sla",
            "Bruine ciabatta",
            "Half-om-half hamburger",
            "Piccalilly"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/burger-met-pittige-groene-peper-en-piccalilly-cbd60477.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/burger-met-pittige-groene-peper-en-piccalilly-5b0e923dae08b5340321a482-95922106.pdf"
    },
    {
        "title": "Kruidige couscoussalade met verse geitenkaas",
        "extra": "Met zoete appel en frisse limoen",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "857 kcal",
        "ingredients": [
            "Volkoren couscous",
            "Gele paprika",
            "Pruimtomaat",
            "Bosui",
            "Amandelen",
            "Appel",
            "Verse bladpeterselie",
            "Verse citroenmelisse",
            "Limoen",
            "Verse geitenkaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kruidige-couscoussalade-met-verse-geitenkaas-8dc89bc3.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kruidige-couscoussalade-met-verse-geitenkaas-5b0e96ff30006c29d83d4e62-4d33ffe7.pdf"
    },
    {
        "title": "Pompoen-pindasoep met ei",
        "extra": "Met peen en Libanees platbrood",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Selderij",
            "Mosterd",
            "Eieren",
            "Gluten",
            "Pinda's"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "823 kcal",
        "ingredients": [
            "Knoflookteen",
            "Wortel",
            "Bosui",
            "Pompoenblokjes",
            "Kerriepoeder",
            "Ei",
            "Wit platbrood",
            "Pindakaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pompoen-pindasoep-mei-2648dc79.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pompoen-pindasoep-mei-5b0e9d3dae08b53d09603dc2-2ce14bab.pdf"
    },
    {
        "title": "Bietenrisotto met feta en munt",
        "extra": "Met veldsla en walnoten",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "658 kcal",
        "ingredients": [
            "Sjalot",
            "Walnoten",
            "Risottorijst",
            "Gekookte rode bieten",
            "Feta",
            "Verse munt",
            "Veldsla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/bietenrisotto-met-feta-en-munt-a620d055.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/bietenrisotto-met-feta-en-munt-5b0ea24eae08b5411112ffd2-5e0276fb.pdf"
    },
    {
        "title": "Zweedse gehaktballetjes met cranberrysaus",
        "extra": "Met romige puree en broccoli",
        "labels": ["Familie"],
        "allergens": [
            "Eieren",
            "Melk (inclusief lactose)",
            "Mosterd"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "752 kcal",
        "ingredients": [
            "Aardappelen",
            "Champignons",
            "Sjalot",
            "Verse bladpeterselie",
            "Ei",
            "Rundergehakt",
            "Gemalen kaneel",
            "Kookroom",
            "Cranberrychutney",
            "Nootmuskaat"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/zweedse-gehaktballetjes-met-cranberrysaus-8d089be3.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/zweedse-gehaktballetjes-met-cranberrysaus-5b0fb0b5ae08b51e5570b632-da1959ed.pdf"
    },
    {
        "title": "Indonesische currry-noedelsoep",
        "extra": "Met koriander en pinda's",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Selderij",
            "Pinda's"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "743 kcal",
        "ingredients": [
            "Rode peper",
            "Knoflookteen",
            "Groentemix van kastanjechampignons, prei, broccoli en courgette",
            "Besengek-currypasta",
            "Kokosmelk",
            "Verse koriander",
            "Gezouten pinda's",
            "Verse udonnoedels"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/indonesische-currry-noedelsoep-d8da7ce1.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/indonesische-currry-noedelsoep-5b0fbf6730006c1ad37852f2-420fb864.pdf"
    },
    {
        "title": "Casarecce met kipgehakt in roomsaus",
        "extra": "Met spinazie en cherrytomaatjes",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "742 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Rode cherrytomaten",
            "Casarecce",
            "Kipgehakt met Italiaanse kruiden",
            "Paprikapoeder",
            "Kookroom",
            "Spinazie"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/casarecce-met-kipgehakt-in-roomsaus-02d6ad62.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/casarecce-met-kipgehakt-in-roomsaus-5b0fd310ae08b5396c410942-b9e41f68.pdf"
    },
    {
        "title": "Gekonfijte parelhoen met aardappelpuree",
        "extra": "Met in boter gesmoorde little gem en hazelnoten",
        "labels": ["Familie"],
        "allergens": [
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "984 kcal",
        "ingredients": [
            "Hazelnoten",
            "Kruimige aardappelen",
            "Sjalot",
            "Mesclun",
            "Verse bieslook",
            "Verse dragon",
            "Gekonfijte parelhoenpoot",
            "Biologische cr\u00e8me fra\u00eeche"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gekonfijte-parelhoen-met-aardappelpuree-6e8a0efe.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gekonfijte-parelhoen-met-aardappelpuree-5b0fd613ae08b53b9b74f682-02436113.pdf"
    },
    {
        "title": "Visstoof met kokosmelk en limoen",
        "extra": "Met rijst en verse koriander",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Selderij",
            "Mosterd",
            "Vis"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "620 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Rode paprika",
            "Kokosmelk",
            "Kerriepoeder",
            "Tomatenblokjes",
            "Pandanrijst",
            "Koolvisfilet zonder huid",
            "Verse koriander",
            "Limoen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/visstoof-met-kokosmelk-en-limoen-984af2b0.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/visstoof-met-kokosmelk-en-limoen-5b17962130006c472e5f9232-3a396282.pdf"
    },
    {
        "title": "Fettuccine met chipolataworstjes",
        "extra": "In pittige tomatensaus met paprika, harissa en basilicum",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Selderij",
            "Gluten",
            "Sulfiet"
        ],
        "duration": "40 minuten",
        "difficulty": "Moeilijk",
        "kcal": "818 kcal",
        "ingredients": [
            "Gele paprika",
            "Rode paprika",
            "Knoflookteen",
            "Rode ui",
            "Pruimtomaat",
            "Vers basilicum",
            "Runderchipolataworstje",
            "Fettuccine",
            "Passata",
            "Harissa",
            "Gedroogde oregano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/fettuccine-met-chipolataworstjes-dc5e5d8b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/fettuccine-met-chipolataworstjes-5b1799c0ae08b560fa0fc9a2-aa9fa401.pdf"
    },
    {
        "title": "Portobello-burger met gebakken ei",
        "extra": "Met gebakken aardappelen en geroosterde courgette",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten",
            "Sesamzaad",
            "Lupine",
            "Eieren",
            "Mosterd"
        ],
        "duration": "50 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "979 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Gedroogde rozemarijn",
            "Gedroogde tijm",
            "Rode ui",
            "Courgette",
            "Geraspte belegen kaas",
            "Hamburgerbol",
            "Ei",
            "Mayonaise",
            "Tabasco",
            "Portobello"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/portobello-burger-met-gebakken-ei-17648982.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/portobello-burger-met-gebakken-ei-5b179c56ae08b562a23bcbf2-a37390c3.pdf"
    },
    {
        "title": "Noedels met warmgerookte makreel",
        "extra": "Met peultjes en geroosterde kokos en pinda",
        "labels": ["Familie"],
        "allergens": [
            "Vis",
            "Pinda's",
            "Gluten",
            "Soja"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "856 kcal",
        "ingredients": [
            "Gerookte makreel",
            "Prei",
            "Knoflookteen",
            "Rode peper",
            "Gezouten pinda's",
            "Kokosrasp",
            "Sojasaus",
            "Peultjes",
            "Verse udonnoedels"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/noedels-met-warmgerookte-makreel-b84496ca.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/noedels-met-warmgerookte-makreel-5b17a3ee30006c510a715c82-0bbc53f5.pdf"
    },
    {
        "title": "Siciliaanse orzo met kipgehakt en rozijnen",
        "extra": "Met broccoli, pecorino en pijnboompitten",
        "labels": [
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "770 kcal",
        "ingredients": [
            "Rode ui",
            "Knoflookteen",
            "Champignons",
            "Orzo",
            "Rozijnen",
            "Pijnboompitten",
            "Kipgehakt met Italiaanse kruiden",
            "Geraspte pecorino"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/siciliaanse-orzo-met-kipgehakt-en-rozijnen-7742445d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/siciliaanse-orzo-met-kipgehakt-en-rozijnen-5b17a905ae08b56c0a413832-ee41c21a.pdf"
    },
    {
        "title": "Krieltjessalade met geitenkaas en ei",
        "extra": "Met sinaasappeldressing en geroosterde hazelnoten",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Noten",
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "758 kcal",
        "ingredients": [
            "Hazelnoten",
            "Krieltjes",
            "Ei",
            "Sjalot",
            "Handsinaasappel",
            "Mesclun",
            "Rode cherrytomaten",
            "Verse geitenkaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/krieltjessalade-met-geitenkaas-en-ei-e5c6e8c5.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/krieltjessalade-met-geitenkaas-en-ei-5b17ac99ae08b56ed5552642-4335a92d.pdf"
    },
    {
        "title": "Parelcouscous met krokante kikkererwten",
        "extra": "Met geroosterde groenten en feta",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Makkelijk",
        "kcal": "899 kcal",
        "ingredients": [
            "HAK kikkererwten",
            "Courgette",
            "Pruimtomaat",
            "Bosui",
            "Knoflookteen",
            "Citroen",
            "Verse tijm",
            "Paprikapoeder",
            "Parelcouscous",
            "Feta"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscous-met-krokante-kikkererwten-8a18cd1a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscous-met-krokante-kikkererwten-5b17c33bae08b502710efc32-6f63c99c.pdf"
    },
    {
        "title": "Italiaanse minestronesoep",
        "extra": "Met beestenboelpasta en knapperig kaas-ciabatta",
        "labels": [
            "Caloriebewust",
            "Familie"
        ],
        "allergens": [
            "Selderij",
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "699 kcal",
        "ingredients": [
            "Knoflookteen",
            "Pruimtomaat",
            "Vers basilicum",
            "Verse tijm",
            "Italiaanse groentemix",
            "Tomatenpuree",
            "Farfalle",
            "Witte ciabatta",
            "Geraspte belegen kaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/italiaanse-minestronesoep-aaea6948.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/italiaanse-minestronesoep-5b17cea130006c73ef7e4712-0fc6ca05.pdf"
    },
    {
        "title": "Brandt & Levie-varkensworst met patatas bravas",
        "extra": "Met geroosterde bospeen en a\u00efoli",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Amandelnoten",
            "Eieren",
            "Mosterd",
            "Gluten"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "872 kcal",
        "ingredients": [
            "Wortel",
            "Paprikapoeder",
            "Vastkokende aardappelen",
            "Varkensworst met peterselie en knoflook",
            "Amandelschaafsel",
            "Verse krulpeterselie",
            "Aioli"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/brandt-levie-varkensworst-met-patatas-bravas-31d10683.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/brandt-levie-varkensworst-met-patatas-bravas-5b17d5ce30006c79ff044942-827fec9f.pdf"
    },
    {
        "title": "Quesadilla met groenten en cheddar",
        "extra": "Met limoenmayonaise en salade",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Eieren",
            "Mosterd"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "955 kcal",
        "ingredients": [
            "Chinese groentemix",
            "Witte tortilla",
            "Geraspte cheddar",
            "Limoen",
            "Mayonaise",
            "Gerookt paprikapoeder",
            "Gemengde sla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/quesadilla-met-groenten-en-cheddar-7cbd9394.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/quesadilla-met-groenten-en-cheddar-5b17d86cae08b514c51d16e2-00edf6c4.pdf"
    },
    {
        "title": "Warme aardappelsalade met gerookte forel",
        "extra": "Met rode paprika, citroen en dille",
        "labels": [
            "Caloriebewust"
        ],
        "allergens": [
            "Vis"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "576 kcal",
        "ingredients": [
            "Krieltjes",
            "Rode paprika",
            "Rode ui",
            "Knoflookteen",
            "Mesclun",
            "Verse dille",
            "Citroen",
            "Gerookte forelfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/warme-aardappelsalade-met-gerookte-forel-967b7358.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/warme-aardappelsalade-met-gerookte-forel-5b17deeb30006c035d428b62-9279269b.pdf"
    },
    {
        "title": "Kipfilet met notenkorstje",
        "extra": "Met fettuccine en salade caprese",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Noten",
            "Gluten",
            "Melk (inclusief lactose)",
            "Selderij"
        ],
        "duration": "40 minuten",
        "difficulty": "Moeilijk",
        "kcal": "1109 kcal",
        "ingredients": [
            "Hazelnoten-walnotenmix",
            "Knoflookteen",
            "Kipfilet",
            "Fettuccine",
            "Pruimtomaat",
            "Mini-romatomaten",
            "Buffelmozzarella",
            "Vers basilicum",
            "Olijf-kappertjesmix",
            "Zongedroogde tomaten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kipfilet-met-notenkorstje-b9baa6ad.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kipfilet-met-notenkorstje-5b17e105ae08b51bf64847a2-0f799a0f.pdf"
    },
    {
        "title": "Gebakken kabeljauwfilet met botersaus",
        "extra": "Met zoete parelcouscous, peen en tomaat",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "682 kcal",
        "ingredients": [
            "Wortel",
            "Rode cherrytomaten",
            "Verse bladpeterselie",
            "Tuinkers",
            "Parelcouscous",
            "Rozijnen",
            "Kabeljauwfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-kabeljauwfilet-met-botersaus-1f0b861f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-kabeljauwfilet-met-botersaus-5b20c791ae08b53ff9567362-7fc2535c.pdf"
    },
    {
        "title": "Kipfilethaasjes met kleurige salade",
        "extra": "Met grana-padanodressing en gebakken aardappeltjes",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "828 kcal",
        "ingredients": [
            "Roseval aardappelen",
            "Geraspte grana padano",
            "Mesclun",
            "Rode ui",
            "Gele paprika",
            "Gedroogde oregano",
            "Kipfilethaasjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kipfilethaasjes-met-kleurige-salade-7291492e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kipfilethaasjes-met-kleurige-salade-5b20ca4bae08b541f76f0d12-70144488.pdf"
    },
    {
        "title": "Gele rijst met spinazie-kokoscurry",
        "extra": "Met tomaat, cashewnoten en een spiegelei",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Pinda's",
            "Noten",
            "Selderij",
            "Mosterd",
            "Eieren"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "849 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Tomaat",
            "Gemalen kurkuma",
            "Pandanrijst",
            "Cashewnoten",
            "Kokosrasp",
            "Kerriepoeder",
            "Kokosmelk",
            "Ei",
            "Spinazie"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gele-rijst-met-spinazie-kokoscurry-7df8f337.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gele-rijst-met-spinazie-kokoscurry-5b20ce9830006c2d0342b531-a318d4c0.pdf"
    },
    {
        "title": "Garnalen met gember-limoendressing",
        "extra": "Met gewokte broccoli, rijst en pinda's",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Pinda's",
            "Gluten",
            "Soja",
            "Schaaldieren"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "732 kcal",
        "ingredients": [
            "Pandanrijst",
            "Champignons",
            "Verse gember",
            "Limoen",
            "Gezouten pinda's",
            "Knoflookteen",
            "Bosui",
            "Sojasaus",
            "Garnalen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/garnalen-met-gember-limoendressing-ce7eef25.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/garnalen-met-gember-limoendressing-5b20d3f6ae08b548b81975d4-f79e967b.pdf"
    },
    {
        "title": "Krieltjes met gekruid rundergehakt",
        "extra": "Met groenten en rode pesto",
        "labels": [
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "822 kcal",
        "ingredients": [
            "Krieltjes",
            "Rundergehakt met k\u00f6ftekruiden",
            "Rode pesto",
            "Groentemix van kastanjechampignons, prei, broccoli en courgette",
            "Verse krulpeterselie"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/krieltjes-met-gekruid-rundergehakt-295b8b7b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/krieltjes-met-gekruid-rundergehakt-5b20ddba30006c37ca1ef122-6a92fcbb.pdf"
    },
    {
        "title": "Orzo met romatomaten en gruy\u00e8re",
        "extra": "Met courgette, pijnboompitten en rozemarijn",
        "labels": [
            "Caloriebewust",
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "588 kcal",
        "ingredients": [
            "Sjalot",
            "Knoflookteen",
            "Courgette",
            "Mini-romatomaten",
            "Verse rozemarijn",
            "Orzo",
            "Pijnboompitten",
            "Geraspte gruy\u00e8re"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/orzo-met-romatomaten-en-gruyere-21638a51.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/orzo-met-romatomaten-en-gruyere-5b20e161ae08b553380b6542-93eb7263.pdf"
    },
    {
        "title": "Couscous met halloumi en gebakken rode paprika",
        "extra": "Met limoen-knoflookolie en yoghurt",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Noten",
            "Melk (inclusief lactose)",
            "Gluten"
        ],
        "duration": "35 minuten",
        "difficulty": "Moeilijk",
        "kcal": "870 kcal",
        "ingredients": [
            "Amandelen",
            "Knoflookteen",
            "Verse koriander",
            "Limoen",
            "Halloumi",
            "Rode paprika",
            "Volkoren couscous",
            "Rozijnen",
            "Volle yoghurt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/couscous-met-halloumi-en-gebakken-rode-paprika-5af80e7a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/couscous-met-halloumi-en-gebakken-rode-paprika-5b20e406ae08b555127fe361-c6480089.pdf"
    },
    {
        "title": "Geroosterde tomaat-paprikasoep",
        "extra": "Met oventosti's en zure room",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Sesamzaad",
            "Tarwe",
            "Rogge",
            "Gerst",
            "Melk (inclusief lactose)",
            "Selderij"
        ],
        "duration": "50 minuten",
        "difficulty": "Makkelijk",
        "kcal": "834 kcal",
        "ingredients": [
            "Tomaat",
            "Rode paprika",
            "Rode ui",
            "Knoflookteen",
            "Meerzadenbaguette",
            "Geraspte belegen kaas",
            "Biologische zure room",
            "Vers basilicum"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/geroosterde-tomaat-paprikasoep-69678c35.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/geroosterde-tomaat-paprikasoep-5b20f7af30006c4b655a1232-16de1ea1.pdf"
    },
    {
        "title": "Ciabatta met varkenshaaspuntjes in kruidenboter",
        "extra": "En little gem met rijke dressing",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Pinda's",
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "541 kcal",
        "ingredients": [
            "Knoflookteen",
            "Limoen",
            "Rode ui",
            "Tomaat",
            "Pinda's",
            "Mesclun",
            "Verse bieslook",
            "Verse krulpeterselie",
            "Witte ciabatta",
            "Spekblokjes",
            "Varkenshaaspuntjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/little-gem-met-rijke-dressing-en-spekjes-a4f66e65.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/little-gem-met-rijke-dressing-en-spekjes-5b20fbb930006c4e8e27a1d2-6c15a627.pdf"
    },
    {
        "title": "Conchiglie met rode pesto en feta",
        "extra": "Met rode paprika, veldsla en basilicum",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Selderij",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "855 kcal",
        "ingredients": [
            "Sjalot",
            "Rode paprika",
            "Tomaat",
            "Conchiglie",
            "Feta",
            "Vers basilicum",
            "Rode pesto",
            "Veldsla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/conchiglie-met-rode-pesto-en-feta-08ffba23.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/conchiglie-met-rode-pesto-en-feta-5b20ffacae08b569fe3689e2-bad47772.pdf"
    },
    {
        "title": "Opgerolde tortilla's met kalkoenshoarma",
        "extra": "Met Turkse groene pepers, ijsbergsla en a\u00efoli",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "950 kcal",
        "ingredients": [
            "Turkse groene peper",
            "Rode paprika",
            "Rode ui",
            "Kalkoenshoarma",
            "IJsbergsla",
            "Witte tortilla",
            "Aioli"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/opgerold-platbrood-met-kalkoenshoarma-6215a692.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/opgerold-platbrood-met-kalkoenshoarma-5b21050330006c56297cb012-e3f47da9.pdf"
    },
    {
        "title": "Aardappelkoekjes met warmgerookte zalm",
        "extra": "Met zure room, dille en koolrabi-appelsalade",
        "labels": ["Familie"],
        "allergens": [
            "Vis",
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "55 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "1031 kcal",
        "ingredients": [
            "Koolrabi",
            "Appel",
            "Radijs",
            "Citroen",
            "Verse dille",
            "Verse bieslook",
            "Warmgerookte zalm",
            "Kruimige aardappelen",
            "Sjalot",
            "Ei",
            "Biologische zure room"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/aardappelkoekjes-met-warmgerookte-zalm-099ea20d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/aardappelkoekjes-met-warmgerookte-zalm-5b212b31ae08b50eb97e38b2-7f9cb405.pdf"
    },
    {
        "title": "Pittige kokossoep met schelvis",
        "extra": "Met udonnoedels, snijbonen en peen",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Vis"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "572 kcal",
        "ingredients": [
            "Knoflookteen",
            "Snijbonen",
            "Wortel",
            "Kokosmelk",
            "Gemalen citroengras",
            "Sojasaus",
            "Schelvisfilet",
            "Verse udonnoedels"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pittige-kokossoep-met-schelvis-faef0d2d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pittige-kokossoep-met-schelvis-5b2912a730006c287336e482-b3a09059.pdf"
    },
    {
        "title": "Aardappelgratin met rundervink",
        "extra": "Met zoete sinaasappelworteltjes met bladpeterselie",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "923 kcal",
        "ingredients": [
            "Aardappelschijfjes",
            "Ui",
            "Wortel",
            "Perssinaasappel",
            "Verse bladpeterselie",
            "Kookroom",
            "Geraspte belegen kaas",
            "Rundervink"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/aardappelgratin-met-rundervink-648612a7.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/aardappelgratin-met-rundervink-5b2b642030006c7aaa75a1b2-a3f820f5.pdf"
    },
    {
        "title": "Risotto met courgette en buffelmozzarella",
        "extra": "Met bleekselderij en bladpeterselie",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Selderij",
            "Melk (inclusief lactose)",
            "Eieren"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "771 kcal",
        "ingredients": [
            "Rode ui",
            "Knoflookteen",
            "Bleekselderij",
            "Rode peper",
            "Verse bladpeterselie",
            "Courgette",
            "Risottorijst",
            "Buffelmozzarella",
            "Geraspte grana padano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/risotto-met-cou-rgette-en-buffelmozzarella-04074db9.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/risotto-met-cou-rgette-en-buffelmozzarella-5b2b66f430006c7d0d7c6fb2-31a62721.pdf"
    },
    {
        "title": "Vispakketje van kabeljauw met venkel en dille",
        "extra": "Met gebakken aardappelen en komkommer met sinaasappeldressing",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "620 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Gedroogde tijm",
            "Venkel",
            "Handsinaasappel",
            "Komkommer",
            "Kabeljauwfilet",
            "Verse dille"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/vispakketje-van-kabeljauw-met-venkel-en-dille-cc8817a1.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/vispakketje-van-kabeljauw-met-venkel-en-dille-5b2b6b09ae08b51f85191c32-3c2b3511.pdf"
    },
    {
        "title": "Rigatoni met kipfilet en sperziebonen",
        "extra": "Met groene pesto",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "820 kcal",
        "ingredients": [
            "Sperziebonen",
            "Rigatoni",
            "Knoflookteen",
            "Kipfiletstukjes",
            "Groene pesto"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rigatoni-met-kipfilet-en-sperziebonen-4e794ed3.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rigatoni-met-kipfilet-en-sperziebonen-5b2b6d2c30006c031c35be12-2c0c657d.pdf"
    },
    {
        "title": "Platbroodpizza met gegrilde courgette",
        "extra": "Met kastanjechampignons en twee soorten kaas",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "849 kcal",
        "ingredients": [
            "Knoflookteen",
            "Courgette",
            "Kastanjechampignons",
            "Passata",
            "Wit platbrood",
            "Gedroogde rozemarijn",
            "Geraspte belegen kaas",
            "Geraspte oude kaas",
            "Walnoten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/platbroodpizza-met-gegrilde-courgette-ea1099ec.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/platbroodpizza-met-gegrilde-courgette-5b2b6fb4ae08b522bd202f12-a0abcee9.pdf"
    },
    {
        "title": "Bulgur-linzensalade met blauwe kaas",
        "extra": "Met avocado en geschaafde amandelen",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Amandelnoten",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "707 kcal",
        "ingredients": [
            "Groene linzen",
            "Bulgur",
            "Rode ui",
            "Amandelschaafsel",
            "Avocado",
            "Mesclun",
            "Danablu"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/bulgur-linzensalade-met-blauwe-kaas-9e1e31e0.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/bulgur-linzensalade-met-blauwe-kaas-5b2b7304ae08b525b3207702-d0e74b6d.pdf"
    },
    {
        "title": "Ras el hanout-quinoa met feta uit de oven",
        "extra": "Met knapperige sperziebonen en geroosterde tomaatjes",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "881 kcal",
        "ingredients": [
            "Rode ui",
            "Knoflookteen",
            "Sperziebonen",
            "Feta",
            "Gemalen komijnzaad",
            "Rode cherrytomaten",
            "Quinoa",
            "Ras el hanout",
            "Verse munt",
            "Pijnboompitten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/ras-el-hanout-quinoa-met-feta-uit-de-oven-8e4e6756.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/ras-el-hanout-quinoa-met-feta-uit-de-oven-5b2b8b5fae08b53735469232-4ac49fa9.pdf"
    },
    {
        "title": "Koude aardappelsalade met kipdrumsticks",
        "extra": "Met gebakken paprika en bosui",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "758 kcal",
        "ingredients": [
            "Gemarineerde kipdrumsticks",
            "Vastkokende aardappelen",
            "Verse bieslook",
            "Rode paprika",
            "Bosui",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/koude-aardappelsalade-met-kipdrumsticks-8db4c2b1.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/koude-aardappelsalade-met-kipdrumsticks-5b2b963e30006c21c4075b92-deef42a2.pdf"
    },
    {
        "title": "Nasi Goreng met spiegelei",
        "extra": "Met limoen, pinda's en koriander",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Eieren",
            "Gluten",
            "Soja",
            "Pinda's"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "788 kcal",
        "ingredients": [
            "Basmatirijst",
            "Hollandse groentemix",
            "Limoen",
            "Bosui",
            "Verse koriander",
            "Gemalen korianderzaad",
            "Gemalen kurkuma",
            "Ei",
            "Sojasaus",
            "Gezouten pinda's"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/nasi-goreng-met-spiegelei-36d76512.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/nasi-goreng-met-spiegelei-5b2b993030006c23a9458e42-42ec0e8a.pdf"
    },
    {
        "title": "Griekse salade met kippendij",
        "extra": "Met parelcouscous en feta",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "626 kcal",
        "ingredients": [
            "Parelcouscous",
            "Kippendijreepjes met tuinkruiden",
            "Romaine sla",
            "Pruimtomaat",
            "Komkommer",
            "Rode ui",
            "Feta",
            "Citroen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/griekse-salade-met-kippendij-23ee0fbe.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/griekse-salade-met-kippendij-5b2b9f44ae08b5466c192f42-84652e18.pdf"
    },
    {
        "title": "Varkenshaas met zoet-pittige saus",
        "extra": "Met pitten-kruidenrijst en bimi met paksoi in vissaus",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Sesamzaad",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "55 minuten",
        "difficulty": "Moeilijk",
        "kcal": "885 kcal",
        "ingredients": [
            "Varkenshaas",
            "Rode peper",
            "Knoflookteen",
            "Paksoi",
            "Verse gember",
            "Perssinaasappel",
            "Sojasaus",
            "Steranijs",
            "Sesamzaad",
            "Zonnebloem-pompoenpittenmix",
            "Zilvervliesrijst",
            "Broccolini",
            "Vissaus",
            "Verse koriander",
            "Verse munt",
            "Bosui",
            "Limoen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/varkenshaas-met-zoet-pittige-saus-9c3d2c38.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/varkenshaas-met-zoet-pittige-saus-5b2ba11330006c299070d082-c0c22ecd.pdf"
    },
    {
        "title": "Mini-pitabroodjes met tonijnsalade",
        "extra": "Met zoete bospeen en kokos",
        "labels": [
            "Familie"
        ],
        "allergens": [
            "Noten",
            "Vis",
            "Eieren",
            "Mosterd",
            "Gluten"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "978 kcal",
        "ingredients": [
            "Wortel",
            "Rode ui",
            "Rode peper",
            "Limoen",
            "Hazelnoten",
            "Tonijn in olijfolie",
            "Kokosrasp",
            "Mayonaise",
            "Mini-pitabroodjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/mini-pitabroodjes-met-tonijnsalade-ae65abf6.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/mini-pitabroodjes-met-tonijnsalade-5b333ece30006c18b159da42-ff12483b.pdf"
    },
    {
        "title": "Kipgyros met kruidige tabouleh",
        "extra": "Met little gem en verse kruiden",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "588 kcal",
        "ingredients": [
            "Bulgur",
            "Ui",
            "Kipgyros",
            "Gemalen komijnzaad",
            "Pruimtomaat",
            "Citroen",
            "Verse krulpeterselie",
            "Verse munt",
            "Mesclun"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kipgyros-met-kruidige-tabouleh-c35e94b7.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kipgyros-met-kruidige-tabouleh-5b33412830006c1a52398042-fe9bf2ea.pdf"
    },
    {
        "title": "Kokos-noedelsoep met sugarsnaps",
        "extra": "Met Chinese kool en gekookt ei",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Eieren",
            "Gluten",
            "Soja"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "748 kcal",
        "ingredients": [
            "Verse gember",
            "Rode peper",
            "Chinese kool",
            "Limoen",
            "Kokosmelk",
            "Sugarsnaps",
            "Ei",
            "Gemalen citroengras",
            "Noedels",
            "Sojasaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kokos-noedelsoep-met-sugarsnaps-b5e1d26b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kokos-noedelsoep-met-sugarsnaps-5b334435ae08b538f41d0802-a57a871a.pdf"
    },
    {
        "title": "Orzo-risotto met roodbaarsfilet",
        "extra": "Met spekjes, venkel en courgette",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "749 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Venkel",
            "Courgette",
            "Spekblokjes",
            "Orzo",
            "Citroen",
            "Roodbaarsfilet met huid",
            "Gedroogde tijm"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/orzo-risotto-met-roodbaarsfilet-c525fc27.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/orzo-risotto-met-roodbaarsfilet-5b3346bfae08b53b027e4802-4a6d5db8.pdf"
    },
    {
        "title": "Krieltjessalade met mais en spekjes",
        "extra": "Met rode paprika en zoetzure sjalot",
        "labels": [
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "749 kcal",
        "ingredients": [
            "Krieltjes",
            "Sjalot",
            "Limoen",
            "Rode paprika",
            "Knoflookteen",
            "Mais",
            "Spekblokjes",
            "Mesclun",
            "Volle yoghurt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/krieltjessalade-met-mais-en-spekjes-84c34d1b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/krieltjessalade-met-mais-en-spekjes-5b334a8bae08b53deb4be782-55838244.pdf"
    },
    {
        "title": "Romige groentelasagne met spinazie",
        "extra": "Met courgette en grana padano",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Eieren",
            "Gluten"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "933 kcal",
        "ingredients": [
            "Courgette",
            "Ui",
            "Knoflookteen",
            "Verse oregano",
            "Spinazie",
            "Kookroom",
            "Geraspte grana padano",
            "Lasagnebladen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-groentelasagne-met-spinazie-0b03f25b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-groentelasagne-met-spinazie-5b334dfe30006c24a51591b2-195446fe.pdf"
    },
    {
        "title": "Gebakken rijst met spiegelei",
        "extra": "Met roerbakgroenten en cashewnoten",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Sesamzaad",
            "Pinda's",
            "Noten",
            "Eieren",
            "Gluten",
            "Soja"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "876 kcal",
        "ingredients": [
            "Zilvervliesrijst",
            "Sesamzaad",
            "Gezouten cashewnoten",
            "Wortel",
            "Sperziebonen",
            "Knoflookteen",
            "Verse gember",
            "Bosui",
            "Limoen",
            "Ei",
            "Sojasaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-rijst-met-spiegelei-14b447b1.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-rijst-met-spiegelei-5b335123ae08b542f93fe972-b8b0204e.pdf"
    },
    {
        "title": "Langzaam gegaarde aubergine",
        "extra": "Met granaatappel, feta en pittenrijst",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Sesamzaad"
        ],
        "duration": "50 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "869 kcal",
        "ingredients": [
            "Ras el hanout",
            "Aubergine",
            "Zonnebloem-pompoenpittenmix",
            "Surinaamse rijst",
            "Granaatappel",
            "Feta",
            "Verse munt",
            "Yoghurt-tahinsaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/langzaam-gegaarde-aubergine-ebe51428.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/langzaam-gegaarde-aubergine-5b3353bcae08b544b65af872-347050a5.pdf"
    },
    {
        "title": "Oosterse tomatensaus met rundergehakt",
        "extra": "Met Turkse rode peper en aardappelpuree",
        "labels": ["Familie"],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "860 kcal",
        "ingredients": [
            "Sjalot",
            "Knoflookteen",
            "Rode peper",
            "Turkse rode peper",
            "Tomaat",
            "Kruimige aardappelen",
            "Gemalen komijnzaad",
            "Passata",
            "Rundergehakt met k\u00f6ftekruiden",
            "Verse munt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/oosterse-tomatensaus-met-rundergehakt-a103325e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/oosterse-tomatensaus-met-rundergehakt-5b33579030006c2bcb3522a2-fc4df37e.pdf"
    },
    {
        "title": "Parelcouscous met kastanjechampignons",
        "extra": "Met gruy\u00e8re, rucola en walnoten",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "658 kcal",
        "ingredients": [
            "Parelcouscous",
            "Knoflookteen",
            "Kastanjechampignons",
            "Geraspte gruy\u00e8re",
            "Rucola",
            "Walnoten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscous-met-kastanjechampignons-3fd39b4a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscous-met-kastanjechampignons-5b335974ae08b54944327782-9dd5cf31.pdf"
    },
    {
        "title": "Bami met 5-kruidenkip",
        "extra": "Met roerbakgroenten en rode peper",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Soja"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "652 kcal",
        "ingredients": [
            "Rode peper",
            "Knoflookteen",
            "Gemarineerde kipfiletreepjes",
            "Groentemix",
            "Sojasaus",
            "Noedels"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/bami-met-5-kruidenkip-0c91f64d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/bami-met-5-kruidenkip-5b335bf3ae08b54b40367a42-5c4b8dcf.pdf"
    },
    {
        "title": "Salade met buffelmozzarella en serranoham",
        "extra": "Met geschaafde venkel en geroosterde druiven",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten",
            "Noten",
            "Eieren",
            "Lupine",
            "Mosterd",
            "Sesamzaad",
            "Soja"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "1128 kcal",
        "ingredients": [
            "Venkel",
            "Verse munt",
            "Buffelmozzarella",
            "Blauwe druiven, pitloos",
            "Kleine baguette",
            "Walnoten",
            "Kappertjes",
            "Gemengde sla",
            "Serranoham"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/salade-met-buffelmozzarella-en-serranoham-625aebc4.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/salade-met-buffelmozzarella-en-serranoham-5b33709630006c3ebc1a4072-4eb01410.pdf"
    },
    {
        "title": "Parelcouscous met garnalen en courgette",
        "extra": "Met verse tijm, rode peper en citroen",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Schaaldieren"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "587 kcal",
        "ingredients": [
            "Rode ui",
            "Knoflookteen",
            "Rode peper",
            "Citroen",
            "Verse tijm",
            "Courgette",
            "Parelcouscous",
            "Garnalen",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscous-met-garnalen-en-courgette-ff205fbf.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscous-met-garnalen-en-courgette-5b3c863230006c7e0554c7a2-b0627da6.pdf"
    },
    {
        "title": "Patatas bravas met rundermerguezworstjes",
        "extra": "Met geroosterde Turkse pepers en a\u00efoli",
        "labels": ["Familie"],
        "allergens": [
            "Soja",
            "Mosterd",
            "Selderij",
            "Eieren",
            "Gluten"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "845 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Paprikapoeder",
            "Turkse groene peper",
            "Knoflookteen",
            "Rode peper",
            "Passata",
            "Rundermerguez",
            "Aioli"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/patatas-bravas-met-rundermerguezworstjes-6d2c6528.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/patatas-bravas-met-rundermerguezworstjes-5b3c935aae08b5254c15b9e2-1c818301.pdf"
    },
    {
        "title": "Tomatensoep met paprikapesto-bruschette",
        "extra": "Met peen en grana padano",
        "labels": [
            "Caloriebewust",
            "Chef's Choice",
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Cashewnoten",
            "Eieren"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "587 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Wortel",
            "Pruimtomaat",
            "Gedroogde tijm",
            "Paprikapoeder",
            "Tomatenpuree",
            "Bruine ciabatta",
            "Paprikapesto",
            "Geraspte grana padano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/tomatensoep-met-paprikapesto-bruschette-9962eaa2.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/tomatensoep-met-paprikapesto-bruschette-5b3c97ed30006c0dbb15e1b2-79139e1e.pdf"
    },
    {
        "title": "Zeebaars met aardappelen in tomatensaus",
        "extra": "Met cherrytomaten en leccino-olijven",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "597 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Knoflookteen",
            "Sjalot",
            "Tomaat",
            "Rode cherrytomaten",
            "Passata",
            "Leccino olijven",
            "Zeebaarsfilet met huid"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/zeebaars-met-aardappelen-in-tomatensaus-f89b3d33.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/zeebaars-met-aardappelen-in-tomatensaus-5b3ca98cae08b5353d73a801-73ec30a0.pdf"
    },
    {
        "title": "Romige farfalle met gekruid kipgehakt",
        "extra": "Met venkel, spinazie en belegen kaas",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "813 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Rode peper",
            "Venkel",
            "Farfalle",
            "Kipgehakt met Italiaanse kruiden",
            "Spinazie",
            "Biologische cr\u00e8me fra\u00eeche",
            "Geraspte belegen kaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-farfalle-met-gekruid-kipgehakt-ec7df612.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-farfalle-met-gekruid-kipgehakt-5b3cb21aae08b53b92258281-41a06bf3.pdf"
    },
    {
        "title": "Spinazie-bataatcurry met spiegelei en yoghurt",
        "extra": "Met rode peper en verse koriander",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Selderij",
            "Mosterd",
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "648 kcal",
        "ingredients": [
            "Basmatirijst",
            "Ui",
            "Knoflookteen",
            "Rode peper",
            "Zoete aardappel",
            "Kokosmelk",
            "Kerriepoeder",
            "Verse koriander",
            "Ei",
            "Spinazie",
            "Naturel yoghurt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/spinazie-bataatcurry-met-spiegelei-en-yoghurt-77fcbbd9.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/spinazie-bataatcurry-met-spiegelei-en-yoghurt-5b3cbe5630006c28d7199f82-7ac5e7ed.pdf"
    },
    {
        "title": "Risotto met buffelmozzarella",
        "extra": "Met tomaat, basilicumolie en pecorino",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Selderij",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Moeilijk",
        "kcal": "680 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Bleekselderij",
            "Pruimtomaat",
            "Risottorijst",
            "Rode peper",
            "Basilicumcr\u00e8me",
            "Buffelmozzarella",
            "Geraspte pecorino"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/risotto-met-buffelmozzarella-727e99b6.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/risotto-met-buffelmozzarella-5b3cc40730006c2d5471c271-49d5b10e.pdf"
    },
    {
        "title": "Tortilla's met falafel & feta",
        "extra": "Met hummus-yoghurtsaus en verse munt",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Sesamzaad",
            "Sulfiet",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "824 kcal",
        "ingredients": [
            "Courgette",
            "Tomaat",
            "Falafel met tomaat",
            "Witte tortilla",
            "Hummus",
            "Volle yoghurt",
            "Feta",
            "Verse munt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/knapperig-platbrood-met-falafel-feta-fd86a9ce.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/knapperig-platbrood-met-falafel-feta-5b3cc9e930006c313222b8c2-7458f7a2.pdf"
    },
    {
        "title": "Gekonfijte kippendij met Franse linzensalade",
        "extra": "Met rucola en pruimtomaat",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Noten",
            "Sulfiet"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "812 kcal",
        "ingredients": [
            "Groene linzen",
            "Pruimtomaat",
            "Gedroogde tijm",
            "Gekonfijte kippendij",
            "Sjalot",
            "Hazelnoten",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gekonfijte-kippendij-met-franse-linzensalade-e4d7565c.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gekonfijte-kippendij-met-franse-linzensalade-5b3ccff7ae08b5524f143c32-e0da3b1e.pdf"
    },
    {
        "title": "Linguine met courgettespaghetti en olijvencr\u00e8me",
        "extra": "Met geitenkaas en babyspinazie",
        "labels": [
            "Eet mij eerst",
            "Veggie",
            "Caloriebewust"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Mosterd",
            "Eieren"
        ],
        "duration": "20 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "729 kcal",
        "ingredients": [
            "Linguine",
            "Knoflookteen",
            "Courgette-spaghetti",
            "Babyspinazie",
            "Olijvencr\u00e8me",
            "Geitenkaas, geraspt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/linguine-met-courgettespaghetti-en-olijvencreme-9f129983.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/linguine-met-courgettespaghetti-en-olijvencreme-5b3cd5fe30006c3b96130bb2-25424c7f.pdf"
    },
    {
        "title": "Japanse ramen met gebakken kabeljauw",
        "extra": "Met udonnoedels, spitskool en broccoli",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Vis"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "503 kcal",
        "ingredients": [
            "Verse gember",
            "Rode peper",
            "Wortel",
            "Champignons",
            "Spitskool en broccoli",
            "Verse udonnoedels",
            "Sojasaus",
            "Kabeljauwfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/japanse-ramen-met-gebakken-kabeljauw-e74522f8.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/japanse-ramen-met-gebakken-kabeljauw-5b3cd9bf30006c3f4e14aa92-5bf7037b.pdf"
    },
    {
        "title": "Varkenshaas met zomergratin",
        "extra": "Van aardappel en cherrytomaten met knapperige serranoham",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Eieren",
            "Lupine",
            "Mosterd",
            "Sesamzaad",
            "Soja",
            "Selderij"
        ],
        "duration": "50 minuten",
        "difficulty": "Moeilijk",
        "kcal": "938 kcal",
        "ingredients": [
            "Aardappelen",
            "Knoflookteen",
            "Slagroom",
            "Rode cherrytomaten",
            "Kappertjes",
            "Serranoham",
            "Varkenshaasmedaillon",
            "Vers basilicum"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/varkenshaas-met-zomergratin-ca70669f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/varkenshaas-met-zomergratin-5b3cdfcc30006c43f767f622-ce0a1eec.pdf"
    },
    {
        "title": "Lauwwarme salade met gerookte forel",
        "extra": "Met aardappel, boontjes, appel en tuinkers",
        "labels": [
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Vis"
        ],
        "duration": "30 minuten",
        "difficulty": "Makkelijk",
        "kcal": "657 kcal",
        "ingredients": [
            "Roseval aardappelen",
            "Sperziebonen",
            "Appel",
            "Tuinkers",
            "Gerookte forelfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/lauwwarme-salade-met-gerookte-forle-9c8fd29f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/lauwwarme-salade-met-gerookte-forle-5b45b912ae08b528cc72b982-c88f88c6.pdf"
    },
    {
        "title": "Kip-cheeseburgers met zoete rode ui",
        "extra": "Met gebakken aardappeltjes en frisse salade",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Sesamzaad",
            "Lupine"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "958 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Rode ui",
            "Vleestomaat",
            "Botersla",
            "Gekruide kipburger",
            "Mini-hamburgerbol",
            "Geraspte cheddar"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kip-cheeseburgers-met-zoete-rode-ui-2242e799.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kip-cheeseburgers-met-zoete-rode-ui-5b45c112ae08b52dd14b0c22-5435ec28.pdf"
    },
    {
        "title": "Orzo met broccoli, pecorino en pijnboompitten",
        "extra": "Met verse tijm",
        "labels": [
            "Caloriebewust",
            "Chef's Choice",
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "648 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Champignons",
            "Verse tijm",
            "Pijnboompitten",
            "Orzo",
            "Geraspte pecorino"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/orzo-met-broccoli-pecorino-en-pijnboompitten-eba7b683.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/orzo-met-broccoli-pecorino-en-pijnboompitten-5b45c640ae08b531d5034f22-676304d1.pdf"
    },
    {
        "title": "Linguine met cherrytomaten en ansjovisboter",
        "extra": "Met gezouten amandelen, verse bladpeterselie en dragon",
        "labels": ["Familie"],
        "allergens": [
            "Vis",
            "Gluten",
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Makkelijk",
        "kcal": "792 kcal",
        "ingredients": [
            "Ansjovis",
            "Knoflookteen",
            "Rode cherrytomaten",
            "Linguine",
            "Verse bladpeterselie",
            "Verse dragon",
            "Amandelen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/linguine-met-ansjovisboter-9b7e41c5.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/linguine-met-ansjovisboter-5b45cc93ae08b53638605012-a3bc1815.pdf"
    },
    {
        "title": "Koreaanse biefstukwraps met groenten",
        "extra": "Met zoetzure komkommer",
        "labels": [
            "Eet mij eerst",
            "Caloriebewust",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Soja"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "645 kcal",
        "ingredients": [
            "Knoflookteen",
            "Verse gember",
            "Rode peper",
            "Sojasaus",
            "Biefstukreepjes",
            "Komkommer",
            "Verse koriander",
            "Limoen",
            "Witte tortilla",
            "Peen & kool"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/koreaanse-biefstukwraps-met-groenten-69a4b596.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/koreaanse-biefstukwraps-met-groenten-5b45d577ae08b53cd81690e2-314842ff.pdf"
    },
    {
        "title": "Rucolastamppot met kruidenkaas",
        "extra": "Met courgette, tomaat en cr\u00e8me fra\u00eeche",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Selderij",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "771 kcal",
        "ingredients": [
            "Kruimige aardappelen",
            "Courgette",
            "Rode cherrytomaten",
            "Rucola",
            "Vers basilicum",
            "Zonnebloempitten",
            "Biologische cr\u00e8me fra\u00eeche",
            "Kruidenkaasblokjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rucolastamppot-met-kruidenkaas-fed74882.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rucolastamppot-met-kruidenkaas-5b45e502ae08b5485609b9f2-c28fb9b2.pdf"
    },
    {
        "title": "Aubergine uit de oven met rigatoni",
        "extra": "Met tomaat, oude kaas en salade",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten",
            "Selderij"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "743 kcal",
        "ingredients": [
            "Aubergine",
            "Tomaat",
            "Knoflookteen",
            "Passata",
            "Geraspte oude kaas",
            "Rigatoni",
            "Rucolamelange",
            "Vers basilicum"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/aubergine-uit-de-oven-met-rigatoni-103a529d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/aubergine-uit-de-oven-met-rigatoni-5b45f193ae08b5516b4bd692-11cb3e40.pdf"
    },
    {
        "title": "Indiase dahl met bataat en kokosmelk",
        "extra": "Met pompoenpittensalsa en kruidig naanbrood",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Selderij",
            "Mosterd",
            "Gluten",
            "Tarwe",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Makkelijk",
        "kcal": "886 kcal",
        "ingredients": [
            "Ui",
            "Verse gember",
            "Zoete aardappel",
            "Kerriepoeder",
            "Tomatenpuree",
            "Kokosmelk",
            "Rode splitlinzen",
            "Pompoenpitten",
            "Verse koriander",
            "Limoen",
            "Naanbrood",
            "Spinazie"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/indiase-dahl-met-bataat-en-kokosmelk-84d63645.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/indiase-dahl-met-bataat-en-kokosmelk-5b4619f530006c52aa01c2d4-1175f942.pdf"
    },
    {
        "title": "Licht pittige bouillon met gehaktballetjes",
        "extra": "Met Aziatische groenten en noedels",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Soja"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "606 kcal",
        "ingredients": [
            "Varkensworst met laoskruiden",
            "Knoflookteen",
            "Rode peper",
            "Verse gember",
            "Broccolini",
            "Paksoi",
            "Sojasaus",
            "Verse udonnoedels"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/licht-pittige-bouillon-met-gehaktballetjes-7aa43dce.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/licht-pittige-bouillon-met-gehaktballetjes-5b470ef9ae08b536af406b12-6211cda4.pdf"
    },
    {
        "title": "Gebakken gnocchi met groene pesto",
        "extra": "Met courgettelinten, rucola en cherrytomaten",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Eieren",
            "Sulfiet",
            "Tarwe",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "857 kcal",
        "ingredients": [
            "Courgette",
            "Rode cherrytomaten",
            "Gnocchi",
            "Groene pesto",
            "Rucola",
            "Provolonevlokken"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-gnocchi-met-groene-pesto-ce35273b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-gnocchi-met-groene-pesto-5b4713a730006c1d2c703222-b3c01abc.pdf"
    },
    {
        "title": "Kipshoarma met couscoussalade",
        "extra": "Met pitabroodjes en knoflooksaus",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Eieren",
            "Mosterd",
            "Gluten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "909 kcal",
        "ingredients": [
            "Knoflookteen",
            "Mayonaise",
            "Volkoren couscous",
            "Kipshoarma",
            "Volkoren pitabroodje",
            "Tomaat",
            "Komkommer",
            "Verse munt",
            "Verse bladpeterselie",
            "IJsbergsla met peen en witte kool"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kipshoarma-met-couscoussalade-707d3252.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kipshoarma-met-couscoussalade-5b47196830006c20cb458444-091ee72e.pdf"
    },
    {
        "title": "Garnalen in verse tomatensaus",
        "extra": "Met romige aardappelpuree en haricots verts",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Schaaldieren",
            "Melk (inclusief lactose)",
            "Vis"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "788 kcal",
        "ingredients": [
            "Kruimige aardappelen",
            "Haricots verts",
            "Sjalot",
            "Mini-romatomaten",
            "Verse bladpeterselie",
            "Citroen",
            "Garnalen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/garnalen-in-verse-tomatensaus-2589974c.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/garnalen-in-verse-tomatensaus-5b4730fcae08b54f2979dc42-7a628da9.pdf"
    },
    {
        "title": "Conchiglie in tonijn-pestosaus",
        "extra": "Met cherrytomaten en gemende sla",
        "labels": [
            "Chef's Choice",
            "Familie"
        ],
        "allergens": [
            "Vis",
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "836 kcal",
        "ingredients": [
            "Ui",
            "Rode cherrytomaten",
            "Tonijn in olijfolie",
            "Conchiglie",
            "Mesclun",
            "Groene pesto",
            "Pijnboompitten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/conchiglie-in-tonijn-pestosaus-e4e89758.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/conchiglie-in-tonijn-pestosaus-5b4eef7a30006c21ed4c66d2-5049b98b.pdf"
    },
    {
        "title": "Andijviestamppot met gehaktballetjes",
        "extra": "Met zongedroogde tomaten en oude kaas",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "975 kcal",
        "ingredients": [
            "Kruimige aardappelen",
            "Rode ui",
            "Zongedroogde tomaten",
            "Half-om-half gehaktballetjes met Thaise kruiden",
            "Gesneden andijvie",
            "Oude-kaasblokjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/andijviestamppot-met-gehaktballetjes-657bd24e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/andijviestamppot-met-gehaktballetjes-5b4ef96530006c295b29b702-4a4b0857.pdf"
    },
    {
        "title": "Gele rijst met spinazie-kokoscurry",
        "extra": "Met tomaat, cashewnoten en een spiegelei",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Pinda's",
            "Noten",
            "Selderij",
            "Mosterd",
            "Eieren"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "847 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Tomaat",
            "Gemalen kurkuma",
            "Pandanrijst",
            "Cashewnoten",
            "Kokosrasp",
            "Kerriepoeder",
            "Kokosmelk",
            "Ei",
            "Spinazie"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gele-rijst-met-spinazie-kokoscurry-7a71627e.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gele-rijst-met-spinazie-kokoscurry-5b4f08f5ae08b55279334a01-d4a5017a.pdf"
    },
    {
        "title": "Zalm in zoet-pittige marinade",
        "extra": "Met knoflookbroccoli met zilvervliesrijst",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Vis"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "830 kcal",
        "ingredients": [
            "Knoflookteen",
            "Verse gember",
            "Rode peper",
            "Champignons",
            "Sojasaus",
            "Zalmfilet met huid",
            "Zilvervliesrijst"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/zalm-in-zoet-pittige-mariande-20c51914.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/zalm-in-zoet-pittige-mariande-5b4f188a30006c3fcd7227c2-553389a4.pdf"
    },
    {
        "title": "Bloemkoolquinoa met gekruide kippendijreepjes",
        "extra": "Met witte courgette en pittige yoghurtsaus",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "824 kcal",
        "ingredients": [
            "Quinoa",
            "Rode peper",
            "Witte courgette",
            "Rode ui",
            "Verse munt",
            "Bloemkoolrijst",
            "Kippendijreepjes met ras el hanout",
            "Limoen",
            "Volle yoghurt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/bloemkoolquinoa-met-ras-el-hanoutkippendijreepjes-240f6d31.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/bloemkoolquinoa-met-ras-el-hanoutkippendijreepjes-5b4f275930006c4a874e8b22-5cd62f2d.pdf"
    },
    {
        "title": "Broccoli-courgettesoep met olijvenciabatta",
        "extra": "Met courgettelinten en pompoenpitten",
        "labels": [
            "Caloriebewust",
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "621 kcal",
        "ingredients": [
            "Ui",
            "Kruimige aardappelen",
            "Champignons",
            "Gele courgette",
            "Pompoenpitten",
            "Olijvenciabatta",
            "Biologische cr\u00e8me fra\u00eeche"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/broccoli-courgettesoep-met-olijvenciabatta-5efdd493.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/broccoli-courgettesoep-met-olijvenciabatta-5b4f30dcae08b56fcb5b9f32-e2012217.pdf"
    },
    {
        "title": "Parelcouscous met gebakken ui",
        "extra": "Met kastanjechampignons met citroenricotta",
        "labels": [
            "Veggie",
            "Chef's Choice"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "709 kcal",
        "ingredients": [
            "Ui",
            "Kastanjechampignons",
            "Citroen",
            "Verse kervel",
            "Verse dragon",
            "Parelcouscous",
            "Ricotta",
            "Geraspte pecorino"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscous-met-gebakken-sjalotjes-8c9e1f2d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscous-met-gebakken-sjalotjes-5b4f367830006c5658354882-18bd0941.pdf"
    },
    {
        "title": "Feta uit de oven met orzo",
        "extra": "Met tomatentapenade en geroosterde gele paprika",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Sulfiet",
            "Selderij"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "860 kcal",
        "ingredients": [
            "Gele paprika",
            "Courgette",
            "Orzo",
            "Feta",
            "Tomatentapenade",
            "Vers basilicum"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/feta-uit-de-oven-met-orzo-32e456f9.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/feta-uit-de-oven-met-orzo-5b4f3e18ae08b579b063aba2-519f9f1d.pdf"
    },
    {
        "title": "Vietnamees kipgehakt in little gem",
        "extra": "Met noedels, peen en verse koriander",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis",
            "Gluten"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "650 kcal",
        "ingredients": [
            "Rode ui",
            "Knoflookteen",
            "Verse gember",
            "Rode peper",
            "Gemalen citroengras",
            "Vissaus",
            "Verse koriander",
            "Verse munt",
            "Kipgehakt met Italiaanse kruiden",
            "Noedels",
            "Gesneden peen",
            "Mesclun"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/vietnamees-kipgehakt-in-little-gem-37216e50.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/vietnamees-kipgehakt-in-little-gem-5b4f42faae08b57dc0242971-91658c0e.pdf"
    },
    {
        "title": "Linguine met cherrytomaten, kappertjes en olijven",
        "extra": "Met verse peterselie en feta",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "543 kcal",
        "ingredients": [
            "Passata",
            "Linguine",
            "Verse bladpeterselie",
            "Olijf-kappertjesmix",
            "Knoflookteen",
            "Rode cherrytomaten",
            "Pruimtomaat",
            "Feta"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/linguine-met-cherrytomaten-kappertjes-en-olijven-5c341dea.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/linguine-met-cherrytomaten-kappertjes-en-olijven-5b4f4656ae08b501ab44ed22-d33d401c.pdf"
    },
    {
        "title": "Pita's met oosters gekruid rundergehakt",
        "extra": "Met hummus en frisse tomaat-komkommersalade",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Sesamzaad",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "998 kcal",
        "ingredients": [
            "Volkoren pitabroodje",
            "Pijnboompitten",
            "Tomaat",
            "Komkommer",
            "Oosters gekruid rundergehakt",
            "Verse munt",
            "Citroen",
            "Hummus met ras el hanout",
            "Paprikapoeder"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pita-s-met-oosters-gekruid-rundergehakt-e24885de.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pita-s-met-oosters-gekruid-rundergehakt-5b504246ae08b546ad787a62-6dd09e27.pdf"
    },
    {
        "title": "Burger deluxe met aardappeltjes",
        "extra": "Een burger van kalfsvlees met gebakken ei en spek",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "1139 kcal",
        "ingredients": [
            "Roseval aardappelen",
            "Biologisch wit rozenbroodje",
            "Mesclun",
            "Mini-romatomaten",
            "Rode ui",
            "Ontbijtspek",
            "Kalfsburger",
            "Ei",
            "Kappertjes en gesneden augurken",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/burger-deluxe-met-aardappeltjes-b0c98c55.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/burger-deluxe-met-aardappeltjes-5b504cc7ae08b54dd30f58d1-b1ce733c.pdf"
    },
    {
        "title": "Curry met kip en sperziebonen",
        "extra": "Met couscous, munt en amandelen",
        "labels": [
            "Caloriebewust"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "124 kcal",
        "ingredients": [
            "Sperziebonen",
            "Kippendijreepjes",
            "Kokosmelk",
            "Rode currypasta",
            "Volkoren couscous",
            "Verse munt",
            "Amandelen",
            "Magere yoghurt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/curry-met-kip-en-sperziebonen-77a71fc3.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/curry-met-kip-en-sperziebonen-5af2ea4a30006c244c474f32-8ea8dad0.pdf"
    },
    {
        "title": "Salade met tonijn en opperdoezer ronde",
        "extra": "Met gebakken venkel, appel en kappertjes",
        "labels": [
            "Caloriebewust",
            "Familie"
        ],
        "allergens": [
            "Vis"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "79 kcal",
        "ingredients": [
            "Opperdoezer Ronde aardappelen",
            "Rode ui",
            "Venkel",
            "Tonijn in olijfolie",
            "Appel",
            "Spinazie",
            "Kappertjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/salade-met-tonijn-en-opperdoezer-ronde-a70aa743.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/salade-met-tonijn-en-opperdoezer-ronde-5b56fbc830006c4f2d614993-d84d606c.pdf"
    },
    {
        "title": "Kapsalon met kipgyros",
        "extra": "Met zelfgemaakte ovenfriet, sla en tomaatjes",
        "labels": [
            "Eet mij eerst",
            "Chef's Choice"
        ],
        "allergens": [
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "138 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Knoflookteen",
            "Mini-romatomaten",
            "Verse bieslook",
            "Mayonaise",
            "Kipgyros",
            "Gemengde sla",
            "Biologische karnemelk"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kapsalon-met-kipgyros-89e64ee6.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kapsalon-met-kipgyros-5b571f5630006c6d5a4f89b2-a5f90842.pdf"
    },
    {
        "title": "Italiaanse platbroodpizza's met buffelmozzarella",
        "extra": "Met courgette en gele paprika",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "134 kcal",
        "ingredients": [
            "Knoflookteen",
            "Courgette",
            "Gele paprika",
            "Buffelmozzarella",
            "Passata",
            "Paprikapoeder",
            "Wit platbrood",
            "Belegen kaasplakken",
            "Gedroogde oregano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/italiaanse-platbroodpizza-s-met-buffelmozzarella-22d05b52.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/italiaanse-platbroodpizza-s-met-buffelmozzarella-5b5724ae30006c714a6df362-b1599e2f.pdf"
    },
    {
        "title": "Schelvisfilet met bieslooksaus",
        "extra": "Met gebakken aardappeltjes en bospeen",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Vis"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "90 kcal",
        "ingredients": [
            "Wortel",
            "Vastkokende aardappelen",
            "Verse bieslook",
            "Volle yoghurt",
            "Schelvisfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/schelvisfilet-met-bieslooksaus-de3de6ec.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/schelvisfilet-met-bieslooksaus-5b57377930006c02d016b192-46ef55b6.pdf"
    },
    {
        "title": "Zomerse gnocchi met merguezworst",
        "extra": "Met citroen-ricottasaus, courgette en mini-romatomaten",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Soja",
            "Mosterd",
            "Selderij",
            "Gluten",
            "Eieren",
            "Sulfiet",
            "Tarwe",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "136 kcal",
        "ingredients": [
            "Knoflookteen",
            "Courgette",
            "Mini-romatomaten",
            "Verse krulpeterselie",
            "Citroen",
            "Rundermerguez",
            "Gnocchi",
            "Karwijzaad",
            "Ricotta"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/zomerse-gnocchi-met-merguezworst-4f1c9bfa.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/zomerse-gnocchi-met-merguezworst-5b573cd630006c07984673c2-ee52ac17.pdf"
    },
    {
        "title": "Linguine met cherrytomaten uit de oven",
        "extra": "Met grana padano en pompoenpitten",
        "labels": [
            "Caloriebewust",
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "125 kcal",
        "ingredients": [
            "Knoflookteen",
            "Turkse rode peper",
            "Rode cherrytomaten",
            "Linguine",
            "Pompoenpitten",
            "Gedroogde oregano",
            "Geraspte grana padano",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/linguine-met-cherrytomaten-uit-de-oven-9a5176f1.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/linguine-met-cherrytomaten-uit-de-oven-5b58235e30006c4a7e12b8b2-e84baec3.pdf"
    },
    {
        "title": "Aziatische noedels met roerei en pinda's",
        "extra": "Met bosui en limoen",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Eieren",
            "Pinda's"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "117 kcal",
        "ingredients": [
            "Knoflookteen",
            "Ui",
            "Rode peper",
            "Bosui",
            "Limoen",
            "Verse gember",
            "Hollandse groentemix",
            "Sojasaus",
            "Ei",
            "Noedels",
            "Gezouten pinda's"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/aziatische-noedels-met-roerei-en-pinda-s-d511a66b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/aziatische-noedels-met-roerei-en-pinda-s-5b582d2bae08b56e634bf1c2-53c19db7.pdf"
    },
    {
        "title": "Orzo met geroosterde bloemkool en bimi",
        "extra": "Met verse citroentijm en geschaafde amandelen",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Amandelnoten",
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "133 kcal",
        "ingredients": [
            "Verse citroentijm",
            "Bloemkool",
            "Broccolini",
            "Ui",
            "Knoflookteen",
            "Amandelschaafsel",
            "Orzo",
            "Parmigiano reggianovlokken"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/orzo-met-geroosterde-bloemkool-en-bimi-b4945b1f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/orzo-met-geroosterde-bloemkool-en-bimi-5b5836f7ae08b575d40569a2-ca3113d0.pdf"
    },
    {
        "title": "Broodje met gehaktballetjes in barbecuesaus",
        "extra": "Met ovenfrites en frisse komkommersalade",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Sesamzaad",
            "Lupine",
            "Soja",
            "Eieren",
            "Mosterd"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "118 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Hamburgerbol",
            "Ui",
            "Knoflookteen",
            "Rode peper",
            "Gekruide rundergehaktballetjes",
            "Gerookt paprikapoeder",
            "Gemalen komijnzaad",
            "Passata",
            "Sojasaus",
            "Komkommer",
            "Tomaat",
            "Verse bieslook",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gehaktballetjes-in-barbecuesaus-op-een-broodje-5891bbf9.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gehaktballetjes-in-barbecuesaus-op-een-broodje-5b583fd230006c5ee4451872-62ff7c0a.pdf"
    },
    {
        "title": "Farfalle met bospaddenstoelenpesto",
        "extra": "Met kastanjechampignons, veldsla en pecorino",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "161 kcal",
        "ingredients": [
            "Farfalle",
            "Ui",
            "Kastanjechampignons",
            "Bospaddenstoelenpesto",
            "Veldsla",
            "Verse krulpeterselie",
            "Geraspte pecorino",
            "Sjalot"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/farfalle-met-bospaddenstoelenpesto-9b86f28a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/farfalle-met-bospaddenstoelenpesto-5b58427c30006c60ee791b32-aaed6f65.pdf"
    },
    {
        "title": "Varkenshaas met orecchiette",
        "extra": "Met paddenstoelen-roomsaus en rucola",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "125 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Gemengde gesneden paddenstoelen",
            "Varkenshaas",
            "Gedroogde rozemarijn",
            "Orecchiette",
            "Biologische cr\u00e8me fra\u00eeche",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/varkenshaas-met-orecchiette-5689e51c.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/varkenshaas-met-orecchiette-5b585e2dae08b513f154e302-35342eaf.pdf"
    },
    {
        "title": "Gebakken zalmfilet met zeekraaltopping",
        "extra": "Met gebakken venkel, krieltjes en citroenmayonaise",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Eieren",
            "Mosterd",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "964 kcal",
        "ingredients": [
            "Krieltjes",
            "Ui",
            "Knoflookteen",
            "Venkel",
            "Citroen",
            "Verse dille",
            "Mayonaise",
            "Zalmfilet met huid",
            "Zeekraal"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-zalmfilet-met-zeekraaltopping-16669fc8.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-zalmfilet-met-zeekraaltopping-5aead5fb30006c2f4563e292-2342e857.pdf"
    },
    {
        "title": "Romige linguine met garnalen en citroen",
        "extra": "Met courgette en tomaat",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Schaaldieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "701 kcal",
        "ingredients": [
            "Courgette",
            "Gedroogde rozemarijn",
            "Knoflookteen",
            "Tomaat",
            "Citroen",
            "Linguine",
            "Garnalen",
            "Biologische cr\u00e8me fra\u00eeche"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-linguine-met-garnalen-en-citroen-02293334.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-linguine-met-garnalen-en-citroen-5b62d6e1ae08b55419294612-ff41239f.pdf"
    },
    {
        "title": "Gyros met mediterrane bulgur",
        "extra": "Met koolrabi, komijn en labne",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Eieren",
            "Sulfiet"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "691 kcal",
        "ingredients": [
            "Koolrabi",
            "Ui",
            "Gemalen komijnzaad",
            "Volkoren bulgur",
            "Varkenshaasreepjes met gyroskruiden",
            "Mesclun",
            "Labne"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gyros-met-mediterrane-bulgur-40ded4fb.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gyros-met-mediterrane-bulgur-5b62ec4230006c45bc2161f2-0e0355d6.pdf"
    },
    {
        "title": "Tomaat-paprikasoep met dragon",
        "extra": "Met parelcouscous en soepstengels",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Sulfiet",
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Eieren"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "700 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Tomaat",
            "Rode paprika",
            "Gedroogde abrikozen",
            "Verse dragon",
            "Parelcouscous",
            "Witte ciabatta",
            "Biologische zure room",
            "Geraspte grana padano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/tomaat-paprikasoep-met-dragon-1511ba8a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/tomaat-paprikasoep-met-dragon-5b62f58eae08b56a954129d2-7350743d.pdf"
    },
    {
        "title": "Schelvisfilet met ontbijtspek en citroen",
        "extra": "Met zomerse spinazie-tomaatstamppot",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "679 kcal",
        "ingredients": [
            "Kruimige aardappelen",
            "Citroen",
            "Schelvisfilet",
            "Ontbijtspek",
            "Tomaat",
            "Knoflookteen",
            "Spinazie"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/schelvisfilet-met-ontbijtspek-en-citroen-11bab4ce.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/schelvisfilet-met-ontbijtspek-en-citroen-5b62fb9d30006c50fe22ff42-b7b57508.pdf"
    },
    {
        "title": "Pad thai met kippendij en roerei",
        "extra": "Met noedels, koriander en pinda's",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Pinda's",
            "Eieren",
            "Vis",
            "Gluten",
            "Soja"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "841 kcal",
        "ingredients": [
            "Limoen",
            "Verse koriander",
            "Gezouten pinda's",
            "Knoflookteen",
            "Ei",
            "Kippendijreepjes",
            "Wittekool en peen",
            "Vissaus",
            "Sojasaus",
            "Noedels"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pad-thai-met-kippendij-en-roerei-15ca1f28.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pad-thai-met-kippendij-en-roerei-5b63044b30006c577e2a96e2-a941f343.pdf"
    },
    {
        "title": "Gegratineerde aubergine met bataatpuree",
        "extra": "Met oude geitenkaas en bieslook",
        "labels": [
            "Caloriebewust",
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "620 kcal",
        "ingredients": [
            "Aubergine",
            "Oude geitenkaasvlokken",
            "Zoete aardappel",
            "Verse bieslook",
            "Rode paprika"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gegratineerde-aubergine-met-bataatpuree-abf39fca.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gegratineerde-aubergine-met-bataatpuree-5b63108dae08b57f9416d6a3-df45ab94.pdf"
    },
    {
        "title": "Spinaziegratin met spiegelei",
        "extra": "Met gebakken aardappeltjes",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Eieren"
        ],
        "duration": "40 minuten",
        "difficulty": "Makkelijk",
        "kcal": "819 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Ui",
            "Knoflookteen",
            "Tomaat",
            "Nootmuskaat",
            "Gedroogde tijm",
            "Spinazie",
            "Biologische cr\u00e8me fra\u00eeche",
            "Geraspte belegen kaas",
            "Ei"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-spinaziegratin-met-spiegelei-c14d7bb5.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-spinaziegratin-met-spiegelei-5b63192c30006c67fb682cd2-97cc59d1.pdf"
    },
    {
        "title": "Feta en knoflooktomaat uit de oven",
        "extra": "Met zilvervliesrijst, kappertjes en pompoenpitten",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Selderij"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "847 kcal",
        "ingredients": [
            "Zilvervliesrijst",
            "Knoflookteen",
            "Tomaat",
            "Rode cherrytomaten",
            "Feta",
            "Kappertjes",
            "Pompoenpitten",
            "Vers basilicum"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/feta-en-knoflooktomaat-uit-de-oven-d5180467.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/feta-en-knoflooktomaat-uit-de-oven-5b640562ae08b53f1d528452-62b4edc6.pdf"
    },
    {
        "title": "Kippendij in kokossaus met paksoi",
        "extra": "Met champignons en pandanrijst",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Sesamzaad",
            "Vis"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "478 kcal",
        "ingredients": [
            "Pandanrijst",
            "Verse gember",
            "Knoflookteen",
            "Champignons",
            "Kippendijfilet",
            "Kokosmelk",
            "Sesamzaad",
            "Paksoi",
            "Vissaus",
            "Limoen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kippendij-in-kokossaus-met-paksoi-4c1dd4e2.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kippendij-in-kokossaus-met-paksoi-5b6414bcae08b549d85a54e2-5387ce3d.pdf"
    },
    {
        "title": "Parelcouscoussalade met avocado en feta",
        "extra": "Met tomaat, limoen en koriander",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "770 kcal",
        "ingredients": [
            "Ui",
            "Tomaat",
            "Avocado",
            "Parelcouscous",
            "Limoen",
            "Verse koriander",
            "Pompoenpitten",
            "Veldsla",
            "Feta"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscoussalade-met-avocado-en-feta-995564ea.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscoussalade-met-avocado-en-feta-5b641c5330006c2f111eb2c2-ce62b3a9.pdf"
    },
    {
        "title": "Rigatoni met broccoli en spekjes",
        "extra": "Met cr\u00e8me fra\u00eeche en belegen kaas",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "881 kcal",
        "ingredients": [
            "Champignons",
            "Rigatoni",
            "Ui",
            "Knoflookteen",
            "Verse bladpeterselie",
            "Spekblokjes",
            "Biologische cr\u00e8me fra\u00eeche",
            "Geraspte belegen kaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rigatoni-met-broccoli-en-spekjes-716e7b70.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rigatoni-met-broccoli-en-spekjes-5b6422b130006c330d2c9102-7a789447.pdf"
    },
    {
        "title": "Gebakken zeebaars met salieboter",
        "extra": "Met tomaat, prei en kerrie-aardappelen",
        "labels": [
            "Eet mij eerst",
            "Caloriebewust",
            "Familie"
        ],
        "allergens": [
            "Selderij",
            "Mosterd",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "615 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Kerriepoeder",
            "Prei",
            "Rode cherrytomaten",
            "Knoflookteen",
            "Verse salie",
            "Zeebaarsfilet met huid"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-zeebaars-met-salieboter-dbd85575.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-zeebaars-met-salieboter-5b6aa4a3ae08b575d819c4d2-65364e3e.pdf"
    },
    {
        "title": "Japanse noedelsoep met kipfilet",
        "extra": "Met kastanjechampignons en paksoi",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Soja"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "516 kcal",
        "ingredients": [
            "Verse gember",
            "Rode peper",
            "Kastanjechampignons",
            "Paksoi",
            "Japans gemarineerde kipfiletreepjes",
            "Verse udonnoedels",
            "Sojasaus",
            "Zwarte peper"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/japanse-noedelsoep-met-kipfilet-18c3f6a6.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/japanse-noedelsoep-met-kipfilet-5b6aac9eae08b57b9a185012-f9d4cecd.pdf"
    },
    {
        "title": "Conchiglie met rode pesto en feta",
        "extra": "Met mini-romatomaatjes, rucola en basilicum",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Selderij",
            "Noten"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "822 kcal",
        "ingredients": [
            "Sjalot",
            "Mini-romatomaten",
            "Conchiglie",
            "Feta",
            "Vers basilicum",
            "Rode pesto",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/conchiglie-met-rode-pesto-en-feta-1c4a5063.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/conchiglie-met-rode-pesto-en-feta-5b6ab1c930006c61b47d8522-3df0bc38.pdf"
    },
    {
        "title": "Schelvisfilet met linzen en spekjes",
        "extra": "Met peen en prei",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis"
        ],
        "duration": "35 minuten",
        "difficulty": "Makkelijk",
        "kcal": "544 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Wortel",
            "Prei",
            "Spekblokjes",
            "Linzen",
            "Schelvisfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/schelvisfilet-met-linzen-en-spekjes-48d7726b.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/schelvisfilet-met-linzen-en-spekjes-5b6abb2930006c693f585a22-139158e0.pdf"
    },
    {
        "title": "Burger met pittige groene peper en piccalilly",
        "extra": "Met aardappelpartjes en salade",
        "labels": [
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Mosterd"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "924 kcal",
        "ingredients": [
            "Bruine ciabatta",
            "Aardappelpartjes",
            "Gedroogde tijm",
            "Tomaat",
            "Komkommer",
            "Groene peper",
            "Gemengde sla",
            "Half-om-half hamburger",
            "Piccalilly"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/burger-met-pittige-groene-peper-en-piccalilly-eaa86129.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/burger-met-pittige-groene-peper-en-piccalilly-5b6acb4130006c7566556352-e80e7918.pdf"
    },
    {
        "title": "Groentelasagne met zelfgemaakte roomsaus",
        "extra": "Met aubergine, spinazie en oude kaas",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten",
            "Eieren"
        ],
        "duration": "1 uur",
        "difficulty": "Gemiddeld",
        "kcal": "899 kcal",
        "ingredients": [
            "Aubergine",
            "Ui",
            "Knoflookteen",
            "Gedroogde rozemarijn",
            "Spinazie",
            "Kookroom",
            "Geraspte oude kaas",
            "Lasagnebladen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/groentelasagne-met-zelfgemaakte-roomsaus-486a40eb.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/groentelasagne-met-zelfgemaakte-roomsaus-5b6ad34230006c7bd165d9d2-b43c15f3.pdf"
    },
    {
        "title": "Aziatisch rijstgerecht met spiegelei",
        "extra": "Met broccoli, kastanjechampignons en cashewnoten",
        "labels": [
            "Eet mij eerst",
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Sesamzaad",
            "Eieren",
            "Gluten",
            "Soja",
            "Pinda's",
            "Noten"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "956 kcal",
        "ingredients": [
            "Basmatirijst",
            "Champignons",
            "Verse gember",
            "Knoflookteen",
            "Sjalot",
            "Kastanjechampignons",
            "Verse bladpeterselie",
            "Sesamzaad",
            "Ei",
            "Sojasaus",
            "Cashewnoten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/groenten-in-zoete-sojasaus-met-spiegelei-af7ab4ed.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/groenten-in-zoete-sojasaus-met-spiegelei-5b6adbc1ae08b521701f3a12-4f794c8e.pdf"
    },
    {
        "title": "Tomatensoep en warm broodje met buffelmozzarella",
        "extra": "Met rode pesto, tomaat en verse basilicum",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Selderij",
            "Noten"
        ],
        "duration": "35 minuten",
        "difficulty": "Makkelijk",
        "kcal": "603 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Tomaat",
            "Verse tijm",
            "Paprikapoeder",
            "Witte ciabatta",
            "Vers basilicum",
            "Buffelmozzarella",
            "Rode pesto"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/omatensoep-en-warm-broodje-met-buffelmozzarella-71957218.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/omatensoep-en-warm-broodje-met-buffelmozzarella-5b6ae3b430006c09ab64d642-6f8ac7c6.pdf"
    },
    {
        "title": "B\u00e1nh m\u00ec met gehaktballetjes",
        "extra": "Met zoetzure groenten en pittige mayonaise",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis",
            "Gluten",
            "Eieren",
            "Mosterd"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "935 kcal",
        "ingredients": [
            "Sjalot",
            "Verse gember",
            "Rode peper",
            "Limoen",
            "Mesclun",
            "Vissaus",
            "Rodekool en wortel",
            "Gekruide rundergehaktballetjes",
            "Baguette",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/banh-mi-met-gehaktballetjes-672d00b3.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/banh-mi-met-gehaktballetjes-5b6aeaea30006c0f5224bd72-b07aabbf.pdf"
    },
    {
        "title": "Bonensalade met croutons en spiegelei",
        "extra": "Met tomaat en romige dressingtomaat",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)",
            "Eieren",
            "Mosterd"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "792 kcal",
        "ingredients": [
            "Sperziebonen",
            "Cannellini bonen",
            "Paprikapoeder",
            "Bruine ciabatta",
            "Pruimtomaat",
            "Sjalot",
            "Mayonaise",
            "Ei",
            "Rucolamelange"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/bonensalade-met-croutons-en-spiegelei-5b478853.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/bonensalade-met-croutons-en-spiegelei-5b6af63a30006c18c10eea12-546b4587.pdf"
    },
    {
        "title": "Parelcouscoussalade met Duitse biefstuk",
        "extra": "Met gebakken portobello en pestodressing",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "20 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "839 kcal",
        "ingredients": [
            "Parelcouscous",
            "Knoflookteen",
            "Portobello",
            "Tomaat",
            "Groene pesto",
            "Duitse biefstuk",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/parelcouscoussalade-met-duitse-biefstuk-5da3fe49.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/parelcouscoussalade-met-duitse-biefstuk-5b6b05c0ae08b5436c2fa0d2-e72a76e3.pdf"
    },
    {
        "title": "Kip piccata met groene asperges en linguine",
        "extra": "Een Italiaans gerecht met kappertjes, citroen en peterselie",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "817 kcal",
        "ingredients": [
            "Sjalot",
            "Verse bladpeterselie",
            "Rode cherrytomaten",
            "Citroen",
            "Knoflookteen",
            "Groene asperges",
            "Linguine",
            "Kipfilet",
            "Kappertjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kip-piccata-met-groene-asperges-en-linguine-2644e0cc.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kip-piccata-met-groene-asperges-en-linguine-5b6b0c9a30006c2b7f4aa572-2a538d1d.pdf"
    },
    {
        "title": "Platbroodpizza's met tonijn",
        "extra": "Met rode paprika, rode ui en belegen kaas",
        "labels": [
            "Familie"
        ],
        "allergens": [
            "Vis",
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Makkelijk",
        "kcal": "711 kcal",
        "ingredients": [
            "Rode paprika",
            "Rode ui",
            "Knoflookteen",
            "Tomatenpuree",
            "Tonijn in olijfolie",
            "Wit platbrood",
            "Geraspte belegen kaas",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/platbroodpizza-s-met-tonijn-eaea0e09.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/platbroodpizza-s-met-tonijn-5b73d3c930006c2feb0f0fa2-54834056.pdf"
    },
    {
        "title": "Risotto met venkel en gekruide varkensworst",
        "extra": "Met mascarpone en grana padano",
        "labels": ["Familie"],
        "allergens": [
            "Melk (inclusief lactose)",
            "Eieren"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "753 kcal",
        "ingredients": [
            "Ui",
            "Rode peper",
            "Venkel",
            "Varkensworst met marjoraan en knoflook",
            "Citroen",
            "Venkelzaad",
            "Risottorijst",
            "Mascarpone",
            "Geraspte grana padano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/risotto-met-venkel-en-gekruide-varkensworst-831d5d9d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/risotto-met-venkel-en-gekruide-varkensworst-5b73da34ae08b53e0243e422-4ca3074c.pdf"
    },
    {
        "title": "Portobello met geitenkaas uit de oven",
        "extra": "Met gebakken aardappeltjes en courgette",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "684 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Verse rozemarijn",
            "Rode ui",
            "Courgette",
            "Portobello",
            "Verse geitenkaas",
            "Walnoten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/portobello-met-geitenkaas-uit-de-oven-2d977127.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/portobello-met-geitenkaas-uit-de-oven-5b73e25230006c3ac7316d72-7ef9d00e.pdf"
    },
    {
        "title": "Zoete appel-pastinaaksoep met gerookte forel",
        "extra": "Met verse dille en een zonnebloempittenciabatta",
        "labels": [
            "Caloriebewust"
        ],
        "allergens": [
            "Gluten",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "592 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Pastinaak",
            "Prei",
            "Appel",
            "Ciabatta met zonnebloempitten",
            "Gerookte forelfilet",
            "Verse dille"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/zoete-appel-pastinaaksoep-met-gerookte-forel-925c1ec1.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/zoete-appel-pastinaaksoep-met-gerookte-forel-5b73e8c230006c3fa9587d22-97cc25dd.pdf"
    },
    {
        "title": "Gebakken kipfilet met knoflooksnijbonen",
        "extra": "Met gekookte aardappelen, kappertjes en zonnebloempitten",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "692 kcal",
        "ingredients": [
            "Aardappelen",
            "Knoflookteen",
            "Gesneden snijbonen",
            "Sjalot",
            "Kipfilet",
            "Pompoenpitten",
            "Kappertjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gebakken-kipfilet-met-knoflooksnijbonen-3718dbbe.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gebakken-kipfilet-met-knoflooksnijbonen-5b73f9bf30006c4d166c52c2-7ea9d105.pdf"
    },
    {
        "title": "Shakshuka met geitenkaas en ei",
        "extra": "Met krulpeterselie en speltbaguette",
        "labels": [
            "Caloriebewust",
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Eieren",
            "Melk (inclusief lactose)",
            "Gluten"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "601 kcal",
        "ingredients": [
            "Ui",
            "Knoflookteen",
            "Rode peper",
            "Rode paprika",
            "Pruimtomaat",
            "Verse krulpeterselie",
            "Ei",
            "Verse geitenkaas",
            "Speltbaguette"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/shakshuka-met-geitenkaas-en-ei-36fa45a9.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/shakshuka-met-geitenkaas-en-ei-5b740a98ae08b562ed538c22-09758902.pdf"
    },
    {
        "title": "Salade met peer, danablu en walnoot",
        "extra": "Met gepofte aardappel en courgettelinten",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "715 kcal",
        "ingredients": [
            "Kruimige aardappelen",
            "Walnoten",
            "Courgette",
            "Peer",
            "Gemengde sla",
            "Danablu"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/salade-met-peer-danablu-en-walnoot-56ebcff2.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/salade-met-peer-danablu-en-walnoot-5b74125030006c5f29452042-549b11d7.pdf"
    },
    {
        "title": "Geroosterde feta met honing en bataat",
        "extra": "Met groene linzen, cherrytomaten en gemengde sla",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "50 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "932 kcal",
        "ingredients": [
            "Groene linzen",
            "Zoete aardappel",
            "Sjalot",
            "Knoflookteen",
            "Rode cherrytomaten",
            "Feta",
            "Citroen",
            "Verse bladpeterselie",
            "Rucolamelange"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/geroosterde-feta-met-honing-en-bataat-16ace6e8.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/geroosterde-feta-met-honing-en-bataat-5b741c4230006c66bb158e22-3a5f61a2.pdf"
    },
    {
        "title": "Paprika's gevuld met chili con carne",
        "extra": "Met basmatirijst en yoghurt",
        "labels": ["Familie"],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "804 kcal",
        "ingredients": [
            "Groene paprika",
            "Basmatirijst",
            "Ui",
            "Rode peper",
            "Tomaat",
            "Rode paprika",
            "HAK kleine rode kidneybonen",
            "Gekruid rundergehakt",
            "Mexicaanse kruiden",
            "Volle yoghurt",
            "Geraspte cheddar"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/paprika-s-gevuld-met-chili-con-carne-b4937c74.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/paprika-s-gevuld-met-chili-con-carne-5b742c6aae08b57ea02ed652-c7adfe6e.pdf"
    },
    {
        "title": "Pastasalade met avocado en ricotta",
        "extra": "Met citroen en verse kruiden",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Selderij"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "588 kcal",
        "ingredients": [
            "Rigatoni",
            "Gekleurde cherrytomaten",
            "Sjalot",
            "Citroen",
            "Avocado",
            "Ricotta",
            "Verse bladpeterselie",
            "Vers basilicum",
            "Pompoenpitten",
            "Veldsla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pastasalade-met-avocado-en-ricotta-2a8a3f63.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pastasalade-met-avocado-en-ricotta-5b74350c30006c7a8a323461-b3ebb6c3.pdf"
    },
    {
        "title": "Schelvisfilet met knoflook-krieltjes",
        "extra": "Met sperziebonen, verse kruiden en zure room",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "599 kcal",
        "ingredients": [
            "Krieltjes",
            "Sperziebonen",
            "Knoflookteen",
            "Verse bladpeterselie",
            "Verse bieslook",
            "Schelvisfilet",
            "Biologische zure room"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/schelvisfilet-met-knoflook-krieltjes-ad101fc5.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/schelvisfilet-met-knoflook-krieltjes-5b743ac630006c7ebd3e0cc2-622c89e6.pdf"
    },
    {
        "title": "Rumpsteak met Aziatische salade",
        "extra": "Met zelfgemaakte ovenfriet en limoenmayonaise",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Sesamzaad",
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Moeilijk",
        "kcal": "934 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Verse gember",
            "Rode peper",
            "Limoen",
            "Sojasaus",
            "Sesamolie",
            "Radijs",
            "Gesneden rodekool",
            "Sesamzaad",
            "Mayonaise",
            "Rumpsteak",
            "Gemengde sla"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rumpsteak-met-aziatische-salade-e2081d03.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rumpsteak-met-aziatische-salade-5b74426fae08b5129275ade2-7b134634.pdf"
    },
    {
        "title": "Luxe vispotje met room en witte wijn",
        "extra": "Met venkel en gebakken Drieling aardappelen",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Sulfiet",
            "Melk (inclusief lactose)",
            "Vis"
        ],
        "duration": "40 minuten",
        "difficulty": "Moeilijk",
        "kcal": "1178 kcal",
        "ingredients": [
            "Drieling aardappelen",
            "Knoflookteen",
            "Sjalot",
            "Venkel",
            "Wortel",
            "Prei",
            "Espiga Branco",
            "Slagroom",
            "Vismelange: zalm, kabeljauw en koolvis",
            "Verse kervel",
            "Citroen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/vispotje-met-room-en-witte-wijn-10f103a5.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/vispotje-met-room-en-witte-wijn-5b753250ae08b5675f781782-d65bbdbb.pdf"
    },
    {
        "title": "Schelvis met spek en sperziebonen",
        "extra": "Met goudbruine aardappelschijfjes",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Vis",
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "805 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Sperziebonen",
            "Ontbijtspek",
            "Schelvisfilet",
            "Nootmuskaat",
            "Ravigotesaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/schelvis-met-spek-en-sperziebonen-5f4906c5.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/schelvis-met-spek-en-sperziebonen-5b8545d0ae08b549f533c4f2-360d6494.pdf"
    },
    {
        "title": "Chinese rijst met gemarineerde varkensreepjes",
        "extra": "Met ham, omelet en pittige zoetzure-komkommer",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Eieren",
            "Pinda's"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "952 kcal",
        "ingredients": [
            "Knoflookteen",
            "Varkenshaasreepjes met Indonesische kruiden",
            "Pandanrijst",
            "Rode peper",
            "Komkommer",
            "Prei",
            "Ei",
            "Achterham",
            "Gezouten pinda's"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/chinese-rijst-met-gemarineerde-varkensreepjes-af949df8.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/chinese-rijst-met-gemarineerde-varkensreepjes-5b854b14ae08b54e7d235f82-2eee73fe.pdf"
    },
    {
        "title": "Bataatstamppot met oude kaas",
        "extra": "Met veldsla, rucola en pecannoten",
        "labels": [
            "Caloriebewust",
            "Veggie"
        ],
        "allergens": [
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Makkelijk",
        "kcal": "653 kcal",
        "ingredients": [
            "Kruimige aardappelen",
            "Zoete aardappel",
            "Pecannoten",
            "Rode ui",
            "Tomaat",
            "Rucolamelange",
            "Oude-kaasblokjes"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/bataatstamppot-met-oude-kaas-13ff419f.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/bataatstamppot-met-oude-kaas-5b8556b3ae08b55a0327f312-5553a76e.pdf"
    },
    {
        "title": "Romige soep met garnalen en kokosmelk",
        "extra": "Met verse mais, tomaat en aardappel",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Schaaldieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "663 kcal",
        "ingredients": [
            "Sjalot",
            "Vastkokende aardappelen",
            "Paprikapoeder",
            "Laurierblad",
            "Maiskolf",
            "Tomaat",
            "Verse bieslook",
            "Garnalen",
            "Kokosmelk"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-soep-met-garnalen-en-kokosmelk-d6756a0d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-soep-met-garnalen-en-kokosmelk-5b8560d530006c429d096d52-ff85bd15.pdf"
    },
    {
        "title": "Aziatische kip in roomsaus",
        "extra": "Met bloemkoolrijst en rode paprika",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Pinda's",
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "944 kcal",
        "ingredients": [
            "Pandanrijst",
            "Sjalot",
            "Verse gember",
            "Rode peper",
            "Rode paprika",
            "Gezouten cashewnoten",
            "Verse bladpeterselie",
            "Kippendijreepjes",
            "Kookroom",
            "Bloemkoolrijst"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/aziatische-kip-in-roomsaus-6f0f1f39.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/aziatische-kip-in-roomsaus-5b856b34ae08b56b844c3831-e8db55fd.pdf"
    },
    {
        "title": "Farfalle met spinazie-preisaus",
        "extra": "Met groene pesto en rode peper",
        "labels": [
            "Caloriebewust",
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "763 kcal",
        "ingredients": [
            "Sjalot",
            "Knoflookteen",
            "Prei",
            "Rode peper",
            "Farfalle",
            "Geraspte parmigiano reggiano",
            "Spinazie",
            "Groene pesto"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/farfalle-met-spinazie-preisaus-8f6442b8.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/farfalle-met-spinazie-preisaus-5b8659f630006c0b1377ad82-7bfadeca.pdf"
    },
    {
        "title": "Patatas bravas met geitenkaasomelet",
        "extra": "Met Turkse pepers en pittige tomatensaus",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Eieren",
            "Melk (inclusief lactose)",
            "Mosterd",
            "Gluten"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "900 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Paprikapoeder",
            "Turkse groene peper",
            "Knoflookteen",
            "Rode peper",
            "Verse bladpeterselie",
            "Passata",
            "Ei",
            "Geitenkaas, geraspt",
            "Aioli"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/patatas-bravas-met-geitenkaasomelet-a942c211.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/patatas-bravas-met-geitenkaasomelet-5b865fa530006c0f634c80d2-aae628dd.pdf"
    },
    {
        "title": "Volkoren couscous met blauwe kaas",
        "extra": "Met gebakken peer en pistachenootjes",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "619 kcal",
        "ingredients": [
            "Rode ui",
            "Kastanjechampignons",
            "Peer",
            "Gedroogde tijm",
            "Volkoren couscous",
            "Pistachenoten",
            "Gemengde sla",
            "Danablu"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/volkoren-couscous-met-blauwe-kaas-72c8ae72.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/volkoren-couscous-met-blauwe-kaas-5b866b7330006c184e2805e2-8db69c3a.pdf"
    },
    {
        "title": "Kikkererwtenstamppot met kruidig gehakt",
        "extra": "Met citroen en geroosterde broccoli",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "909 kcal",
        "ingredients": [
            "Champignons",
            "Wit platbrood",
            "Knoflookteen",
            "Verse bladpeterselie",
            "Verse munt",
            "Citroen",
            "Kikkererwten",
            "Gemalen komijnzaad",
            "Gerookt paprikapoeder",
            "Gemalen korianderzaad",
            "Rundergehakt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kikkererwtenstamppot-met-kruidig-gehakt-665c952c.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kikkererwtenstamppot-met-kruidig-gehakt-5b86782630006c21fd4b0b02-1ecae2a5.pdf"
    },
    {
        "title": "Broodje omelet met pittige blauwe kaas",
        "extra": "Met gebakken portobello en coleslaw",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Sesamzaad",
            "Eieren",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "886 kcal",
        "ingredients": [
            "Knoflookteen",
            "Verse bladpeterselie",
            "Portobello",
            "Rodekool en wortel",
            "Biologisch wit broodje met roggedesem",
            "Ei",
            "Danablu"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/broodje-omelet-met-pittige-blauwe-kaas-2fd182cc.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/broodje-omelet-met-pittige-blauwe-kaas-5b879d4930006c0fcc66c712-9162aff4.pdf"
    },
    {
        "title": "Victoriabaarsfilet met aardappelschijfjes en prei",
        "extra": "Met rode ui en dillemayonaise",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis",
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "755 kcal",
        "ingredients": [
            "Aardappelschijfjes",
            "Rode ui",
            "Prei",
            "Victoriabaarsfilet",
            "Verse dille",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/victoriabaarsfilet-met-aardappelschijfjes-en-prei-748c5051.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/victoriabaarsfilet-met-aardappelschijfjes-en-prei-5b87a8eeae08b5376b304e82-30521f40.pdf"
    },
    {
        "title": "Gevulde kipfilet in coppa di parma",
        "extra": "Met ricotta, citroenrisotto en geroosterde courgette",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "40 minuten",
        "difficulty": "Moeilijk",
        "kcal": "1006 kcal",
        "ingredients": [
            "Sjalot",
            "Knoflookteen",
            "Courgette",
            "Verse oregano",
            "Kipfilet",
            "Ricotta",
            "Coppa di Parma",
            "Risottorijst",
            "Geraspte parmigiano reggiano",
            "Citroen",
            "Hazelnoten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/gevulde-kipfilet-in-coppa-di-parma-7f10d303.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/gevulde-kipfilet-in-coppa-di-parma-5b87b27130006c1f5721a561-8baf67d0.pdf"
    },
    {
        "title": "Salade ni\u00e7oise met verse tonijn",
        "extra": "Met ansjovisdressing en gekookt ei",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Eieren",
            "Vis",
            "Sulfiet"
        ],
        "duration": "35 minuten",
        "difficulty": "Moeilijk",
        "kcal": "651 kcal",
        "ingredients": [
            "Krieltjes",
            "Sperziebonen",
            "Ei",
            "Botersla",
            "Mini-romatomaten",
            "Verse dragon",
            "Verse kervel",
            "Sjalot",
            "Knoflookteen",
            "Ansjovis",
            "Tonijn",
            "Olijf-kappertjesmix"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/salade-nicoise-met-verse-tonijn-27f3f3dd.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/salade-nicoise-met-verse-tonijn-5b87bac0ae08b543c12338b2-67f5bf1e.pdf"
    },
    {
        "title": "Schelvisfilet met tomatentapenade",
        "extra": "Met ratatouille en rijst",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Sulfiet",
            "Vis"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "681 kcal",
        "ingredients": [
            "Basmatirijst",
            "Ui",
            "Knoflookteen",
            "Rode peper",
            "Tomaat",
            "Aubergine",
            "Paprikapoeder",
            "Tomatentapenade",
            "Schelvisfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/schelvisfilet-met-tomatentapenade-43ee0257.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/schelvisfilet-met-tomatentapenade-5b8e6fc230006c6c5036f992-78860d58.pdf"
    },
    {
        "title": "Kokos-noedelsoep met kippendij",
        "extra": "Met vers citroengras en basilicum",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Selderij",
            "Vis"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "643 kcal",
        "ingredients": [
            "Verse gember",
            "Rode peper",
            "Wortel",
            "Kippendijreepjes",
            "Kokosmelk",
            "Gemalen citroengras",
            "Snijbonen",
            "Verse udonnoedels",
            "Vers basilicum",
            "Limoen",
            "Vissaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kokos-noedelsoep-met-kippendij-ac25b7f2.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kokos-noedelsoep-met-kippendij-5b8f8386ae08b576d077a9d2-ee072bcb.pdf"
    },
    {
        "title": "Romige groentelasagne met spinazie",
        "extra": "Met courgette en grana padano",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Soja",
            "Eieren",
            "Melk (inclusief lactose)",
            "Gluten"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "913 kcal",
        "ingredients": [
            "Courgette",
            "Ui",
            "Knoflookteen",
            "Verse oregano",
            "Spinazie",
            "Alpro Cuisine",
            "Geraspte grana padano",
            "Lasagnebladen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-groentelasagne-met-spinazie-5c9fc14c.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-groentelasagne-met-spinazie-5b8f8d5130006c5c85577f52-679201b9.pdf"
    },
    {
        "title": "Scandinavische salade met dorade",
        "extra": "Met gebakken aardappelen",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Vis",
            "Eieren",
            "Mosterd",
            "Melk (inclusief lactose)"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "625 kcal",
        "ingredients": [
            "Komkommer",
            "Verse dille",
            "Sjalot",
            "Mesclun",
            "Vastkokende aardappelen",
            "Doradefilet met huid",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/scandinavische-salade-met-dorade-52b374eb.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/scandinavische-salade-met-dorade-5b8f9bf8ae08b5099e01d872-008ac92f.pdf"
    },
    {
        "title": "Wraps met kruidige aubergine en tartaar",
        "extra": "Met feta, munt en yoghurt-tahinsaus",
        "labels": ["Familie"],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten",
            "Sesamzaad"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "823 kcal",
        "ingredients": [
            "Gemalen komijnzaad",
            "Gemalen kurkuma",
            "Aubergine",
            "Feta",
            "Verse munt",
            "Tomaat",
            "IJsbergsla",
            "Rundertartaar",
            "Witte tortilla",
            "Yoghurt-tahinsaus"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/wraps-met-kruidige-aubergine-en-tartaar-04723d48.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/wraps-met-kruidige-aubergine-en-tartaar-5b8fa29dae08b50e5c3183e2-d366b50f.pdf"
    },
    {
        "title": "Linguine met geroosterde groenten en feta",
        "extra": "Met groene kruiden, olijven en kappertjes",
        "labels": [
            "Veggie",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)",
            "Selderij"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "836 kcal",
        "ingredients": [
            "Courgette",
            "Rode cherrytomaten",
            "Linguine",
            "Knoflookteen",
            "Olijf-kappertjesmix",
            "Feta",
            "Verse munt",
            "Vers basilicum",
            "Verse bladpeterselie"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/linguine-met-geroosterde-groenten-en-feta-5cf6af97.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/linguine-met-geroosterde-groenten-en-feta-5b8faa7230006c7171023322-fa08b07f.pdf"
    },
    {
        "title": "Kruidige couscoussalade met verse geitenkaas",
        "extra": "Met appel, limoen en bosui",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "886 kcal",
        "ingredients": [
            "Volkoren couscous",
            "Gele paprika",
            "Tomaat",
            "Bosui",
            "Amandelen",
            "Appel",
            "Verse bladpeterselie",
            "Verse citroenmelisse",
            "Limoen",
            "Verse geitenkaas"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kruidige-couscoussalade-met-verse-geitenkaas-55e6c3ef.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kruidige-couscoussalade-met-verse-geitenkaas-5b8fb99530006c7d221e38c2-a32b2452.pdf"
    },
    {
        "title": "Boekweitnoedels met gekruide tofu",
        "extra": "Met sperziebonen en sesamzaad",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst",
            "Veggie"
        ],
        "allergens": [
            "Soja",
            "Tarwe",
            "Selderij",
            "Sesamzaad",
            "Gluten"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "717 kcal",
        "ingredients": [
            "Knoflookteen",
            "Rode peper",
            "Verse gember",
            "Sperziebonen",
            "Gekruide tofublokjes",
            "Sesamzaad",
            "Sojasaus",
            "Sobanoedels",
            "Sesamolie"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/boekweitnoedels-met-gekruide-tofu-71b4facd.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/boekweitnoedels-met-gekruide-tofu-5b8fc00cae08b524c56ff502-26e3816a.pdf"
    },
    {
        "title": "Kipfilet met zoet-kruidige groentestoof",
        "extra": "Met ras el hanout en gedroogde abrikozen",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Sulfiet",
            "Amandelnoten",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "775 kcal",
        "ingredients": [
            "Ui",
            "Vastkokende aardappelen",
            "Wortel",
            "Ras el hanout",
            "Verse bladpeterselie",
            "Gedroogde abrikozen",
            "Tomaat",
            "Tomatenpuree",
            "Kipfilet",
            "Amandelschaafsel"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kipfilet-met-zoet-kruidige-groentestoof-2a7a453d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kipfilet-met-zoet-kruidige-groentestoof-5b8fd854ae08b539c20770e2-86923c3e.pdf"
    },
    {
        "title": "Drie-tomatensoep met kaascroutons",
        "extra": "Met gerookt paprikapoeder en zure room",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "805 kcal",
        "ingredients": [
            "Knoflookteen",
            "Tomaat",
            "Zongedroogde tomaten",
            "Paprikapoeder",
            "Rode cherrytomaten",
            "Witte ciabatta",
            "Geraspte belegen kaas",
            "Biologische zure room"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/drie-tomatensoep-met-kaaskroutons-7466a663.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/drie-tomatensoep-met-kaaskroutons-5b8fe47bae08b542d56b33d2-407c22b8.pdf"
    },
    {
        "title": "Griekse salade met kippendij",
        "extra": "Met parelcouscous en feta",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "800 kcal",
        "ingredients": [
            "Parelcouscous",
            "Kippendijreepjes met tuinkruiden",
            "Romaine sla",
            "Tomaat",
            "Komkommer",
            "Rode ui",
            "Feta",
            "Citroen"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/griekse-salade-met-kippendij-7e587ec7.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/griekse-salade-met-kippendij-5b8ff1bfae08b54cf8689da2-a622cf6a.pdf"
    },
    {
        "title": "Kogelbiefstuk met sjalot-tijmsaus",
        "extra": "Met aardappelgratin en haricots verts",
        "labels": ["Familie"],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Moeilijk",
        "kcal": "1059 kcal",
        "ingredients": [
            "Vastkokende aardappelen",
            "Sjalot",
            "Knoflookteen",
            "Slagroom",
            "Geraspte oude kaas",
            "Gedroogde tijm",
            "Haricots verts",
            "Kogelbiefstuk"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/kogelbiefstuk-met-sjalot-tijmsaus-6a81ee13.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/kogelbiefstuk-met-sjalot-tijmsaus-5b90f276ae08b5178e0ba8d2-65e34b83.pdf"
    },
    {
        "title": "Rag\u00f9 van gekonfijte eend",
        "extra": "Met linguine en frisse rucolasalade",
        "labels": ["Familie"],
        "allergens": [
            "Gluten"
        ],
        "duration": "55 minuten",
        "difficulty": "Moeilijk",
        "kcal": "964 kcal",
        "ingredients": [
            "Gekonfijte eendenpoot",
            "Knoflookteen",
            "Sjalot",
            "Rode cherrytomaten",
            "Tomatenblokjes",
            "Verse bladpeterselie",
            "Linguine",
            "Pijnboompitten",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/ragu-van-gekonfijte-eend-4bbab190.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/ragu-van-gekonfijte-eend-5b90fa8530006c7bfc1a2262-eaec8e82.pdf"
    },
    {
        "title": "Zalmfilet met Aziatische aardappelsalade",
        "extra": "Met gewokte broccoli en sesamzaadjes",
        "labels": [
            "Eet mij eerst",
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Soja",
            "Sesamzaad",
            "Vis",
            "Eieren",
            "Mosterd"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "823 kcal",
        "ingredients": [
            "Roseval aardappelen",
            "Champignons",
            "Knoflookteen",
            "Sjalot",
            "Gemalen korianderzaad",
            "Sojasaus",
            "Sesamzaad",
            "Zalmfilet met huid",
            "Mayonaise"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/zalmfilet-met-aziatische-aardappelsalade-1757bb58.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/zalmfilet-met-aziatische-aardappelsalade-5b97e13d30006c5ae8544432-096d2b60.pdf"
    },
    {
        "title": "Peruaanse aardappelsoep met feta",
        "extra": "Met courgette, cashewnoten en komijn",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Pinda's",
            "Noten",
            "Gluten",
            "Soja"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "764 kcal",
        "ingredients": [
            "Knoflookteen",
            "Ui",
            "Zoete aardappel",
            "Kruimige aardappelen",
            "Courgette",
            "Gemalen komijnzaad",
            "Paprikapoeder",
            "Verse koriander",
            "Feta",
            "Gezouten cashewnoten",
            "Bruine ciabatta"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/peruaanse-aardappelsoep-met-feta-47c1444d.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/peruaanse-aardappelsoep-met-feta-5b98def1ae08b54cf44e3672-98e5716a.pdf"
    },
    {
        "title": "Pilav met gestoomde kabeljauw",
        "extra": "Met basmatirijst, paprika en spinazie",
        "labels": [
            "Caloriebewust",
            "Eet mij eerst"
        ],
        "allergens": [
            "Selderij",
            "Mosterd",
            "Vis",
            "Melk (inclusief lactose)"
        ],
        "duration": "45 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "560 kcal",
        "ingredients": [
            "Sjalot",
            "Rode peper",
            "Knoflookteen",
            "Rode paprika",
            "Verse gember",
            "Kerriepoeder",
            "Basmatirijst",
            "Spinazie",
            "Citroen",
            "Kabeljauwfilet"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/pilav-met-gestoomde-kabeljauw-d7da4fcb.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/pilav-met-gestoomde-kabeljauw-5b98f085ae08b559e222e3c2-90c88f39.pdf"
    },
    {
        "title": "Mexicaanse taco's met kipgehakt",
        "extra": "Met pittige groene peper en cheddar",
        "labels": [
            "Caloriebewust",
            "Familie"
        ],
        "allergens": [
            "Melk (inclusief lactose)"
        ],
        "duration": "25 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "649 kcal",
        "ingredients": [
            "Ui",
            "Groene peper",
            "Groene paprika",
            "Tomaat",
            "Kipgehakt met Mexicaanse kruiden",
            "Mexicaanse kruiden",
            "Tomatenpuree",
            "Rode kidneybonen",
            "Tacoschelpen",
            "Geraspte cheddar",
            "Biologische zure room"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/mexicaanse-taco-s-met-kipgehakt-21968078.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/mexicaanse-taco-s-met-kipgehakt-5b98f58230006c406d4237b2-09840be2.pdf"
    },
    {
        "title": "Orzo met mini-romatomaten uit de oven",
        "extra": "Met courgette, gruy\u00e8re en citroen",
        "labels": [
            "Familie"
        ],
        "allergens": [
            "Gluten",
            "Melk (inclusief lactose)"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "791 kcal",
        "ingredients": [
            "Mini-romatomaten",
            "Sjalot",
            "Knoflookteen",
            "Courgette",
            "Citroen",
            "Orzo",
            "Gedroogde oregano",
            "Geraspte gruy\u00e8re"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/orzo-met-mini-romatomaten-uit-de-oven-5fdca245.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/orzo-met-mini-romatomaten-uit-de-oven-5b98fa09ae08b561694ea7d2-a272e977.pdf"
    },
    {
        "title": "Platbroodpizza funghi",
        "extra": "Met champignons, rucola en buffelmozzarella",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Gluten"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "688 kcal",
        "ingredients": [
            "Champignons",
            "Rode ui",
            "Knoflookteen",
            "Buffelmozzarella",
            "Passata",
            "Gedroogde tijm",
            "Wit platbrood",
            "Rucola"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/platbroodpizza-funghi-5e9c4423.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/platbroodpizza-funghi-5b991ac830006c5e806f7042-0c32df8c.pdf"
    },
    {
        "title": "Rijst met pittige groenten en falafel",
        "extra": "Met harissa en frisse tzatziki",
        "labels": [
            "Veggie"
        ],
        "allergens": [
            "Melk (inclusief lactose)",
            "Selderij",
            "Mosterd"
        ],
        "duration": "30 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "955 kcal",
        "ingredients": [
            "Zilvervliesrijst",
            "Knoflookteen",
            "Komkommer",
            "Volle yoghurt",
            "Champignons",
            "Wortel",
            "Harissa",
            "Kerriepoeder",
            "Falafel"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/rijst-met-pittige-groenten-en-falafel-5595b87a.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/rijst-met-pittige-groenten-en-falafel-5b99259e30006c677275b5b2-4f758b5c.pdf"
    },
    {
        "title": "Zoet gemarineerde varkenshaaspuntjes",
        "extra": "Met gebakken romaine-sla en noedels",
        "labels": [
            "Eet mij eerst"
        ],
        "allergens": [
            "Gluten"
        ],
        "duration": "35 minuten",
        "difficulty": "Gemiddeld",
        "kcal": "671 kcal",
        "ingredients": [
            "Varkenshaaspuntjes",
            "Garam masala",
            "Verse gember",
            "Rode peper",
            "Rode ui",
            "Limoen",
            "Romaine sla",
            "Noedels"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/zoet-gemarineerde-varkenshaaspuntjes-b6a3e742.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/zoet-gemarineerde-varkenshaaspuntjes-5b992bf830006c6c844445c2-f538aff9.pdf"
    },
    {
        "title": "Romige linguine met broccoli",
        "extra": "Met geitenkaas, walnoten en zongedroogde tomaatjes",
        "labels": ["Familie"],
        "allergens": [
            "Noten",
            "Gluten",
            "Melk (inclusief lactose)",
            "Eieren"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "801 kcal",
        "ingredients": [
            "Champignons",
            "Sjalot",
            "Knoflookteen",
            "Zongedroogde tomaten",
            "Walnoten",
            "Linguine",
            "Gedroogde oregano",
            "Kookroom",
            "Geitenkaas, geraspt"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/romige-linguine-met-broccoli-aa1a6d68.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/romige-linguine-met-broccoli-5b992fa2ae08b50e6646cd92-f0d89457.pdf"
    },
    {
        "title": "Steak sandwich met provolone",
        "extra": "Met een frisse salade",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Soja",
            "Melk (inclusief lactose)"
        ],
        "duration": "20 minuten",
        "difficulty": "Makkelijk",
        "kcal": "696 kcal",
        "ingredients": [
            "Sjalot",
            "Rode cherrytomaten",
            "Biefstukreepjes",
            "Witte ciabatta",
            "Provolonevlokken",
            "Rucolamelange",
            "Zonnebloempitten"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/steak-sandwich-met-provolone-8b052067.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/steak-sandwich-met-provolone-5b99339a30006c73a94d0e62-60834ef3.pdf"
    },
    {
        "title": "Linguine met warmgerookte zalm",
        "extra": "Met babyspinazie, avocado en geroosterde cherrytomaten",
        "labels": ["Familie"],
        "allergens": [
            "Gluten",
            "Vis"
        ],
        "duration": "30 minuten",
        "difficulty": "Moeilijk",
        "kcal": "821 kcal",
        "ingredients": [
            "Linguine",
            "Knoflookteen",
            "Rode cherrytomaten",
            "Pijnboompitten",
            "Citroen",
            "Avocado",
            "Verse bladpeterselie",
            "Babyspinazie",
            "Warmgerookte zalm"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/linguine-met-warmgerookte-zalm-92ac8ff9.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/linguine-met-warmgerookte-zalm-5b9a5b5230006c5f2b5805c2-fbdf0fe9.pdf"
    },
    {
        "title": "Risotto met eend en shiitake",
        "extra": "Met een frisse salade van sinaasappel en peultjes",
        "labels": ["Familie"],
        "allergens": [
            "Selderij",
            "Noten",
            "Melk (inclusief lactose)"
        ],
        "duration": "40 minuten",
        "difficulty": "Makkelijk",
        "kcal": "1126 kcal",
        "ingredients": [
            "Shiitake",
            "Kastanjechampignons",
            "Sjalot",
            "Knoflookteen",
            "Gekonfijte eendenpoot",
            "Risottorijst",
            "Peultjes",
            "Hazelnoten",
            "Handsinaasappel",
            "Veldsla",
            "Geraspte parmigiano reggiano"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/risotto-met-eend-en-shiitake-1c9c07e8.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/risotto-met-eend-en-shiitake-5b9a5fe530006c62bc17b6e2-a2c67d2f.pdf"
    },
    {
        "title": "Gekruid rundergehakt",
        "extra": "Met drieling aardappelen, groenten en rode pesto",
        "labels": ["Familie"],
        "allergens": [
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "876 kcal",
        "ingredients": [
            "Drieling aardappelen",
            "Verse krulpeterselie",
            "Rundergehakt met k\u00f6ftekruiden",
            "Groentemix van kastanjechampignons, prei, broccoli en courgette",
            "Rode pesto"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/krieltjes-met-gekruid-rundergehakt-e1aa2ecb.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/krieltjes-met-gekruid-rundergehakt-5b9a7e40ae08b51bf8581902-0f335bfc.pdf"
    },
    {
        "title": "Gekruid rundergehakt",
        "extra": "Met drieling aardappelen, groenten en rode pesto",
        "labels": ["Familie"],
        "allergens": [
            "Melk (inclusief lactose)",
            "Noten"
        ],
        "duration": "25 minuten",
        "difficulty": "Makkelijk",
        "kcal": "876 kcal",
        "ingredients": [
            "Drieling aardappelen",
            "Verse krulpeterselie",
            "Rundergehakt met k\u00f6ftekruiden",
            "Groentemix van kastanjechampignons, prei, broccoli en courgette",
            "Rode pesto"
        ],
        "image_url": "https://img.hellofresh.com/f_auto,fl_lossy,q_auto,w_1200/hellofresh_s3/image/krieltjes-met-gekruid-rundergehakt-e1aa2ecb.jpg",
        "pdf_url": "https://hellofresh.com/recipecards/card/krieltjes-met-gekruid-rundergehakt-5b9a7e40ae08b51bf8581902-0f335bfc.pdf"
    }
]