import {createContext, useCallback, useContext, useMemo, useState} from "react";

const NotificationContext = createContext();

export function NotificationProvider(props) {
    const [notification, setNotification] = useState("");

    const clearNotification = useCallback(() => {
        setNotification("");
    }, [setNotification]);

    const api = useMemo(() => ({
        notification, setNotification, clearNotification
    }), [notification, setNotification, clearNotification]);

    return <NotificationContext.Provider value={api}>
        {props.children}
    </NotificationContext.Provider>
}

export const useNotificationContext = () => useContext(NotificationContext);