import {Col, Form, Row} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";

export function Slider(props) {

    const {label, value, onChange, min, max, step, tooltipLabel, showToolTip} = props;

    return (
        <>
            <Form.Group as={Row}>
                <Col md={4}><Form.Label>{label}</Form.Label></Col>
                <Col md={8}><RangeSlider variant={"light"} value={value} onChange={(e) => onChange(e.target.value)} min={min} max={max} step={step} tooltip={showToolTip ? "on": "off"} tooltipPlacement={"top"} tooltipLabel={tooltipLabel}/></Col>
            </Form.Group>
        </>
    );

}