import {Form, Offcanvas} from "react-bootstrap";
import {useUserAuthContext} from "../contexts/UserAuthContext";
import {FormSection} from "./FormSection";
import {AllergenSwitches} from "./AllergenSwitches";

export function ProfileSettings() {

    const {logout, showSelectAllergensUI, setShowSelectAllergensUI, saveUserAllergens, stayLoggedIn, toggleStayLoggedIn} = useUserAuthContext();

    return (
        <>
            <Offcanvas className={"login-form"} show={showSelectAllergensUI} onHide={() => setShowSelectAllergensUI(false)}>
                <div className={"sidebar-wrapper"}>
                    <Offcanvas.Header className={"login-form-header"} closeButton>
                        <Offcanvas.Title className={"login-form-header"}>INSTELLINGEN</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Form className={"sidebar-form"} onSubmit={saveUserAllergens}>
                            <AllergenSwitches/>
                            <button className={"btn-sidebar btn-login"}>sla op</button>
                        </Form>

                        <Form className={"sidebar-form"} style={{marginTop: "15rem"}}>
                            <FormSection>
                                <Form.Switch type={"switch"} id={"stay-logged-in"} label={"AANGEMELD BLIJVEN"} checked={stayLoggedIn} onChange={toggleStayLoggedIn}/>
                            </FormSection>
                        </Form>
                    </Offcanvas.Body>

                    <button className={"btn-sidebar btn-logout"} onClick={logout}>log uit</button>

                </div>
            </Offcanvas>
        </>
    );

}