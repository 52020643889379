import {Form} from "react-bootstrap";
import {useUserAuthContext} from "../../contexts/UserAuthContext";

export function LoginForm(props) {

    const {showLoginForm} = props;

    const {login, user, setUser} = useUserAuthContext();

    return (
        <>
            <Form onSubmit={login} className={"sidebar-form"} style={{marginTop: "1rem", marginBottom: "0rem", display: showLoginForm ? "block" : "none"}}>
                <Form.Control className={"login-form-input"} type="email" value={user.email} onChange={e => setUser({...user, email: e.target.value})} placeholder="Email"/>
                <Form.Control className={"login-form-input"} type="password" value={user.password} onChange={e => setUser({...user, password: e.target.value})} placeholder="Wachtwoord"/>
                <button className={"btn-sidebar btn-login"}>Aanmelden</button>
            </Form>
        </>
    );

}