import {Toast, ToastContainer} from "react-bootstrap";
import {useNotificationContext} from "../contexts/NotificationContext";

export function Notification(){
    const {notification, clearNotification} = useNotificationContext();

    return(
        <ToastContainer position="top-end" className="p-3" style={{ zIndex: 99999 }}>
            <Toast show={!!notification} onClose={clearNotification} className={"notification"}>
                <Toast.Header className={"notification-header"}>
                    <strong className="me-auto">{notification.toUpperCase()}</strong>
                </Toast.Header>
            </Toast>
        </ToastContainer>
    );
}