import {Col, Form, Row} from "react-bootstrap";
import {FormSection} from "./FormSection";
import {useSearchRecipeContext} from "../contexts/SearchRecipeContext";

export function AllergenSwitches() {

    const {allergens, setAllergens} = useSearchRecipeContext();

    return (
        <FormSection title={"uw allergieën"}>
            <Row>
                <Col md={6}><Form.Switch type={"switch"} id={"lactose"} label={"LACTOSE"} checked={allergens["lactose"]} onChange={() => setAllergens({...allergens, "lactose": !allergens["lactose"]})}/></Col>
                <Col md={6}><Form.Switch type={"switch"} id={"gluten"} label={"GLUTEN"} checked={allergens["gluten"]} onChange={() => setAllergens({...allergens, "gluten": !allergens["gluten"]})}/></Col>
            </Row>
            <Row>
                <Col md={6}><Form.Switch type={"switch"} id={"vis"} label={"VIS"} checked={allergens["vis"]} onChange={() => setAllergens({...allergens, "vis": !allergens["vis"]})}/></Col>
                <Col md={6}><Form.Switch type={"switch"} id={"pinda"} label={"PINDA"} checked={allergens["pinda"]} onChange={() => setAllergens({...allergens, "pinda": !allergens["pinda"]})}/></Col>
            </Row>
            <Row>
                <Col md={6}><Form.Switch type={"switch"} id={"noten"} label={"NOTEN"} checked={allergens["noten"]} onChange={() => setAllergens({...allergens, "noten": !allergens["noten"]})}/></Col>
                <Col md={6}><Form.Switch type={"switch"} id={"mosterd"} label={"MOSTERD"} checked={allergens["mosterd"]} onChange={() => setAllergens({...allergens, "mosterd": !allergens["mosterd"]})}/></Col>
            </Row>
            <Row>
                <Col md={6}><Form.Switch type={"switch"} id={"soja"} label={"SOJA"} checked={allergens["soja"]} onChange={() => setAllergens({...allergens, "soja": !allergens["soja"]})}/></Col>
                <Col md={6}><Form.Switch type={"switch"} id={"eieren"} label={"EIEREN"} checked={allergens["eieren"]} onChange={() => setAllergens({...allergens, "eieren": !allergens["eieren"]})}/></Col>
            </Row>
            <Row>
                <Col md={6}><Form.Switch type={"switch"} id={"sulfiet"} label={"SULFIET"} checked={allergens["sulfiet"]} onChange={() => setAllergens({...allergens, "sulfiet": !allergens["sulfiet"]})}/></Col>
                <Col md={6}><Form.Switch type={"switch"} id={"selderij"} label={"SELDERIJ"} checked={allergens["selderij"]} onChange={() => setAllergens({...allergens, "selderij": !allergens["selderij"]})}/></Col>
            </Row>
            <Row>
                <Col md={6}><Form.Switch type={"switch"} id={"sesamzaad"} label={"SESAMZAAD"} checked={allergens["sesamzaad"]} onChange={() => setAllergens({...allergens, "sesamzaad": !allergens["sesamzaad"]})}/></Col>
                <Col md={6}><Form.Switch type={"switch"} id={"cashew"} label={"CASHEWNOTEN"} checked={allergens["cashewnoten"]} onChange={() => setAllergens({...allergens, "cashewnoten": !allergens["cashewnoten"]})}/></Col>
            </Row>
            <Row>
                <Col md={6}><Form.Switch type={"switch"} id={"schaaldieren"} label={"SCHAALDIEREN"} checked={allergens["schaaldieren"]} onChange={() => setAllergens({...allergens, "schaaldieren": !allergens["schaaldieren"]})}/></Col>
                <Col md={6}><Form.Switch type={"switch"} id={"lupine"} label={"LUPINE"} checked={allergens["lupine"]} onChange={() => setAllergens({...allergens, "lupine": !allergens["lupine"]})}/></Col>
            </Row>
            <Row>
                <Col md={6}><Form.Switch type={"switch"} id={"amandelnoten"} label={"AMANDELNOTEN"} checked={allergens["amandelnoten"]} onChange={() => setAllergens({...allergens, "amandelnoten": !allergens["amandelnoten"]})}/></Col>
            </Row>
        </FormSection>
    );

}