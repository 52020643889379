import {Sidebar} from "../components/Sidebar";
import {Recipe} from "../components/Recipe";
import {useRecipeContext} from "../contexts/RecipeContext";

export function RecipePage(){

    const {recipe} = useRecipeContext();

    return (
        <>
            <Sidebar/>
            <Recipe recipe={recipe}/>
        </>
    );

}