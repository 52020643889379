import {createContext, useContext, useMemo, useState} from "react";
import {ALLERGENS} from "../data/data";

const SearchRecipeContext = createContext();

export function SearchRecipeProvider(props) {

    const [isVeggie, setVeggie] = useState(false);
    const [isLowCal, setLowCal] = useState(false);
    const [maxCal, setMaxCal] = useState(1000);
    const [duration, setDuration] = useState(40);
    const [difficulty, setDifficulty] = useState(0);
    const [allergens, setAllergens] = useState(ALLERGENS);

    const api = useMemo(() => ({
        isVeggie, setVeggie, isLowCal, setLowCal, maxCal, setMaxCal, duration, setDuration, difficulty, setDifficulty, allergens, setAllergens
    }), [isVeggie, setVeggie, isLowCal, setLowCal, maxCal, setMaxCal, duration, setDuration, difficulty, setDifficulty, allergens, setAllergens]);

    return <SearchRecipeContext.Provider value={api}>
        {props.children}
    </SearchRecipeContext.Provider>;
}

export const useSearchRecipeContext = () => useContext(SearchRecipeContext);